//import * as Sentry from '@sentry/react';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {request} from '../../utils';
import * as types from './ActionTypes';
import {getBalance, getReportAccounts, getUsers} from './reports.actions';

function* getAccountsSaga({ payload }: { payload: any }): any {
  try {
    yield put(getReportAccounts.loading({} as any));
    const transactionHistoryParams = {
      method: 'GET',
      params: {
        accountOwnerId: payload.accountOwnerId,
        page: payload.page,
        size: payload.rowsPerPage,
        ...payload.filter,
      },
      url: 'reports/user',
    };
    const data: any = (yield call(request, transactionHistoryParams)).data;
    yield put(getReportAccounts.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load transaction history', error); // tslint:disable-line
    yield put(getReportAccounts.fail(error.toString())); // tslint:disable-line
  }
}

function* getUsersSaga({ payload }: { payload: any }): any {
  try {
    yield put(getUsers.loading({} as any));
    const transactionHistoryParams = {
      method: 'GET',
      params: {
        accountOwnerId: payload.accountOwnerId,
        page: payload.page,
        size: payload.rowsPerPage,
        ...payload.filter,
      },
      url: 'reports/user',
    };
    const data: any = (yield call(request, transactionHistoryParams)).data;
    yield put(getUsers.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load transaction history', error); // tslint:disable-line
    yield put(getUsers.fail(error.toString())); // tslint:disable-line
  }
}

function* getBalanceSaga({ payload }: { payload: any }): any {
  try {
    yield put(getBalance.loading({} as any));
    const transactionHistoryParams = {
      method: 'GET',
      url: 'reports/balance',
    };
    const data: any = (yield call(request, transactionHistoryParams)).data;
    yield put(getBalance.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load transaction history', error); // tslint:disable-line
    yield put(getBalance.fail(error.toString())); // tslint:disable-line
  }
}

// Saga watchers / listeners
export function* reportsSaga() {
  yield all([
    // @ts-ignore
    takeEvery(types.GET_REPORT_ACCOUNTS.DEFAULT, getAccountsSaga),
    // @ts-ignore
    takeEvery(types.GET_USERS.DEFAULT, getUsersSaga),
    // @ts-ignore
    takeEvery(types.GET_REPORT_BALANCE.DEFAULT, getBalanceSaga),
  ]);
}
