import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import * as React from 'react';
import {IUsedField} from '../forms/hooks';

interface IOption {
  label: string | React.ReactElement;
  value: string | number;
}

interface IInputProps {
  label?: string;
  options: IOption[];
  type?: string;
  className?: string;
  disabled?: boolean;
  value?: string | number;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IInputProps2 extends IUsedField {
  label: string;
  multiple?: boolean;
  margin?: 'none' | 'normal' | 'dense' | undefined;
  options: IOption[] | any;
  optionRenderer?: (option: any) => void;
  rendererValue?: (option: any) => void;
  type?: string;
  className?: string;
  formControlClassName?: string;
  disabled?: boolean;
  value: string | string[];
  name: string;
  helperText?: string | React.ReactNode;
}

const styles = () =>
  createStyles({
    input: {
      marginRight: 0,
      // width: '100%',
    },
    textField: {
      // width: '100%',
    },
    root: {
      minHeight: '35px',
      height: '100%',
      '&.Mui-disabled svg': {
        fill: 'rgba(0, 0, 0, 0.26)',
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    label: {
      color: '#777777',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.57',
      letterSpacing: 'normal',
    },
  });

const SelectTemp: React.FunctionComponent<
  IInputProps & WithStyles<typeof styles>
> = ({
  label,
  options,
  name,
  disabled,
  classes,
  className,
  type = 'text',
  value,
  onChange,
}) => {
  return (
    <TextField
      select={true}
      disabled={disabled}
      name={name}
      label={label}
      type={type}
      InputLabelProps={{
        classes: {
          root: classes.label,
        },
      }}
      className={classNames(classes.textField, className)}
      value={value}
      onChange={onChange}
    >
      {options.map(({ value: optionValue, label: optionLabel }) => (
        <MenuItem key={optionValue} value={optionValue}>
          {optionLabel}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const Select = withStyles(styles)(SelectTemp);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const Select2Temp: React.FunctionComponent<
  IInputProps2 & WithStyles<typeof styles>
> = ({
  label,
  multiple = false,
  options,
  name,
  disabled,
  classes,
  className,
  formControlClassName,
  type = 'text',
  value,
  onChange,
  errors,
  margin,
  pristine,
  validate,
  onBlur,
  helperText,
  optionRenderer,
  rendererValue,
}) => {
  const showErrors = !pristine && !!errors.length;

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    validate();
  };

  const renderValue = (selected: any) => (
    <div className={classes.chips}>
      {selected.map((selectedValue: any) => (
        <Chip
          key={selectedValue}
          label={rendererValue ? rendererValue(selectedValue) : selectedValue}
          className={classes.chip}
        />
      ))}
    </div>
  );

  return (
    <FormControl
      error={showErrors}
      className={classNames('flex-column', formControlClassName)}
    >
      {multiple ? (
        <React.Fragment>
          <InputLabel id="multiple-select-label">{label}</InputLabel>
          <MuiSelect
            disabled={disabled}
            name={name}
            inputProps={{
              labelId: 'multiple-select-label',
              className: 'test-className',
            }}
            className={classNames(classes.root, className)}
            value={value}
            onChange={onChange}
            onBlur={onBlurHandler}
            multiple={true}
            renderValue={renderValue}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  width: 250,
                },
              },
            }}
          >
            {options}
          </MuiSelect>
        </React.Fragment>
      ) : (
        <TextField
          select={true}
          helperText={helperText}
          disabled={disabled}
          name={name}
          label={label}
          type={type}
          InputProps={{
            classes: {
              root: classes.input,
            },
          }}
          className={classNames(classes.textField, className)}
          value={value}
          onChange={onChange}
          onBlur={onBlurHandler}
          margin={margin || 'normal'}
        >
          {options?.map((option: any) => {
            if (optionRenderer) {
              return optionRenderer(option);
            }
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </TextField>
      )}
      <FormHelperText component="div">
        <span>{showErrors ? errors[0] : helperText || ''}</span>
      </FormHelperText>
    </FormControl>
  );
};

export const Select2 = withStyles(styles)(Select2Temp);
