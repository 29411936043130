import injectReducer from '../../store/injectReducer';
import * as types from './ActionTypes';
import {IReferralReducer} from './IReferralReducer';

export const InitialReferralReducer = {
  referralList: { content: [], totalElements: 0 },
  campaign: {},
  error: '',
  isLoading: false,
};

const requestFunctionHandler = (state: IReferralReducer) => ({
  ...state,
  isLoading: true,
});

const failureFunctionHandler = (state: IReferralReducer, { payload }: any) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default injectReducer(InitialReferralReducer, {
  [types.GET_REFERRAL_LIST.REQUEST]: requestFunctionHandler,
  [types.GET_REFERRAL_LIST.FAILURE]: failureFunctionHandler,
  [types.GET_REFERRAL_LIST.SUCCESS]: (
    state: IReferralReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    referralList: {
      content: payload.content,
      totalElements: payload.totalElements,
      first: payload.first,
      last: payload.last,
    },
  }),
  [types.RESET_REFERRAL_LIST]: (state: IReferralReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    referralList: InitialReferralReducer.referralList,
  }),
  [types.GET_REFERRAL_CAMPAIGN.REQUEST]: requestFunctionHandler,
  [types.GET_REFERRAL_CAMPAIGN.FAILURE]: failureFunctionHandler,
  [types.GET_REFERRAL_CAMPAIGN.SUCCESS]: (
    state: IReferralReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    campaign: payload,
  }),
  [types.RESET_REFERRAL_CAMPAIGN]: (state: IReferralReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    campaign: InitialReferralReducer.campaign,
  }),
  // [types.GET_REFERRAL_USER.REQUEST]: requestFunctionHandler,
  [types.GET_REFERRAL_USER.FAILURE]: failureFunctionHandler,
  [types.GET_REFERRAL_USER.SUCCESS]: (
    state: IReferralReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    campaign: {
      ...state.campaign,
      user: payload,
    },
  }),
});
