import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import * as React from 'react';

interface IInputProps {
  label: string;
  type?: string;
  margin?: 'none' | 'dense' | 'normal';
  className?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string | undefined;
  defaultValue?: string | undefined;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const InputTextTemp: React.FunctionComponent<
  IInputProps & WithStyles<typeof styles>
> = ({
  label,
  name,
  disabled,
  classes,
  margin,
  className,
  type = 'text',
  value,
  defaultValue,
  required,
  onChange,
  onBlur,
}) => (
  <TextField
    disabled={disabled}
    name={name}
    label={label}
    type={type}
    className={classNames(classes.textField, className)}
    value={value}
    defaultValue={defaultValue}
    onChange={onChange}
    onBlur={onBlur}
    required={required}
    InputProps={{
      classes: {
        root: classes.input,
      },
    }}
    margin={margin || 'normal'}
  />
);

const styles = () =>
  createStyles({
    input: {
      marginRight: 0,
      // width: '100%',
    },
    textField: {
      width: '100%',
    },
  });

export const InputText = withStyles(styles)(InputTextTemp);
