import {createAction, createApiAction} from '../../store/helpers';
import * as types from './ActionTypes';

export const getReferralList = createApiAction(types.GET_REFERRAL_LIST);
export const getReferralCampaign = createApiAction(types.GET_REFERRAL_CAMPAIGN);
export const getReferralUser = createApiAction(types.GET_REFERRAL_USER);
export const resetReferralList = createAction(types.RESET_REFERRAL_LIST);
export const resetReferralCampaign = createAction(
  types.RESET_REFERRAL_CAMPAIGN,
);
