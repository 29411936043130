import classNames from 'classnames';
import * as React from 'react';

import MuiButton from '@material-ui/core/Button';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import '../../styles/variables.scss';

const styles = () =>
  createStyles({
    button: {
      border: '1px solid #006caf',
      borderRadius: '4px',
      fontSize: '14px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '0.7px',
      lineHeight: 1.57,
      padding: '8px 22px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },

    modalButton: {
      width: '136px',
      height: '42px',
    },

    buttonSend: {
      width: '136px',
      height: '42px',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.57',
      letterSpacing: '0.7px',
      textAlign: 'center',
      backgroundColor: '#006caf',
    },

    buttonCancel: {
      '&:hover': {
        backgroundColor: '#006caf',
        color: '#FFFFFF',
      },
      backgroundColor: '#FFFFFF',
      color: '#006caf',
    },
    buttonRollCancel: {
      '&:hover': {
        background: '#ef5e5f',
        color: '#fff',
      },
    },
    buttonDone: {
      '&:hover': {
        backgroundColor: '#FFFFFF',
        color: '#006caf',
      },
      borderColor: '#006caf',
      backgroundColor: '#006caf',
      color: '#FFFFFF',
    },
    buttonFill: {
      flexGrow: 2,
      width: '100%',
    },
    shapeless: {
      '&:hover': {
        background: 'none',
        textDecoration: 'none',
      },
      backgroundColor: 'transparent',
      border: 'none',
      color: '#006caf',
      minHeight: '1em',
      padding: '0',
      textTransform: 'none',
    },
  });

const actionStyles = () =>
  createStyles({
    button: {
      // border: '1px solid #006caf',
      border: '1px solid var(--action-color)',
      borderRadius: '4px',
      fontSize: '14px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '0.7px',
      lineHeight: 1.57,
      padding: '13px 12px',
      textAlign: 'center',
      textTransform: 'uppercase',
      width: '216px',
    },

    modalButton: {
      width: '136px',
      height: '42px',
    },

    buttonSend: {
      width: '136px',
      height: '42px',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.57',
      letterSpacing: '0.7px',
      textAlign: 'center',
      backgroundColor: 'var(--action-color)',
    },

    buttonCancel: {
      '&:hover': {
        backgroundColor: 'var(--action-color)',
        color: '#FFFFFF',
      },
      backgroundColor: '#FFFFFF',
      color: 'var(--action-color)',
    },
    buttonRollCancel: {
      '&:hover': {
        background: '#ef5e5f',
        color: '#fff',
      },
    },
    buttonDone: {
      '&:hover': {
        backgroundColor: '#FFFFFF',
        color: 'var(--action-color)',
      },
      borderColor: 'var(--action-color)',
      backgroundColor: 'var(--action-color)',
      color: '#FFFFFF',
    },
    buttonFill: {
      flexGrow: 2,
      width: '100%',
    },
    shapeless: {
      '&:hover': {
        background: 'none',
        textDecoration: 'none',
      },
      backgroundColor: 'transparent',
      border: 'none',
      color: 'var(--action-color)',
      minHeight: '1em',
      padding: '0',
      textTransform: 'none',
    },
  });

interface IButtonBaseProps {
  onClick?: () => void;
  hollow?: boolean;
  fillSpace?: boolean;
  rollCancel?: boolean;
  send?: boolean;
  modalButton?: boolean;
  disabled?: boolean;
  className?: string;
  shapeless?: boolean;
  type?: string;
  component?: any;
}

type ButtonProps = IButtonBaseProps & WithStyles<typeof styles>;

const ButtonBase: React.FC<ButtonProps> = ({
  classes,
  children,
  onClick,
  hollow,
  rollCancel,
  send,
  modalButton,
  fillSpace,
  disabled = false,
  className,
  shapeless,
  type,
  component,
}) => (
  <MuiButton
    onClick={onClick}
    type={type}
    variant="outlined"
    disabled={disabled}
    className={classNames(
      classes.button,
      rollCancel ? classes.buttonRollCancel : null,
      send ? classes.buttonSend : null,
      modalButton ? classes.modalButton : null,
      hollow ? classes.buttonCancel : null,
      !hollow && !shapeless ? classes.buttonDone : null,
      shapeless ? classes.shapeless : null,
      fillSpace ? classes.buttonFill : null,
      className || null,
    )}
    component={component}
  >
    {children}
  </MuiButton>
);
export const Button = withStyles(styles)(ButtonBase);
export const ActionButton = withStyles(actionStyles)(ButtonBase);
