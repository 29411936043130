import * as React from 'react';

export const LogoutIcon: React.SFC = () => (
  <svg
    width="25px"
    height="27px"
    viewBox="0 0 25 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="logout_icn"
        transform="translate(-11.000000, -5.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <path
          d="M33.4415458,16.504 L30.9718758,14.0343301 C30.6789826,13.7414369 30.6789826,13.2665631 30.9718758,12.9736699 C31.2647691,12.6807767 31.7396428,12.6807767 32.032536,12.9736699 L35.782536,16.7236699 C36.0754292,17.0165631 36.0754292,17.4914369 35.782536,17.7843301 L32.032536,21.5343301 C31.7396428,21.8272233 31.2647691,21.8272233 30.9718758,21.5343301 C30.6789826,21.2414369 30.6789826,20.7665631 30.9718758,20.4736699 L33.4415458,18.004 L19.5022059,18.004 C19.0879924,18.004 18.7522059,17.6682136 18.7522059,17.254 C18.7522059,16.8397864 19.0879924,16.504 19.5022059,16.504 L33.4415458,16.504 Z M27.0022059,21.75 C27.0022059,21.3357864 27.3379924,21 27.7522059,21 C28.1664195,21 28.5022059,21.3357864 28.5022059,21.75 L28.5014434,28.7983757 C28.5557136,30.0009798 27.627879,31.0213258 26.3882059,31.0821866 L13.0773475,31.0812305 C11.8756167,31.0204922 10.9486829,30.000389 11.0022059,28.8321866 L11.0029932,7.28435625 C10.9478448,6.08172366 11.8754631,5.06086713 13.1152059,5 L26.4255436,5.00092998 C27.627879,5.06086087 28.5557136,6.08120683 28.5022059,7.25 L28.5022059,12.75 C28.5022059,13.1642136 28.1664195,13.5 27.7522059,13.5 C27.3379924,13.5 27.0022059,13.1642136 27.0022059,12.75 L27.0029684,7.21618902 C27.0200163,6.83841625 26.7285566,6.51789605 26.3882059,6.5 L13.1525454,6.49906993 C12.7752129,6.5178791 12.4841122,6.83823938 12.5022059,7.25 L12.5014422,28.8660238 C12.4843892,29.2436191 12.775566,29.5640631 13.1152059,29.5821866 L26.3508682,29.5831166 C26.7285566,29.5642906 27.0200163,29.2437704 27.0022059,28.8321866 L27.0022059,21.75 Z"
          id="logout_icn-copy"
        />
      </g>
    </g>
  </svg>
);
