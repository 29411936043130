//import * as Sentry from '@sentry/react';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {request} from '../../utils';
import * as types from './ActionTypes';
import {
  blockAdmin,
  cashInAgent,
  cashInSystemAccounts,
  cashOutSystemAccounts,
  closeTransactionDetailsModal,
  getAdmin,
  getAdministratorsList,
  getRoles,
  getSystemAccountsList,
  getTypes,
  rebalanceSystemAccounts,
  resetCredential,
  updateAdmin,
} from './preferences.actions';
import {JSEncrypt} from "jsencrypt";

function* getAdministratorsListSaga({ payload }: { payload: any }): any {
  try {
    yield put(getAdministratorsList.loading({} as any));

    const { rowsPerPage, page, filter } = payload;
    const administratorsListParams = {
      method: 'GET',
      url: 'admin',
      params: {
        page: page,
        size: rowsPerPage,
        ...filter,
      },
    };
    const data: any = (yield call(request, administratorsListParams)).data;
    yield put(getAdministratorsList.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load administrators list', error); // tslint:disable-line
    yield put(getAdministratorsList.fail(error.toString())); // tslint:disable-line
  }
}

function* getSystemAccountsListSaga({ payload }: { payload: any }): any {
  try {
    yield put(getSystemAccountsList.loading({} as any));

    const { rowsPerPage, page, filter } = payload;
    const systemAccountsListParams = {
      method: 'GET',
      url: 'system-accounts',
      params: {
        page: page,
        size: rowsPerPage,
        ...filter,
      },
    };
    const data: any = (yield call(request, systemAccountsListParams)).data;
    yield put(getSystemAccountsList.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load system accounts list', error); // tslint:disable-line
    yield put(getSystemAccountsList.fail(error.toString())); // tslint:disable-line
  }
}

function* getAdminSaga({ payload }: { payload: any }): any {
  try {
    yield put(getAdmin.loading({} as any));

    const adminParams = {
      method: 'GET',
      url: `admin/${payload.id}`,
    };
    const data: any = (yield call(request, adminParams)).data;
    yield put(getAdmin.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load admin', error); // tslint:disable-line
    yield put(getAdmin.fail(error.toString())); // tslint:disable-line
  }
}

function* updateAdminSaga({ payload }: { payload: any }): any {
  try {
    yield put(updateAdmin.loading({} as any));
    const adminParams = {
      method: 'PUT',
      url: 'admin',
      data: payload,
    };
    const data: any = (yield call(request, adminParams)).data;
    yield put(updateAdmin.success(data));
    yield call(getAdminSaga, { payload: { id: payload.id } } as any);
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to update admin', error); // tslint:disable-line
    yield put(updateAdmin.fail(error.toString())); // tslint:disable-line
  }
}

function* getRolesSaga({ payload }: { payload: any }): any {
  try {
    yield put(getRoles.loading({} as any));

    const rolesParams = {
      method: 'GET',
      url: 'admin/roles',
    };
    const data: any = (yield call(request, rolesParams)).data;
    yield put(getRoles.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load roles', error); // tslint:disable-line
    yield put(getRoles.fail(error.toString())); // tslint:disable-line
  }
}

function* getTypesSaga({ payload }: { payload: any }): any {
  try {
    yield put(getTypes.loading({} as any));

    const typesParams = {
      method: 'GET',
      url: 'account/types',
    };
    const data: any = (yield call(request, typesParams)).data;
    yield put(getTypes.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load types', error); // tslint:disable-line
    yield put(getTypes.fail(error.toString())); // tslint:disable-line
  }
}

function* blockAdminSaga({ payload }: { payload: any }): any {
  try {
    yield put(blockAdmin.loading({} as any));

    const disableAgentParams = {
      method: 'POST',
      url: `admin/${payload.id}/${payload.isBlocked ? 'unblock' : 'block'}`,
    };
    const data: any = (yield call(request, disableAgentParams)).data;
    yield put(blockAdmin.success(data));
    yield call(getAdminSaga, { payload: { id: payload.id } } as any);
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to block admin', error); // tslint:disable-line
    yield put(blockAdmin.fail(error.toString())); // tslint:disable-line
  }
}

function* rebalanceSystemAccountsSaga({ payload }: { payload: any }): any {
  try {
    yield put(rebalanceSystemAccounts.loading({} as any));

    const encrypt = new JSEncrypt();
    const publicKeyParams = {
      method: 'GET',
      url: `profile/challenge`,
    };
    const publicKey: any = (yield call(request, publicKeyParams)).data;
    encrypt.setPublicKey(publicKey);
    const hash = encrypt.encrypt(payload.password);

    const rebalanceParams = {
      method: 'POST',
      withNotification: true,
      data: {
        amount: parseFloat(payload.amount),
        signedChallenge: {
          code: payload.code,
          signature: hash,
        },
      },
      url: `system-accounts/${payload.sourceAccountId}/rebalance/${payload.destinationAccountId}`,
    };
    const data: any = (yield call(request, rebalanceParams)).data;
    yield put(rebalanceSystemAccounts.success(data));
    yield put(closeTransactionDetailsModal(true));
    yield call(getSystemAccountsListSaga, {
      payload: { page: payload.page, rowsPerPage: payload.rowsPerPage },
    } as any);
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to rebalance', error); // tslint:disable-line
    yield put(rebalanceSystemAccounts.fail(error.toString()));
  }
}

function* cashInSystemAccountsSaga({ payload }: { payload: any }): any {
  try {
    yield put(cashInSystemAccounts.loading({} as any));
    const encrypt = new JSEncrypt();
    const publicKeyParams = {
      method: 'GET',
      url: `profile/challenge`,
    };
    const publicKey: any = (yield call(request, publicKeyParams)).data;
    encrypt.setPublicKey(publicKey);
    const hash = encrypt.encrypt(payload.password);

    const cashInParams = {
      method: 'POST',
      withNotification: true,
      data: {
        amount: parseFloat(payload.amount),
        poolAccountId: payload.poolAccountId,
        signedChallenge: {
          code: payload.code,
          signature: hash,
        },
      },
      url: `system-accounts/${payload.id}/cashin`,
    };
    const data: any = (yield call(request, cashInParams)).data;
    yield put(cashInSystemAccounts.success(data));
    yield put(closeTransactionDetailsModal(true));
    yield call(getSystemAccountsListSaga, {
      payload: { page: payload.page, rowsPerPage: payload.rowsPerPage },
    } as any);
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to cash in', error); // tslint:disable-line
    yield put(cashInSystemAccounts.fail(error.toString()));
  }
}

function* cashOutAgentSaga({ payload }: { payload: any }): any {
  try {
    yield put(cashInAgent.loading({} as any));

    const encrypt = new JSEncrypt();
    const publicKeyParams = {
      method: 'GET',
      url: `profile/challenge`,
    };
    const publicKey: any = (yield call(request, publicKeyParams)).data;
    encrypt.setPublicKey(publicKey);
    const hash = encrypt.encrypt(payload.password);

    const cashInParams = {
      method: 'POST',
      withNotification: true,
      data: {
        amount: parseFloat(payload.amount),
        poolAccountId: payload.poolAccountId,
        signedChallenge: {
          code: payload.code,
          signature: hash,
        },
      },
      url: `agent/${payload.id}/cashout/${payload.accountId}`,
    };
    const data: any = (yield call(request, cashInParams)).data;
    yield put(cashInAgent.success(data));
    yield put(closeTransactionDetailsModal(true));
    yield call(getSystemAccountsListSaga, {
      payload: { page: payload.page, rowsPerPage: payload.rowsPerPage },
    } as any);
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to cash in', error); // tslint:disable-line
    yield put(cashInAgent.fail(error.toString()));
  }
}

function* cashOutCompanySaga({ payload }: { payload: any }): any {
  try {
    yield put(cashInAgent.loading({} as any));

    const encrypt = new JSEncrypt();
    const publicKeyParams = {
      method: 'GET',
      url: `profile/challenge`,
    };
    const publicKey: any = (yield call(request, publicKeyParams)).data;
    encrypt.setPublicKey(publicKey);
    const hash = encrypt.encrypt(payload.password);

    const cashInParams = {
      method: 'POST',
      withNotification: true,
      data: {
        amount: parseFloat(payload.amount),
        poolAccountId: payload.poolAccountId,
        signedChallenge: {
          code: payload.code,
          signature: hash,
        },
      },
      url: `companies/${payload.id}/cashout/${payload.accountId}`,
    };
    const data: any = (yield call(request, cashInParams)).data;
    yield put(cashInAgent.success(data));
    yield put(closeTransactionDetailsModal(true));
    yield call(getSystemAccountsListSaga, {
      payload: { page: payload.page, rowsPerPage: payload.rowsPerPage },
    } as any);
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to cash in', error); // tslint:disable-line
    yield put(cashInAgent.fail(error.toString()));
  }
}

function* cashInAgentSaga({ payload }: { payload: any }): any {
  try {
    yield put(cashInAgent.loading({} as any));
    const encrypt = new JSEncrypt();
    const publicKeyParams = {
      method: 'GET',
      url: `profile/challenge`,
    };
    const publicKey: any = (yield call(request, publicKeyParams)).data;
    encrypt.setPublicKey(publicKey);
    const hash = encrypt.encrypt(payload.password);

    const cashInParams = {
      method: 'POST',
      withNotification: true,
      data: {
        amount: parseFloat(payload.amount),
        poolAccountId: payload.poolAccountId,
        signedChallenge: {
          code: payload.code,
          signature: hash,
        },
      },
      url: `agent/${payload.id}/cashin/${payload.accountId}`,
    };
    const data: any = (yield call(request, cashInParams)).data;
    yield put(cashInAgent.success(data));
    yield put(closeTransactionDetailsModal(true));
    yield call(getSystemAccountsListSaga, {
      payload: { page: payload.page, rowsPerPage: payload.rowsPerPage },
    } as any);
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to cash in', error); // tslint:disable-line
    yield put(cashInAgent.fail(error.toString()));
  }
}

function* cashInCompanySaga({ payload }: { payload: any }): any {
  try {
    yield put(cashInAgent.loading({} as any));

    const encrypt = new JSEncrypt();
    const publicKeyParams = {
      method: 'GET',
      url: `profile/challenge`,
    };
    const publicKey: any = (yield call(request, publicKeyParams)).data;
    encrypt.setPublicKey(publicKey);
    const hash = encrypt.encrypt(payload.password);

    const cashInParams = {
      method: 'POST',
      withNotification: true,
      data: {
        amount: parseFloat(payload.amount),
        poolAccountId: payload.poolAccountId,
        signedChallenge: {
          code: payload.code,
          signature: hash,
        },
      },
      url: `companies/${payload.id}/cashin/${payload.accountId}`,
    };
    const data: any = (yield call(request, cashInParams)).data;
    yield put(cashInAgent.success(data));
    yield put(closeTransactionDetailsModal(true));
    yield call(getSystemAccountsListSaga, {
      payload: { page: payload.page, rowsPerPage: payload.rowsPerPage },
    } as any);
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to cash in', error); // tslint:disable-line
    yield put(cashInAgent.fail(error.toString()));
  }
}

function* cashOutSystemAccountsSaga({ payload }: { payload: any }): any {
  try {
    yield put(cashOutSystemAccounts.loading({} as any));

    const encrypt = new JSEncrypt();
    const publicKeyParams = {
      method: 'GET',
      url: `profile/challenge`,
    };
    const publicKey: any = (yield call(request, publicKeyParams)).data;
    encrypt.setPublicKey(publicKey);
    const hash = encrypt.encrypt(payload.password);

    const cashOutParams = {
      method: 'POST',
      withNotification: true,
      data: {
        poolAccountId: payload.poolAccountId,
        amount: parseFloat(payload.amount),
        signedChallenge: {
          code: payload.code,
          signature: hash,
        },
      },
      url: `system-accounts/${payload.id}/cashout`,
    };
    const data: any = (yield call(request, cashOutParams)).data;
    yield put(closeTransactionDetailsModal(true));
    yield put(cashOutSystemAccounts.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to cash out', error); // tslint:disable-line
    yield put(cashOutSystemAccounts.fail(error.toString()));
  }
}

function* resetCredentialSaga({ payload }: { payload: any }): any {
  try {
    yield put(resetCredential.loading({} as any));

    const disableAgentParams = {
      method: 'POST',
      url: `admin/${payload.id}/reset-protection`,
    };
    const data: any = (yield call(request, disableAgentParams)).data;
    yield put(resetCredential.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to reset credential', error); // tslint:disable-line
    yield put(resetCredential.fail(error.toString())); // tslint:disable-line
  }
}

// Saga watchers / listeners
export function* preferencesSaga() {
  yield all([
    // @ts-ignore
    takeEvery(types.GET_ADMINISTRATORS_LIST.DEFAULT, getAdministratorsListSaga),
    // @ts-ignore
    takeEvery(types.GET_SYSTEM_ACCOUNTS_LIST.DEFAULT, getSystemAccountsListSaga),
    // @ts-ignore
    takeEvery(types.GET_ADMIN.DEFAULT, getAdminSaga),
    // @ts-ignore
    takeEvery(types.UPDATE_ADMIN.DEFAULT, updateAdminSaga),
    // @ts-ignore
    takeEvery(types.GET_ROLES.DEFAULT, getRolesSaga),
    // @ts-ignore
    takeEvery(types.GET_TYPES.DEFAULT, getTypesSaga),
    // @ts-ignore
    takeEvery(types.BLOCK_ADMIN.DEFAULT, blockAdminSaga),
    // @ts-ignore
    takeEvery(types.REBALANCE_SYSTEM_ACCOUNTS.DEFAULT, rebalanceSystemAccountsSaga),
    // @ts-ignore
    takeEvery(types.CASH_IN_SYSTEM_ACCOUNTS.DEFAULT, cashInSystemAccountsSaga),
    // @ts-ignore
    takeEvery(types.CASH_OUT_AGENT.DEFAULT, cashOutAgentSaga),
    // @ts-ignore
    takeEvery(types.CASH_OUT_COMPANY.DEFAULT, cashOutCompanySaga),
    // @ts-ignore
    takeEvery(types.CASH_IN_AGENT.DEFAULT, cashInAgentSaga),
    // @ts-ignore
    takeEvery(types.CASH_IN_COMPANY.DEFAULT, cashInCompanySaga),
    // @ts-ignore
    takeEvery(types.CASH_OUT_SYSTEM_ACCOUNTS.DEFAULT, cashOutSystemAccountsSaga),
    // @ts-ignore
    takeEvery(types.RESET_CREDENTIAL.DEFAULT, resetCredentialSaga),
  ]);
}
