import {createApiActionTypes} from '../../store/helpers';

export const GET_USERS = createApiActionTypes('GET_USERS');
export const RESET_USERS = 'RESET_USERS';

export const GET_REPORT_ACCOUNTS = createApiActionTypes('GET_REPORT_ACCOUNTS');
export const RESET_REPORT_ACCOUNTS = 'RESET_REPORT_ACCOUNTS';

export const GET_REPORT_BALANCE = createApiActionTypes('GET_REPORT_BALANCE');
export const RESET_REPORT_BALANCE = 'RESET_REPORT_BALANCE';
