import {createAction, createApiAction} from '../../store/helpers';
import * as types from './ActionTypes';

export const getTransactionsHistory = createApiAction(types.GET_TRANSACTIONS);
export const getTransactionsDetails = createApiAction(
  types.GET_TRANSACTIONS_DETAILS,
);
export const calculateTransactions = createApiAction(
  types.CALCULATE_TRANSACTION,
);

export const rollBacked = createApiAction(types.ROLL_BACK);
export const confirmRollBack = createApiAction(types.CONFIRM_ROLL_BACK);
export const getTokens = createApiAction(types.GET_TOKENS);

export const resetTransactions = createAction(types.RESET_TRANSACTIONS);
export const resetTokens = createAction(types.RESET_TOKENS);
export const resetTransactionsDetails = createAction(
  types.RESET_TRANSACTIONS_DETAILS,
);
export const cancelRollBack = createAction(types.CANCEL_ROLL_BACK);
