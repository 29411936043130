import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import * as React from 'react';

export interface IColumn {
  cellRenderer?: (data: any, column: any) => any;
  getCellStyle?: (data: any) => any;
  hasSort?: boolean;
  label: string | React.ReactNode;
  maxWidth?: number;
  name: string;
  width?: number;
  marginRight?: number;
}

interface ITableRow {
  columns: IColumn[];
  row: any;
}

export const TableRow: React.FC<ITableRow> = ({ row, columns }) => {
  return (
    <MuiTableRow>
      {columns.map(
        ({
          marginRight,
          name,
          cellRenderer,
          width,
          maxWidth,
          getCellStyle,
        }) => {
          const cellContent = cellRenderer
            ? cellRenderer(row, { name })
            : row[name];
          return (
            <MuiTableCell
              key={name}
              style={getCellStyle ? getCellStyle(row) : undefined}
            >
              <div
                style={{
                  maxWidth: `${maxWidth}px` || undefined,
                  width: (width && `${width}px`) || 'fit-content',
                  marginRight: (marginRight && `${marginRight}px`) || 0,
                }}
              >
                {cellContent}
              </div>
            </MuiTableCell>
          );
        },
      )}
    </MuiTableRow>
  );
};
