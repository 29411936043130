import {InitialMainReducer} from '../../components/main/main.reducer';
import {InitialNotificationReducer} from '../../components/notifications/notifications.reducer';
import {InitialAgentReducer} from '../../pages/agent/agent.reducer';
import {InitialAuthReducer} from '../../pages/login/auth.reducer';
import {InitialMessagesReducer} from '../../pages/messages/messages.reducer';
import {InitialPreferencesReducer} from '../../pages/preferences/preferences.reducer';
import {InitialReferralReducer} from '../../pages/referral/referral.reducer';
import {InitialReportsReducer} from '../../pages/reports/reports.reducer';
import {InitialTransactionReducer} from '../../pages/transactions/transactions.reducer';

export default {
  auth: InitialAuthReducer,
  agent: InitialAgentReducer,
  main: InitialMainReducer,
  notifications: InitialNotificationReducer,
  referral: InitialReferralReducer,
  transactions: InitialTransactionReducer,
  messages: InitialMessagesReducer,
  reports: InitialReportsReducer,
  preferences: InitialPreferencesReducer,
};
