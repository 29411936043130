import * as React from 'react';
import ReactDOM from 'react-dom';

interface ITitleProps {
  title: string;
}

export class Title extends React.Component<ITitleProps> {
  public titleNode: HTMLTitleElement;
  constructor(props: ITitleProps) {
    super(props);

    this.titleNode = document.getElementsByTagName('title')[0];
  }

  public render() {
    const { title } = this.props;

    return ReactDOM.createPortal(title || '', this.titleNode);
  }
}
