import * as React from 'react';

export const CalendarIcon: React.SFC = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="calendar_pick_icn"
        transform="translate(-4.000000, -4.000000)"
        fill="#006CAF"
      >
        <path
          d="M5.5,9.75 L18.5,9.75 L18.5,7 C18.5,6.72385763 18.2761424,6.5 18,6.5 L16.25,6.5 L16.25,7.25 C16.25,7.66421356 15.9142136,8 15.5,8 C15.0857864,8 14.75,7.66421356 14.75,7.25 L14.75,6.5 L9.25,6.5 L9.25,7.25 C9.25,7.66421356 8.91421356,8 8.5,8 C8.08578644,8 7.75,7.66421356 7.75,7.25 L7.75,6.5 L6,6.5 C5.72385763,6.5 5.5,6.72385763 5.5,7 L5.5,9.75 Z M5.5,11.25 L5.5,18 C5.5,18.2761424 5.72385763,18.5 6,18.5 L18,18.5 C18.2761424,18.5 18.5,18.2761424 18.5,18 L18.5,11.25 L5.5,11.25 Z M16.25,5 L18,5 C19.1045695,5 20,5.8954305 20,7 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,7 C4,5.8954305 4.8954305,5 6,5 L7.75,5 L7.75,4.75 C7.75,4.33578644 8.08578644,4 8.5,4 C8.91421356,4 9.25,4.33578644 9.25,4.75 L9.25,5 L14.75,5 L14.75,4.75 C14.75,4.33578644 15.0857864,4 15.5,4 C15.9142136,4 16.25,4.33578644 16.25,4.75 L16.25,5 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);
