import * as React from 'react';

export const AdminIcon: React.SFC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#FFF"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    >
      <path d="M5.833 21.111V15a9.167 9.167 0 0 1 18.334 0v6.111" />
      <path d="M24.167 22.13a2.037 2.037 0 0 1-2.037 2.037H21.11a2.037 2.037 0 0 1-2.037-2.037v-3.056c0-1.125.912-2.037 2.037-2.037h3.056v5.093zm-18.334 0c0 1.125.912 2.037 2.037 2.037H8.89a2.037 2.037 0 0 0 2.037-2.037v-3.056a2.037 2.037 0 0 0-2.037-2.037H5.833v5.093z" />
    </g>
  </svg>
);
