import * as React from 'react';

export const TransactionsIcon: React.SFC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <g fill="#FFF" fillRule="nonzero">
      <path d="M31.273 13h-1.5v-.582c0-.69-.56-1.25-1.25-1.25H6.38v16.554c0 .69.56 1.25 1.25 1.25h20.893A1.25 1.25 0 0 0 29.742 28h1.517a2.75 2.75 0 0 1-2.736 2.472H7.63a2.75 2.75 0 0 1-2.75-2.75V8.572a2.75 2.75 0 0 1 2.75-2.75h16.734a2.75 2.75 0 0 1 2.75 2.75v1.096h1.409a2.75 2.75 0 0 1 2.75 2.75V13zm-5.66-4.428c0-.69-.559-1.25-1.25-1.25H7.63c-.69 0-1.25.56-1.25 1.25v1.096h19.234V8.572z" />
      <path d="M33.89 23.294a.75.75 0 0 0 0-1.5H22.75a.75.75 0 1 0 0 1.5h11.14zM34.236 17.508a.75.75 0 0 0 0-1.5h-11.14a.75.75 0 0 0 0 1.5h11.14z" />
      <path d="M30.912 20.066a.75.75 0 0 1 1.06-1.06l2.915 2.964a.75.75 0 0 1 0 1.06l-2.914 2.96a.75.75 0 1 1-1.06-1.06l2.383-2.43-2.384-2.434zM26.195 14.28a.75.75 0 0 0-1.061-1.06l-2.914 2.964a.75.75 0 0 0 0 1.06l2.914 2.961a.75.75 0 1 0 1.06-1.06l-2.383-2.43 2.384-2.435z" />
    </g>
  </svg>
);
