import {createApiActionTypes} from '../../store/helpers';

export const GET_ROLES = createApiActionTypes('GET_ROLES');
export const GET_TYPES = createApiActionTypes('GET_TYPES');

export const GET_ADMINISTRATORS_LIST = createApiActionTypes(
  'GET_ADMINISTRATORS_LIST',
);
export const RESET_ADMINISTRATORS_LIST = 'RESET_ADMINISTRATORS_LIST';

export const GET_ADMIN = createApiActionTypes('GET_ADMIN');
export const UPDATE_ADMIN = createApiActionTypes('UPDATE_ADMIN');
export const RESET_ADMIN = 'RESET_ADMIN';

export const BLOCK_ADMIN = createApiActionTypes('BLOCK_ADMIN');
export const RESET_CREDENTIAL = createApiActionTypes('RESET_CREDENTIAL');

export const REBALANCE_SYSTEM_ACCOUNTS = createApiActionTypes(
  'REBALANCE_SYSTEM_ACCOUNTS',
);
export const CASH_IN_SYSTEM_ACCOUNTS = createApiActionTypes(
  'CASH_IN_SYSTEM_ACCOUNTS',
);
export const CASH_IN_AGENT = createApiActionTypes('CASH_IN_AGENT');
export const CASH_IN_COMPANY = createApiActionTypes('CASH_IN_COMPANY');
export const CASH_OUT_AGENT = createApiActionTypes('CASH_OUT_AGENT');
export const CASH_OUT_COMPANY = createApiActionTypes('CASH_OUT_COMPANY');
export const CASH_OUT_SYSTEM_ACCOUNTS = createApiActionTypes(
  'CASH_OUT_SYSTEM_ACCOUNTS',
);
export const GET_SYSTEM_ACCOUNTS_LIST = createApiActionTypes(
  'GET_SYSTEM_ACCOUNTS_LIST',
);

export const RESET_SYSTEM_ACCOUNTS_LIST = 'RESET_SYSTEM_ACCOUNTS_LIST';

export const CLOSE_TRANSACTION_DETAILS_MODAL =
  'CLOSE_TRANSACTION_DETAILS_MODAL';

export const CLOSE_MODAL_SUCCESS = 'CLOSE_MODAL_SUCCESS';
