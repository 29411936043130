//import * as Sentry from '@sentry/react';
//import { Integrations } from '@sentry/tracing';
//import { BrowserTracing } from "@sentry/tracing";
import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

//Sentry.init({
//   dsn: 'https://33b31942f115420a98a4fce8591736ee@o478651.ingest.sentry.io/5842021',
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
//   environment:
//     process.env.NODE_ENV === 'development' ? 'development' : 'development',
// });

ReactDOM.render(<App />, document.getElementById('root'));
