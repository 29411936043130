import {createApiActionTypes} from '../../store/helpers';

export const GET_CURRENCIES = createApiActionTypes('GET_CURRENCIES');
export const GET_DOCUMENTS = createApiActionTypes('GET_DOCUMENTS');
export const GET_COUNTRIES_DETAIL = createApiActionTypes(
  'GET_COUNTRIES_DETAIL',
);
export const GET_DELIVERY_METHOD = createApiActionTypes('GET_DELIVERY_METHOD');
export const GET_PROVIDER_TYPE = createApiActionTypes('GET_PROVIDER_TYPE');
export const GET_TRANSACTION_TYPE = createApiActionTypes('GET_TRANSACTION_TYPE');
export const GET_DOMAINS = createApiActionTypes('GET_DOMAINS');
export const ADD_PHONE_PREFIX = createApiActionTypes('ADD_PHONE_PREFIX');
export const UPLOAD_FLAG = createApiActionTypes('UPLOAD_FLAG');

export const SAVE_COUNTRY = createApiActionTypes('SAVE_COUNTRY');
