//import * as Sentry from '@sentry/react';
import formurlencoded from 'form-urlencoded';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {request} from '../../utils';
import * as types from './ActionTypes';
import {ILoginCredentials, ILoginPayload, signIn, signOut,} from './auth.actions';

function* signInSaga({ payload }: { payload: ILoginPayload }): any {
  try {
    yield put(signIn.loading(payload));

    const authParams = {
      data: formurlencoded(payload),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      url: 'authenticate',
    };
    const data: ILoginCredentials = yield call(request, authParams);
    localStorage.setItem('isAuthenticated', JSON.stringify(true));
    localStorage.setItem('userIdentifier', data.data.id);
    localStorage.setItem('firstName', data.data.firstName);
    localStorage.setItem('lastName', data.data.lastName);
    localStorage.setItem('roles', JSON.stringify(data.data.roles));
    yield put(signIn.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to sign in', error); // tslint:disable-line
    localStorage.setItem('isAuthenticated', JSON.stringify(false));
    yield put(signIn.fail(error.toString()));
  }
}

function* signOutSaga() {
  localStorage.removeItem('isAuthenticated');
  localStorage.removeItem('userIdentifier');
  localStorage.removeItem('roles');
  localStorage.removeItem('firstName');
  localStorage.removeItem('lastName');
  yield put(signOut.success({}));
}

// Saga watchers / listeners
export function* authSaga() {
  yield all([
    // @ts-ignore
    takeEvery(types.SIGN_IN.DEFAULT, signInSaga),
    takeEvery(types.SIGN_OUT.DEFAULT, signOutSaga),
  ]);
}
