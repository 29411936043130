import injectReducer from '../../store/injectReducer';
import * as types from './ActionTypes';
import {IAuthReducer} from './IAuthReducer';

let roles = [];
let userIdentifier = '';
let firstName = '';
let isAuthenticated = false;
let lastName = '';
try {
  const storedRoles = localStorage.getItem('roles');
  roles = storedRoles ? JSON.parse(storedRoles) : [];
  firstName = localStorage.getItem('firstName') || '';
  lastName = localStorage.getItem('lastName') || '';
  userIdentifier = localStorage.getItem('userIdentifier') || '';
  isAuthenticated = JSON.parse(
    localStorage.getItem('userIdentifier') || 'false',
  );
} catch (e) {
  console.error(e); // tslint:disable-line
}
export const InitialAuthReducer = {
  authError: null,
  authRequest: null,
  firstName,
  lastName,
  isAuthenticated,
  userIdentifier,
  roles,
};

export default injectReducer(InitialAuthReducer, {
  [types.SIGN_IN.REQUEST]: (state: IAuthReducer) => ({
    ...state,
    authError: null,
    authRequest: true,
  }),
  [types.SIGN_IN.FAILURE]: (
    state: IAuthReducer,
    { payload }: { payload: any },
  ) => ({
    ...state,
    authError: payload.message,
    authRequest: false,
  }),
  [types.SIGN_IN.SUCCESS]: (
    state: IAuthReducer,
    { payload: { data } }: any,
  ) => ({
    ...state,
    authRequest: false,
    isAuthenticated: true,
    roles: data.roles,
    firstName: data.firstName,
    lastName: data.lastName,
    userIdentifier: data.id,
  }),
  [types.SIGN_OUT.SUCCESS]: (state: IAuthReducer) => ({
    ...state,
    authError: null,
    isAuthenticated: false,
    firstName: '',
    userIdentifier: '',
    lastName: '',
    roles: [],
  }),
});
