export interface IApiAction<Payload, SuccessData, ErrorMessage> {
  default: ActionFunction<Payload, Action<Payload>>;
  loading: ActionFunction<Payload, Action<Payload>>;
  success: ActionFunction<SuccessData, Action<SuccessData>>;
  fail: ActionFunction<ErrorMessage, Action<ErrorMessage>>;
}

export type ActionFunction<T1, R> = (t1: T1) => R;
export interface Action<Payload> {
  type: string;
  payload?: Payload;
  error?: boolean;
}

export const createAction = (type: string) =>
  function action<Payload, R>(payload?: Payload, ...rest: any[]) {
    return {
      payload,
      type,
      ...rest,
    };
  };

interface IApiActionTypes {
  DEFAULT: string;
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
}

export interface IApiError {
  message: string;
  response: {
    data: {
      fault?: string;
    };
  };
}

export const createApiAction = <
  Payload = {},
  SuccessData = {},
  ErrorMessage = string
>(
  type: IApiActionTypes,
): IApiAction<Payload, SuccessData, ErrorMessage> => ({
  default: createAction(type.DEFAULT),
  fail: createAction(type.FAILURE),
  loading: createAction(type.REQUEST),
  success: createAction(type.SUCCESS),
});

export const createApiActionTypes = (base: string): IApiActionTypes => ({
  DEFAULT: base,
  FAILURE: `${base}_FAILURE`,
  REQUEST: `${base}_REQUEST`,
  SUCCESS: `${base}_SUCCESS`,
});

export const getApiErrorMessage = (error: IApiError): string => {
  const fault = undefined;
  return fault || error.message || 'someWentWrong';
};

export interface IApiCallback {
  success?: (data?: any) => any;
  failure?: (error?: any) => any;
}
