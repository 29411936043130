import * as React from 'react';

export const PendingApprovalIcon: React.SFC<{
  iconClass?: string;
  fillColor?: string;
}> = ({ iconClass, fillColor }) => (
  <svg
    width="12px"
    height="14px"
    viewBox="0 0 12 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={iconClass || ''}
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="pending_icn"
        transform="translate(-6.000000, -5.000000)"
        fill={fillColor || '#333333'}
      >
        <path
          d="M8,16.75 L8,16 C8,13.790861 9.790861,12 12,12 C14.209139,12 16,13.790861 16,16 L16,16.75 L17,16.75 C17.4142136,16.75 17.75,17.0857864 17.75,17.5 C17.75,17.9142136 17.4142136,18.25 17,18.25 L7,18.25 C6.58578644,18.25 6.25,17.9142136 6.25,17.5 C6.25,17.0857864 6.58578644,16.75 7,16.75 L8,16.75 Z M16,7.25 L16,8 C16,10.209139 14.209139,12 12,12 C9.790861,12 8,10.209139 8,8 L8,7.25 L7,7.25 C6.58578644,7.25 6.25,6.91421356 6.25,6.5 C6.25,6.08578644 6.58578644,5.75 7,5.75 L17,5.75 C17.4142136,5.75 17.75,6.08578644 17.75,6.5 C17.75,6.91421356 17.4142136,7.25 17,7.25 L16,7.25 Z M9.5,7.5 L9.5,8 C9.5,9.38071187 10.6192881,10.5 12,10.5 C13.3807119,10.5 14.5,9.38071187 14.5,8 L14.5,7.5 L9.5,7.5 Z M14.5,16 C14.5,14.6192881 13.3807119,13.5 12,13.5 C10.6192881,13.5 9.5,14.6192881 9.5,16 L9.5,16.5 L14.5,16.5 L14.5,16 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);
