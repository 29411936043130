import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const PRODUCTION_MODE = 'production';
let localesPath = '/locales/{{lng}}/{{ns}}.json';

if (process.env.NODE_ENV === PRODUCTION_MODE) {
  localesPath = `/static${localesPath}`;
}

i18n
  .use(initReactI18next)
  .use(LngDetector)
  .use(Backend)
  .init({
    ns: ['common', 'sidebar'],
    whitelist: ['en', 'fr'],
    interpolation: { escapeValue: false },
    debug: true,
    fallbackLng: 'en',
    defaultNS: 'sidebar',
    backend: {
      loadPath: localesPath,
    },
  });

export default i18n;
