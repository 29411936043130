import {combineReducers} from 'redux';
import main from '../components/main/main.reducer';
import notifications from '../components/notifications/notifications.reducer';
import agent from '../pages/agent/agent.reducer';
import auth from '../pages/login/auth.reducer';
import messages from '../pages/messages/messages.reducer';
import preferences from '../pages/preferences/preferences.reducer';
import referral from '../pages/referral/referral.reducer';
import reports from '../pages/reports/reports.reducer';
import transactions from '../pages/transactions/transactions.reducer';

export default combineReducers({
  auth,
  agent,
  notifications,
  main,
  messages,
  transactions,
  referral,
  reports,
  preferences,
});
