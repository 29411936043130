//import * as Sentry from '@sentry/react';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {request} from '../../utils';
import * as types from './ActionTypes';
import {IMessageFormData} from './components/message-forms';
import {getGroups, getMessageDetails, getMessages, saveMessage, sendMessage,} from './messages.actions';

function* getMessagesSaga({ payload }: any): any {
  try {
    yield put(getMessages.loading({} as any));
    const {
      rowsPerPage,
      page,
      filter: { status, dateTo, dateFrom },
    } = payload;

    const messagesParams: any = {
      method: 'GET',
      url: 'user/message',
      params: {
        page: page,
        size: rowsPerPage,
        dateTo,
        dateFrom,
      },
    };

    if (status) {
      messagesParams.params.draft = status === 'draft';
    }

    if (dateTo) {
      messagesParams.params = {
        ...messagesParams.params,
        from: dateFrom,
        to: dateTo,
      };
    }

    const data: any = (yield call(request, messagesParams)).data;
    yield put(getMessages.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load messages ', error); // tslint:disable-line
    yield put(getMessages.fail(error.toString())); // tslint:disable-line
  }
}

function* getMessageDetailsSaga({ payload }: { payload: any }): any {
  try {
    yield put(getMessageDetails.loading({} as any));

    const { id } = payload;
    const messageDetailsParams = {
      method: 'GET',
      url: `user/message/${id}/details`,
    };
    const data: any = (yield call(request, messageDetailsParams)).data;
    yield put(getMessageDetails.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load message details', error); // tslint:disable-line
    yield put(getMessageDetails.fail(error.toString())); // tslint:disable-line
  }
}

function* getGroupsSaga(): any {
  try {
    yield put(getGroups.loading({} as any));
    const messageDetailsParams = {
      method: 'GET',
      url: `user/message/group`,
    };
    const data: any = (yield call(request, messageDetailsParams)).data;
    yield put(getGroups.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load groups', error); // tslint:disable-line
    yield put(getGroups.fail(error.toString())); // tslint:disable-line
  }
}

function* sendMessageSaga({ payload }: { payload: IMessageFormData }): any {
  try {
    yield put(sendMessage.loading({} as any));
    const sendMessagesParams = {
      method: 'POST',
      url: 'user/message/send',
      data: {
        ...payload,
      },
      withNotification: true,
    };
    const data: any = (yield call(request, sendMessagesParams)).data;
    yield put(sendMessage.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to send messages ', error); // tslint:disable-line
    yield put(sendMessage.fail(error.toString())); // tslint:disable-line
  }
}

function* saveMessageSaga({ payload }: { payload: IMessageFormData }): any {
  try {
    yield put(saveMessage.loading({} as any));

    const saveMessagesParams = {
      method: 'POST',
      url: 'user/message/save',
      data: {
        ...payload,
      },
    };
    const data: any = (yield call(request, saveMessagesParams)).data;
    yield put(saveMessage.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to save messages ', error); // tslint:disable-line
    yield put(saveMessage.fail(error.toString())); // tslint:disable-line
  }
}

export function* messagesSaga() {
  yield all([
    takeEvery(types.GET_MESSAGES.DEFAULT, getMessagesSaga),
    // @ts-ignore
    takeEvery(types.GET_MESSAGE_DETAILS.DEFAULT, getMessageDetailsSaga),
    // @ts-ignore
    takeEvery(types.SEND_MESSAGE.DEFAULT, sendMessageSaga),
    // @ts-ignore
    takeEvery(types.SAVE_MESSAGE.DEFAULT, saveMessageSaga),
    takeEvery(types.GET_MESSAGE_GROUPS.DEFAULT, getGroupsSaga),
  ]);
}
