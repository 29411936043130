import Button from '@material-ui/core/Button';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import CashedIcon from '@material-ui/icons/Cached';
import classNames from 'classnames';
import * as React from 'react';

const buttonStyles = () =>
  createStyles({
    button: {
      color: '#006caf',
      flex: '0 1 100px',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 1.57,
      textAlign: 'center',
      textTransform: 'none',
      width: '100px',
    },
    rightIcon: {
      color: '#006caf',
      marginRight: '4px',
    },
  });

interface IRefreshButton extends WithStyles<typeof buttonStyles> {
  onClick?: () => void;
  className: string;
  label: React.ReactNode;
}

const RefreshButtonTemp: React.SFC<IRefreshButton> = (
  props: IRefreshButton,
) => {
  const { classes, className, label } = props;

  return (
    <Button
      size="small"
      className={classNames(classes.button, className)}
      onClick={props.onClick}
    >
      <CashedIcon className={classes.rightIcon} />
      {label}
    </Button>
  );
};
export const RefreshButton = withStyles(buttonStyles)(RefreshButtonTemp);
