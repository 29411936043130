import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import * as React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {signOut} from '../../pages/login/auth.actions';
import {IInitialState} from '../../store/initialStateTypes';
import {Sidebar} from '../side-bar';
import './main-styles.scss';

declare global {
  interface Window {
    asmCrypto: any;
    libphonenumber: any;
  }
}

const theme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&$disabled:before': {
          borderBottom: '1px solid #D4D4D4',
        },
        '&:after': {
          borderBottom: `1px solid #006caf`,
          zIndex: 4,
        },
        '&:before': {
          borderBottom: `1px solid #D4D4D4`,
          zIndex: 4,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `1px solid #006caf`,
        },
        borderBottomWidth: '1px',
        marginTop: 0,
      },
    },
    MuiInputBase: {
      root: {
        boxSizing: 'border-box',
        height: '35px',
        marginRight: '24px',
        '&$disabled': {
          color: '#333333',
        },
      },
    },
    MuiSvgIcon: {},
    MuiListItemIcon: {
      root: {
        minWidth: '26px',
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: '2px',
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: '13px',
      },
    },
    MuiSelect: {
      icon: {
        color: '#006caf',
      },
    },
    MuiButton: {
      root: {
        '&$disabled': {
          color: '#FFFFFF',
          opacity: 0.5,
        },
      },
    },
  },
});

interface IMainTempProps {
  signOut: (data: any) => void;
  isAuthenticated: boolean;
  firstName: string;
  lastName: string;
}

type Props = IMainTempProps & RouteComponentProps<any>;

const renderer = ({ days, hours, minutes, seconds }: any) => {
  return (
    <div className="flex-row">
      {`${days} Days ${hours} Hours ${minutes} Min ${seconds} Sec`}
    </div>
  );
};

class MainTemp extends React.Component<Props> {

  public render() {
    const {
      children,
      isAuthenticated,
      signOut: signOutProps,
      firstName,
      lastName,
    } = this.props;

    return (
      <div className="main flex-row">
        <Sidebar
          isAuthenticated={isAuthenticated}
          signOut={signOutProps}
          firstName={firstName}
          lastName={lastName}
        />
        <MuiThemeProvider theme={theme}>
          <div className="content">{children}</div>
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }: IInitialState) => ({
  isAuthenticated: auth.isAuthenticated,
  firstName: auth.firstName,
  lastName: auth.lastName,
});

export const Main = withRouter(
  connect(mapStateToProps, { signOut: signOut.default })(MainTemp),
);
