import * as React from 'react';

export const SearchIcon: React.SFC = () => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="search_icn"
        transform="translate(-5.000000, -5.000000)"
        fill="#006CAF"
      >
        <path
          d="M15.78691,14.6606519 L18.7890603,17.7282459 C19.0774804,18.0264607 19.0690837,18.5015815 18.7703057,18.7894579 C18.4715277,19.0773344 17.9955096,19.0689535 17.7070895,18.7707387 L14.7289853,15.7277149 C13.7063583,16.5297595 12.4168446,17.0081112 11.0153948,17.0081112 C7.69318401,17.0081112 5,14.3200039 5,11.0040556 C5,7.68810725 7.69318401,5 11.0153948,5 C14.3376057,5 17.0307897,7.68810725 17.0307897,11.0040556 C17.0307897,12.3801219 16.5669913,13.6480664 15.78691,14.6606519 Z M11.0153948,15.5070973 C13.507053,15.5070973 15.526941,13.4910168 15.526941,11.0040556 C15.526941,8.51709433 13.507053,6.5010139 11.0153948,6.5010139 C8.52373672,6.5010139 6.50384871,8.51709433 6.50384871,11.0040556 C6.50384871,13.4910168 8.52373672,15.5070973 11.0153948,15.5070973 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);
