import injectReducer from '../../store/injectReducer';
import * as types from './ActionTypes';
import {IAgentReducer} from './IAgentReducer';

export const InitialAgentReducer: IAgentReducer = {
  result: {
    kyc: {},
    actionLogs: {},
    accounts: {},
  },
  groups: [],
  accountsDetails: {},
  localities: [],
  error: '',
  isLoading: false,
  search: { content: [], totalElements: 0 },
  poolAccounts: [],
  hasLinkAccounts: true,
};

const requestFunctionHandler = (state: IAgentReducer) => ({
  ...state,
  isLoading: true,
});

const failureFunctionHandler = (state: IAgentReducer, { payload }: any) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default injectReducer(InitialAgentReducer, {
  [types.GET_AGENT_SEARCH_RESULT.REQUEST]: requestFunctionHandler,
  [types.DISABLE_AGENT.REQUEST]: requestFunctionHandler,
  [types.GET_POOL_ACCOUNTS.REQUEST]: requestFunctionHandler,
  [types.GET_AGENT.REQUEST]: requestFunctionHandler,
  [types.GET_LOCALITIES.REQUEST]: requestFunctionHandler,
  [types.GET_KYC_TABLE.REQUEST]: requestFunctionHandler,
  [types.GET_AGENT_ACTION_LOGS.REQUEST]: requestFunctionHandler,
  [types.GET_ACCOUNTS.REQUEST]: requestFunctionHandler,
  [types.GET_GROUPS.REQUEST]: requestFunctionHandler,
  [types.GET_ACCOUNTS_DETAILS.REQUEST]: requestFunctionHandler,

  [types.GET_AGENT_SEARCH_RESULT.FAILURE]: failureFunctionHandler,
  [types.GET_POOL_ACCOUNTS.FAILURE]: failureFunctionHandler,
  [types.GET_AGENT.FAILURE]: failureFunctionHandler,
  [types.GET_LOCALITIES.FAILURE]: failureFunctionHandler,
  [types.DISABLE_AGENT.FAILURE]: failureFunctionHandler,
  [types.GET_KYC_TABLE.FAILURE]: failureFunctionHandler,
  [types.GET_GROUPS.FAILURE]: failureFunctionHandler,
  [types.GET_ACCOUNTS.FAILURE]: failureFunctionHandler,
  [types.GET_ACCOUNTS_DETAILS.FAILURE]: failureFunctionHandler,
  [types.GET_AGENT_ACTION_LOGS.FAILURE]: failureFunctionHandler,
  [types.UPDATE_AGENT.FAILURE]: failureFunctionHandler,

  [types.GET_POOL_ACCOUNTS.SUCCESS]: (
    state: IAgentReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    poolAccounts: payload,
  }),
  [types.GET_AGENT_SEARCH_RESULT.SUCCESS]: (
    state: IAgentReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    search: { content: payload.content, totalElements: payload.totalElements },
  }),
  [types.GET_AGENT.SUCCESS]: (state: IAgentReducer, { payload }: any) => ({
    ...state,
    error: '',
    isLoading: false,
    result: payload,
  }),
  [types.GET_KYC_TABLE.SUCCESS]: (state: IAgentReducer, { payload }: any) => ({
    ...state,
    error: '',
    isLoading: false,
    result: {
      ...state.result,
      kyc: payload,
    },
  }),
  [types.GET_AGENT_ACTION_LOGS.SUCCESS]: (
    state: IAgentReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    result: {
      ...state.result,
      actionLogs: payload,
    },
  }),
  [types.GET_ACCOUNTS.SUCCESS]: (state: IAgentReducer, { payload }: any) => ({
    ...state,
    error: '',
    isLoading: false,
    result: {
      ...state.result,
      accounts: payload,
    },
  }),
  [types.GET_LOCALITIES.SUCCESS]: (state: IAgentReducer, { payload }: any) => ({
    ...state,
    error: '',
    isLoading: false,
    localities: payload,
  }),
  [types.GET_GROUPS.SUCCESS]: (state: IAgentReducer, { payload }: any) => ({
    ...state,
    error: '',
    isLoading: false,
    groups: payload,
  }),
  [types.GET_ACCOUNTS_DETAILS.SUCCESS]: (
    state: IAgentReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    accountsDetails: payload,
  }),
  [types.RESET_AGENT_SEARCH_RESULT]: (state: IAgentReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    search: InitialAgentReducer.search,
  }),
  [types.RESET_ACCOUNTS_DETAILS]: (state: IAgentReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    accountsDetails: InitialAgentReducer.accountsDetails,
  }),
  [types.RESET_AGENT]: (state: IAgentReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    result: InitialAgentReducer.result,
  }),
  [types.RESET_KYC_TABLE]: (state: IAgentReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    result: {
      ...state.result,
      kyc: {},
    },
  }),
  [types.RESET_ACCOUNTS]: (state: IAgentReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    result: {
      ...state.result,
      accounts: {},
    },
  }),
  [types.RESET_POOL_ACCOUNTS]: (state: IAgentReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    poolAccounts: [],
  }),
  [types.RESET_ACTION_LOGS]: (state: IAgentReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    result: {
      ...state.result,
      actionLogs: {},
    },
  }),
});
