import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import {connect} from 'react-redux';
import {IInitialState} from '../../store/initialStateTypes';
import {NavigationItem, SidebarItem} from './Sidebar';

const styles = () =>
  createStyles({
    icon: {
      color: '#ffffff',
      minWidth: '26px',
    },
  });

interface ICollapsableProps {
  classes?: any;
  title: string;
  requiredRoles?: string[];
  roles?: string[];
  selected?: boolean;
  children: any;
  theme?: any;
  icon?: any;
  openImmediately?: boolean;
}

class AppDrawerNavItemTemp extends React.Component<
  WithStyles<typeof styles> & ICollapsableProps,
  any
> {
  public state = {
    open: this.props.openImmediately,
  };

  public componentDidMount() {
    // So we only run this logic once.
    if (!this.props.openImmediately) {
      return;
    }

    // Center the selected item in the list container.
    const activeElement = document.querySelector(
      `.${this.props.classes.active}`,
    );
    if (activeElement && activeElement.scrollIntoView) {
      activeElement.scrollIntoView({});
    }
  }

  public handleClick = () => {
    this.setState((state: any) => ({ open: !state.open }));
  };

  public render() {
    const {
      children,
      classes,
      title,
      icon: Icon,
      selected,
      requiredRoles,
      roles,
      ...other
    } = this.props;

    const hasRequiredRole: boolean =
      Array.isArray(requiredRoles) && Array.isArray(roles)
        ? roles.some((role: string) => requiredRoles.indexOf(role) !== -1)
        : true;

    return hasRequiredRole ? (
      <div
        className="flex-column"
        style={
          selected ? { backgroundColor: 'rgba(0, 0, 0, 0.14)' } : undefined
        }
      >
        <NavigationItem
          onClick={this.handleClick}
          className="sidebar__list-item"
          disableGutters={true}
          selected={selected}
          style={{
            backgroundColor: 'initial',
          }}
          {...other}
        >
          <ListItemIcon className="icon" classes={{ root: classes.icon }}>
            <Icon />
          </ListItemIcon>
          <SidebarItem primary={title} />
          <ListItemIcon classes={{ root: classes.icon }}>
            {this.state.open ? (
              <ExpandLess classes={{ root: classes.icon }} />
            ) : (
              <ExpandMore classes={{ root: classes.icon }} />
            )}
          </ListItemIcon>
        </NavigationItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit={true}>
          {children}
        </Collapse>
      </div>
    ) : null;
  }
}

const mapStateToProps = ({ auth }: IInitialState) => ({
  roles: auth.roles,
});

export const CollapsableItem = connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(AppDrawerNavItemTemp),
);
