import * as React from 'react';

export const AgentIcon: React.SFC = () => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Icons-/-agent_icn" fill="#FFFFFF" fillRule="nonzero">
        <path
          d="M21.597302,29.5 L29,29.5 C29.2761424,29.5 29.5,29.2761424 29.5,29 L29.5,19 C28.2268374,19 27.1124486,18.3202089 26.5,17.3037933 C25.8875514,18.3202089 24.7731626,19 23.5,19 C22.300734,19 21.2423455,18.3968313 20.6116484,17.4773076 C20.0962413,18.386509 19.1197649,19 18,19 C16.8802351,19 15.9037587,18.386509 15.3883516,17.4773076 C14.7576545,18.3968313 13.699266,19 12.5,19 C11.2268374,19 10.1124486,18.3202089 9.5,17.3037933 C8.88755143,18.3202089 7.77316257,19 6.5,19 L6.5,29 C6.5,29.2761424 6.72385763,29.5 7,29.5 L14.597302,29.5 L14.597302,21 L21.597302,21 L21.597302,29.5 Z M27.5,14 L27.5,15.5 C27.5,16.6045695 28.3954305,17.5 29.5,17.5 C30.6045695,17.5 31.5,16.6045695 31.5,15.5 L31.5,14 L27.5,14 L27.5,14 Z M33,11.1032283 L33,15.5 C33,16.896198 32.1824737,18.1014525 31,18.6631844 L31,29 C31,30.1045695 30.1045695,31 29,31 L7,31 C5.8954305,31 5,30.1045695 5,29 L5,18.6631844 C3.81752633,18.1014525 3,16.896198 3,15.5 L3,11.1032283 L4.43848647,7.00616968 C4.8604503,5.80434359 5.9953372,5 7.26908734,5 L28.7309127,5 C30.0046628,5 31.1395497,5.80434359 31.5615135,7.00616968 L33,11.1032283 Z M25.5,14 L21.5,14 L21.5,15.5 C21.5,16.6045695 22.3954305,17.5 23.5,17.5 C24.6045695,17.5 25.5,16.6045695 25.5,15.5 L25.5,14 L25.5,14 Z M10.5,14 L10.5,15.5 C10.5,16.6045695 11.3954305,17.5 12.5,17.5 C13.6045695,17.5 14.5,16.6045695 14.5,15.5 L14.5,14 L10.5,14 Z M19.5,14 L16.5,14 L16.5,16 C16.5,16.8284271 17.1715729,17.5 18,17.5 C18.8284271,17.5 19.5,16.8284271 19.5,16 L19.5,14 L19.5,14 Z M8.5,14 L4.5,14 L4.5,15.5 C4.5,16.6045695 5.3954305,17.5 6.5,17.5 C7.6045695,17.5 8.5,16.6045695 8.5,15.5 L8.5,14 L8.5,14 Z M4.5,11.3589044 L4.5,12.5 L31.5,12.5 L31.5,11.3589044 L30.1462131,7.50308484 C29.9352312,6.9021718 29.3677877,6.5 28.7309127,6.5 L7.26908734,6.5 C6.63221227,6.5 6.06476882,6.9021718 5.8537869,7.50308484 L4.5,11.3589044 Z M16.0797317,22.5 L16.0797317,29.5 L20.0797317,29.5 L20.0797317,22.5 L16.0797317,22.5 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);
