import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as React from 'react';
import {connect} from 'react-redux';
import {SidebarItem} from '../side-bar';

import {Translation} from 'react-i18next';
import i18next from '../../i18next';
// import { getCountries } from '../main/main.actions';
import './language-switcher-styles.scss';

interface IOption {
  code: string;
  label: any;
}

const options: IOption[] = [
  {
    code: 'en',
    label: <Translation ns="common">{t => t('languages.en')}</Translation>,
  },
  {
    code: 'fr',
    label: <Translation ns="common">{t => t('languages.fr')}</Translation>,
  },
];
const ITEM_HEIGHT = 48;

interface ILanguageSwitcherState {
  anchorEl: React.ReactNode;
}
class LanguageSwitcher1 extends React.Component<any> {
  public state = {
    anchorEl: null,
  };

  public handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  public handleClose = () => {
    this.setState({ anchorEl: null });
  };

  public changeLanguage = (language: string) => () => {
    // this.props.boundGetCountries({ lang: language });
    this.setState({ anchorEl: null }, () => i18next.changeLanguage(language));
  };

  public render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const currentLanguage = options.find(
      (option: IOption) => option.code === i18next.language,
    );

    const codeLanguage = (currentLanguage && currentLanguage.code) || 'en';
    return (
      <>
        <div className="flex-row language-switcher" onClick={this.handleClick}>
          <ListItemIcon className="language-switcher__button">
            <SidebarItem primary={codeLanguage} />
          </ListItemIcon>
        </div>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200,
            },
          }}
        >
          {options.map(option => (
            <MenuItem
              key={option.code}
              selected={option.code === i18next.language}
              onClick={this.changeLanguage(option.code)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

const mapStateToProps = ({ main }: any) => ({
  countryIsLoading: main.countryIsLoading,
});

export const LanguageSwitcher = connect(
  mapStateToProps,
  {
    // boundGetCountries: getCountries.default,
  },
)(LanguageSwitcher1);
