import * as React from 'react';

export const ReportsIcon: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <path
      fill="#FFF"
      fillRule="nonzero"
      d="M8.5 12.272V28a1.5 1.5 0 0 0 1.5 1.5h16a1.5 1.5 0 0 0 1.5-1.5V8A1.5 1.5 0 0 0 26 6.5H14.235v5.772H8.5zm4.235-4.786l-3.358 3.286h3.358V7.486zM7 11l6.131-6H26a3 3 0 0 1 3 3v20a3 3 0 0 1-3 3H10a3 3 0 0 1-3-3V11zm7 8.25a.75.75 0 1 1 0-1.5h8a.75.75 0 1 1 0 1.5h-8zm0 3.904a.75.75 0 1 1 0-1.5h8a.75.75 0 1 1 0 1.5h-8z"
    />
  </svg>
);
