//import * as Sentry from '@sentry/react';
import {applyMiddleware, compose, createStore, Middleware, Store} from 'redux';
import createSagaMiddleware from 'redux-saga';
import initialState from './initialState';
import {IInitialState} from './initialStateTypes';
import reducer from './reducers';
import rootSaga from './rootSaga';

export const sagaMiddleware = createSagaMiddleware();
// const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const w: any = window as any;
const configureStore = (): Store<IInitialState> => {
  const composeEnhancers =
    typeof window === 'object' && w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(sagaMiddleware as Middleware),
    // sentryReduxEnhancer,
  );

  const store = createStore(
    reducer,
    initialState as any,
    enhancer,
  ) as Store<IInitialState>;

  sagaMiddleware.run(rootSaga);
  return store;
};

export default configureStore;
