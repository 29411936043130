//import * as Sentry from '@sentry/react';
import JSEncrypt from 'jsencrypt';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {request} from '../../utils';
import * as types from './ActionTypes';
import {
  calculateTransactions,
  confirmRollBack,
  getTokens,
  getTransactionsDetails,
  getTransactionsHistory,
  rollBacked,
} from './transactions.actions';

function* getTransactionsSaga({ payload }: { payload: any }): any {
  try {
    yield put(getTransactionsHistory.loading({} as any));

    const { rowsPerPage, page, filter } = payload;
    const transactionsHistoryParams = {
      method: 'GET',
      url: 'transactions',
      params: {
        page: page,
        size: rowsPerPage,
        ...filter,
      },
    };
    const data: any = (yield call(request, transactionsHistoryParams)).data;
    yield put(getTransactionsHistory.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load search result', error); // tslint:disable-line
    yield put(getTransactionsHistory.fail(error.toString())); // tslint:disable-line
  }
}

function* postCalculateRollBackSaga({ payload }: { payload: any }): any {
  try {
    yield put(rollBacked.loading({} as any));

    const calculateRollBackParams = {
      method: 'POST',
      apiVersion: 'v2',
      data: {
        id: payload,
      },
      url: `transactions/${payload}/rollback/calculate`,
    };
    const data: any = (yield call(request, calculateRollBackParams)).data;
    yield put(rollBacked.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load search result', error); // tslint:disable-line
    yield put(rollBacked.fail(error.toString())); // tslint:disable-line
  }
}

function* postConfirmRollBackSaga({ payload }: { payload: any }): any {
  try {
    yield put(confirmRollBack.loading({} as any));
    const encrypt = new JSEncrypt();
    const publicKeyParams = {
      method: 'GET',
      url: `profile/challenge`,
    };
    const publicKey: any = (yield call(request, publicKeyParams)).data;
    encrypt.setPublicKey(publicKey);
    const hash = encrypt.encrypt(payload.password);

    const confirmRollBackParams = {
      method: 'POST',
      apiVersion: 'v2',
      data: {
        code: payload.data.mfaCode,
        signature: hash,
      },
      url: `transactions/${payload.id}/rollback`,
    };
    const data: any = (yield call(request, confirmRollBackParams)).data;
    yield put(confirmRollBack.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load search result', error); // tslint:disable-line
    yield put(confirmRollBack.fail(error.toString())); // tslint:disable-line
  }
}

function* getTransactionsDetailsSaga({ payload }: { payload: any }): any {
  try {
    yield put(getTransactionsDetails.loading({} as any));

    const { transactionsId } = payload;
    const transactionsDetailsParams = {
      method: 'GET',
      url: `transactions/${transactionsId}/details`,
      apiVersion: 'v2',
    };
    const data: any = (yield call(request, transactionsDetailsParams)).data;
    yield put(getTransactionsDetails.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load transactions details', error); // tslint:disable-line
    yield put(getTransactionsDetails.fail(error.toString())); // tslint:disable-line
  }
}

function* getTokensSaga({ payload }: { payload: any }): any {
  try {
    yield put(getTokens.loading({} as any));

    const { rowsPerPage, page, filter } = payload;
    const tokensParams = {
      method: 'GET',
      url: 'tokens',
      params: {
        page: page,
        size: rowsPerPage,
        ...filter,
      },
    };
    const data: any = (yield call(request, tokensParams)).data;
    yield put(getTokens.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load search result', error); // tslint:disable-line
    yield put(getTokens.fail(error.toString())); // tslint:disable-line
  }
}

function* getCalculateDetailsSaga({ payload }: { payload: any }): any {
  try {
    yield put(calculateTransactions.loading({} as any));
    const { type, id, formData } = payload;
    let requestUrl: string;
    const accountId= formData.accountId;
    if (type === 'agent') {
      requestUrl = `agent/${id}/cashin/${accountId}/calculate`;  
    } else {
      requestUrl = `companies/${id}/cashin/${accountId}/calculate`;
    }
    delete formData.accountId;

    const calculateParams = {
      method: 'POST',
      url: requestUrl,
      payload: {
        ...formData,
        amount: parseFloat(formData.amount.split(' ').join('')),
      },
    };
    const data: any = (yield call(request, calculateParams)).data;
    yield put(calculateTransactions.success({accountId,...data}));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load calculate details of transaction', error); // tslint:disable-line
    yield put(calculateTransactions.fail(error.toString())); // tslint:disable-line
  }
}

// Saga watchers / listeners
export function* transactionsSaga() {
  yield all([
    // @ts-ignore
    takeEvery(types.GET_TRANSACTIONS.DEFAULT, getTransactionsSaga),
    // @ts-ignore
    takeEvery(types.GET_TRANSACTIONS_DETAILS.DEFAULT, getTransactionsDetailsSaga),
    // @ts-ignore
    takeEvery(types.CALCULATE_TRANSACTION.DEFAULT, getCalculateDetailsSaga),
    // @ts-ignore
    takeEvery(types.GET_TOKENS.DEFAULT, getTokensSaga),
    // @ts-ignore
    takeEvery(types.ROLL_BACK.DEFAULT, postCalculateRollBackSaga),
    // @ts-ignore
    takeEvery(types.CONFIRM_ROLL_BACK.DEFAULT, postConfirmRollBackSaga),
  ]);
}
