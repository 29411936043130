import * as React from 'react';

export const AdministratorsIcon: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#87C7F0"
      fillRule="nonzero"
      d="M4.5 15.5h15V6a.5.5 0 0 0-.5-.5H5a.5.5 0 0 0-.5.5v9.5zM5 4h14a2 2 0 0 1 2 2v11H3V6a2 2 0 0 1 2-2zM2.786 17.75h18.487a.75.75 0 1 1 0 1.5H2.786a.75.75 0 1 1 0-1.5z"
    />
  </svg>
);
