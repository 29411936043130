import * as React from 'react';

export const BurgerIconClosed: React.SFC = () => (
  <svg
    width="25px"
    height="21px"
    viewBox="0 0 25 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="expand_menu_icn"
        transform="translate(-6.000000, -8.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <path
          d="M15.75,10.5 C15.3357864,10.5 15,10.1642136 15,9.75 C15,9.33578644 15.3357864,9 15.75,9 L29.5833333,9 C29.9975469,9 30.3333333,9.33578644 30.3333333,9.75 C30.3333333,10.1642136 29.9975469,10.5 29.5833333,10.5 L15.75,10.5 Z M15.75,16.25 C15.3357864,16.25 15,15.9142136 15,15.5 C15,15.0857864 15.3357864,14.75 15.75,14.75 L29.5833333,14.75 C29.9975469,14.75 30.3333333,15.0857864 30.3333333,15.5 C30.3333333,15.9142136 29.9975469,16.25 29.5833333,16.25 L15.75,16.25 Z M6.75,22.25 C6.33578644,22.25 6,21.9142136 6,21.5 C6,21.0857864 6.33578644,20.75 6.75,20.75 L29.5409032,20.75 C29.9551168,20.75 30.2909032,21.0857864 30.2909032,21.5 C30.2909032,21.9142136 29.9551168,22.25 29.5409032,22.25 L6.75,22.25 Z M6.75,28.25 C6.33578644,28.25 6,27.9142136 6,27.5 C6,27.0857864 6.33578644,26.75 6.75,26.75 L29.5409032,26.75 C29.9551168,26.75 30.2909032,27.0857864 30.2909032,27.5 C30.2909032,27.9142136 29.9551168,28.25 29.5409032,28.25 L6.75,28.25 Z M6.3419295,16.5587171 L10.0688772,13 L6.3419295,9.44128293 C6.01227415,9.12650789 5.99557175,8.59914501 6.30462364,8.26338496 C6.61367553,7.92762492 7.13145005,7.91061321 7.4611054,8.22538825 L11.8247398,12.3920527 C12.1695312,12.7212805 12.1695312,13.2787195 11.8247398,13.6079473 L7.4611054,17.7746117 C7.13145005,18.0893868 6.61367553,18.0723751 6.30462364,17.736615 C5.99557175,17.400855 6.01227415,16.8734921 6.3419295,16.5587171 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);
