//import * as Sentry from '@sentry/react';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {request} from '../../utils';
import * as types from './ActionTypes';
import {IFilter} from './components';
import {getReferralCampaign, getReferralList, getReferralUser,} from './referral.actions';

interface ReferralProps {
  filter: IFilter;
  rowsPerPage: number;
  page: number;
}

function* referralListSaga({ payload }: { payload: ReferralProps }): any {
  try {
    yield put(getReferralList.loading({} as any));

    const {
      filter: { campaignType, search, campaignStatus, startDate, endDate },
    } = payload;

    const searchParams = {
      type: campaignType,
      q: search,
      active: campaignStatus,
      startDate,
      endDate,
    };

    const referralListParams = {
      method: 'GET',
      params: {
        ...searchParams,
        page: payload.page,
        size: payload.rowsPerPage,
      },
      url: 'referral/campaign',
    };
    const data = (yield call(request, referralListParams)).data;
    yield put(getReferralList.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load referral list', error); // tslint:disable-line
    yield put(getReferralList.fail(error.toString()));
  }
}

function* referralCampaignSaga({ payload }: { payload: any }): any {
  try {
    yield put(getReferralCampaign.loading({} as any));

    const referralCampaignParams = {
      method: 'GET',
      url: `referral/campaign/statistic/${payload.id}`,
    };
    const data = (yield call(request, referralCampaignParams)).data;
    yield put(getReferralCampaign.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load referral list', error); // tslint:disable-line
    yield put(getReferralCampaign.fail(error.toString()));
  }
}

function* referralUserSaga({ payload }: { payload: any }): any {
  try {
    yield put(getReferralUser.loading({} as any));

    const referralUserParams = {
      method: 'GET',
      url: `referral/campaign/user/statistic/${payload.id}`,
      params: {
        page: payload.page,
        size: payload.rowsPerPage,
      },
    };
    const data = (yield call(request, referralUserParams)).data;
    yield put(getReferralUser.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load referral list', error); // tslint:disable-line
    yield put(getReferralUser.fail(error.toString()));
  }
}

// Saga watchers / listeners
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* referralSaga() {
  yield all([
    // @ts-ignore
    takeEvery(types.GET_REFERRAL_LIST.DEFAULT, referralListSaga),
    // @ts-ignore
    takeEvery(types.GET_REFERRAL_CAMPAIGN.DEFAULT, referralCampaignSaga),
    // @ts-ignore
    takeEvery(types.GET_REFERRAL_USER.DEFAULT, referralUserSaga),
  ]);
}
