import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import * as React from 'react';
import { DatePicker } from '../date-picker';
import { IUsedField } from './hooks';

export * from './hooks';

export interface IFieldProps extends IUsedField {
  label: string;
  className?: string;
  minDate?: any;
  maxDate?: any;
  inputComponent?: any;
  formControlClassName?: string;
  disabled?: boolean;
  type?: string;
  margin?: 'none' | 'normal' | 'dense' | undefined;
  InputLabelProps?: any;
  endAdornment?: React.ReactNode;
  helperText?: string | React.ReactNode;
  maxLength?: number;
}

export const FieldTemp: React.FunctionComponent<
  IFieldProps & WithStyles<typeof styles>
> = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  errors,
  inputComponent,
  endAdornment,
  pristine,
  validate,
  className,
  formControlClassName,
  classes,
  helperText,
  onRevert,
  setErrors,
  type,
  margin,
  formPrefix,
  maxLength,
  ...other
}) => {
  const showErrors = !pristine && !!errors.length;

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    validate();
  };
  return (
    <FormControl
      error={showErrors}
      className={classNames('flex-column', formControlClassName)}
    >
      <TextField
        id={name}
        className={className}
        label={label}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlurHandler}
        margin={margin || 'normal'}
        classes={{
          root: classes.input,
        }}
        {...other}
        inputProps={{ max:maxLength }}
        InputProps={{
          classes: {
            root: classes.input,
          },
          inputComponent,
          endAdornment,
        }}
      />
      <FormHelperText component="div">
        <span>{showErrors ? errors[0] : helperText || ''}</span>
      </FormHelperText>
    </FormControl>
  );
};
const styles = createStyles({
  input: {
    marginRight: 0,
  },
});

export const Field = withStyles(styles)(FieldTemp);

export const DatePickerFieldTemp: React.FunctionComponent<
  IFieldProps & WithStyles<typeof styles>
> = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  errors,
  endAdornment,
  pristine,
  validate,
  className,
  margin,
  formControlClassName,
  classes,
  helperText,
  onRevert,
  setErrors,
  type,
  formPrefix,
  ...other
}) => {
  const showErrors = !pristine && !!errors.length;
  const errorsRender = errors.map((errorMsg) => (
    <div key={errorMsg}>{errorMsg}</div>
  ));

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    validate();
  };
  const style = helperText ? { color: '#009d40' } : undefined;
  return (
    <FormControl
      error={showErrors}
      className={classNames('flex-column', formControlClassName)}
    >
      <DatePicker
        id={name}
        showPlaceholder={false}
        className={className}
        // classes={classes}
        label={label}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlurHandler}
        style={helperText ? { color: '#009d40' } : undefined}
        helperText={helperText}
        margin={margin || 'normal'}
        InputProps={{
          style,
          endAdornment,
        }}
        {...other}
      />
      {showErrors && (
        <FormHelperText component="div">{errorsRender}</FormHelperText>
      )}
    </FormControl>
  );
};

export const DatePickerField = withStyles(styles)(DatePickerFieldTemp);
