import IconButton from '@material-ui/core/IconButton';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import * as React from 'react';

import {TablePaginationProps} from '@material-ui/core/TablePagination';
import {Theme} from '@material-ui/core/styles';

class TablePaginationTemp extends React.Component<
  TablePaginationProps &
    WithStyles<typeof paginationStyles> & { theme: Theme } & {
      first: boolean;
      last: boolean;
    }
> {
  public handleBackButtonClick = (event: any) => {
    if (this.props.page) {
      this.props.onPageChange(event, this.props.page - 1);
    }
  };

  public handleNextButtonClick = (event: any) => {
    this.props.onPageChange(event, this.props.page + 1);
  };

  public render() {
    const { classes, theme, first, last } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={first}
          classes={{ root: classes.iconButton }}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight classes={{ root: classes.icon }} />
          ) : (
            <KeyboardArrowLeft classes={{ root: classes.icon }} />
          )}
        </IconButton>
        <IconButton
          classes={{ root: classes.iconButton }}
          onClick={this.handleNextButtonClick}
          disabled={last}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft classes={{ root: classes.icon }} />
          ) : (
            <KeyboardArrowRight classes={{ root: classes.icon }} />
          )}
        </IconButton>
      </div>
    );
  }
}

const paginationStyles = (theme: Theme) =>
  createStyles({
    iconButton: {
      color: '#006caf',
    },
    root: {
      flexShrink: 0,
      marginLeft: 8 * 2.5,
    },
    icon: {
      color: 'inherit',
    },
  });

export const TablePaginationWrapped = withStyles(paginationStyles, {
  withTheme: true,
})(TablePaginationTemp);
