import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem, {ListItemProps} from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import * as React from 'react';
import {Translation} from 'react-i18next';
import {connect} from 'react-redux';
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom';
import packageJson from '../../../package.json';
import {IInitialState} from '../../store/initialStateTypes';
import {
    getFullName,
    ROLE_AUDIT,
    ROLE_COMPLIANCE,
    ROLE_POOL_MANAGER,
    ROLE_SUPER_ADMIN,
    ROLE_TRANSACTION_MANAGER,
    sliceString,
} from '../../utils';
import {AdminIcon} from '../icons';
import {LanguageSwitcher} from '../language-switcher';
import {CollapsableItem} from './CollapsableItem';
import {
    AddCampaignIcon,
    AdministratorsIcon,
    AgentIcon,
    BigLogo,
    BurgerIconClosed,
    BurgerIconOpen,
    LogoutIcon,
    PreferencesIcon,
    ReferralListIcon,
    ReportsIcon,
    ReportsUsersIcon,
    SendMessageIcon,
    SmallLogo,
    SystemAccountsIcon,
    TokensIcon,
    TransactionHistoryIcon,
    TransactionsIcon,
} from './icons';
import {CountriesIcon} from './icons/countries-icon';
import './sidebar-styles.scss';
import {Theme} from '@material-ui/core/styles';
import { BankIcon } from './icons/bank-icon';

interface ISidebarItemProps extends WithStyles<typeof sidebarStyles> {
  primary: string;
  isNested?: boolean;
}

const sidebarStyles = createStyles({
  primary: {
    color: '#ffffff',
    fontFamily: '\'Roboto\', sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '0.07em',
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
  root: {
    paddingLeft: 0,
  },
  nested: {
    textTransform: 'none',
  },
});

const SidebarItemTemp: React.FunctionComponent<ISidebarItemProps> = ({
  primary,
  isNested,
  classes,
}) => (
  <ListItemText
    className="list-item__text"
    primary={primary}
    classes={{
      primary: classNames({
        [classes.primary]: true,
        [classes.nested]: isNested,
      }),
      root: classes.root,
    }}
  />
);

export const SidebarItem = withStyles(sidebarStyles)(SidebarItemTemp);

interface INavigationItem extends ListItemProps {
  to?: string;
  requiredRoles?: string[];
  roles?: string[];
  component?: any;
  selectedNested?: boolean;
  button?: boolean;
  isNested?: boolean;
}

const NavigationLink = (props: any) => <NavLink {...props} />;

const NavigationItemTemp: React.FunctionComponent<
  INavigationItem & WithStyles<typeof navigationItemStyles>
> = ({
  children,
  classes,
  isNested,
  selectedNested,
  className,
  roles,
  selected,
  style,
  onClick,
  to,
  component,
  requiredRoles,
  ...props
}) => {
  const hasRequiredRole: boolean =
    Array.isArray(requiredRoles) && Array.isArray(roles)
      ? roles.some((role: string) => requiredRoles.indexOf(role) !== -1)
      : true;

  return hasRequiredRole ? (
    <ListItem
      className={className}
      style={style}
      onClick={onClick}
      component={component}
      selected={selected}
      to={to}
      classes={{
        root: classNames({
          [classes.nestedItem]: isNested,
          [classes.selectedNested]: selectedNested,
        }),
      }}
    >
      {children}
    </ListItem>
  ) : null;
};
const navigationItemStyles = createStyles({
  selectedNested: {
    backgroundColor: '#005387 !important',
    marginLeft: '12px',
    paddingLeft: '14px !important',
  },
  nestedItem: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.57',
    letterSpacing: 'normal',
    color: '#87c7f0',
  },
});

const mapStateToProps = ({ auth }: IInitialState) => ({
  roles: auth.roles,
});

export const NavigationItem = connect(mapStateToProps)(
  withStyles(navigationItemStyles, {
    withTheme: true,
  })(NavigationItemTemp),
);

interface ISidebarProps {
  signOut: (data: any) => void;
  isAuthenticated: boolean;
  firstName: string;
  lastName: string;
}

type Props = RouteComponentProps<any> &
  WithStyles<typeof styles> &
  ISidebarProps;

interface ISidebarState {
  open: boolean;
}

class SidebarTemp extends React.Component<Props, ISidebarState> {
  public state = {
    open: true,
  };

  public onSidebarOpen = () => {
    this.setState(({ open: prevOpen }) => ({ open: !prevOpen }));
  };

  public logOut = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    this.props.signOut({});
  };

  public render() {
    const {
      classes,
      isAuthenticated,
      location,
      firstName,
      lastName,
    } = this.props;
    const { open } = this.state;
    const currentRoute = location.pathname;

    return isAuthenticated ? (
      <Drawer
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.drawerPaper]: true,
          }),
        }}
        variant="permanent"
        open={open}
      >
        <div className="burger-icon" onClick={this.onSidebarOpen}>
          {!open ? <BurgerIconClosed /> : <BurgerIconOpen />}
        </div>
        <Divider />
        <div className="sidebar-logo">{open ? <BigLogo /> : <SmallLogo />}</div>
        <Divider />
        <div className="navigation-bar">
          <Translation ns="sidebar">
            {(t, { i18n }) => (
              <List className="sidebar-list">
                <NavigationItem
                  requiredRoles={[ROLE_SUPER_ADMIN]}
                  selected={currentRoute.startsWith('/agent/search')}
                  component={NavigationLink}
                  to="/agent/search"
                  className="sidebar__list-item"
                >
                  <ListItemIcon
                    className="icon"
                    classes={{ root: classes.icon }}
                  >
                    <AgentIcon />
                  </ListItemIcon>
                  <SidebarItem primary={t('sidebar_agent')} />
                </NavigationItem>
                <CollapsableItem
                  requiredRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_COMPLIANCE,
                    ROLE_TRANSACTION_MANAGER,
                  ]}
                  title={t('sidebar_transactions')}
                  icon={TransactionsIcon}
                  selected={
                    currentRoute.startsWith('/transactions/history') ||
                    currentRoute.startsWith('/transactions/tokens')
                  }
                >
                  <NavigationItem
                    requiredRoles={[
                      ROLE_SUPER_ADMIN,
                      ROLE_COMPLIANCE,
                      ROLE_TRANSACTION_MANAGER,
                    ]}
                    isNested={true}
                    selectedNested={currentRoute.startsWith(
                      '/transactions/history',
                    )}
                    selected={
                      currentRoute.startsWith('/transactions/history') ||
                      currentRoute.startsWith('/transactions/tokens')
                    }
                    component={NavigationLink}
                    to="/transactions/history"
                    className="sidebar__list-item sidebar__list-item__without_border nested_item"
                  >
                    <ListItemIcon
                      className="icon"
                      classes={{ root: classes.icon }}
                    >
                      <TransactionHistoryIcon />
                    </ListItemIcon>
                    <SidebarItem
                      primary={t('sidebar_transaction_history')}
                      isNested={true}
                    />
                  </NavigationItem>
                  <NavigationItem
                    requiredRoles={[ROLE_SUPER_ADMIN]}
                    isNested={true}
                    selectedNested={currentRoute.startsWith(
                      '/transactions/tokens',
                    )}
                    selected={
                      currentRoute.startsWith('/transactions/history') ||
                      currentRoute.startsWith('/transactions/token')
                    }
                    component={NavigationLink}
                    to="/transactions/tokens"
                    className="sidebar__list-item sidebar__list-item__without_border nested_item"
                  >
                    <ListItemIcon
                      className="icon"
                      classes={{ root: classes.icon }}
                    >
                      <TokensIcon />
                    </ListItemIcon>
                    <SidebarItem
                      primary={t('sidebar_tokens')}
                      isNested={true}
                    />
                  </NavigationItem>
                </CollapsableItem>
                <CollapsableItem
                  requiredRoles={[ROLE_SUPER_ADMIN]}
                  title={t('sidebar_reports')}
                  icon={ReportsIcon}
                  selected={
                    currentRoute.startsWith('/reports/users') ||
                    currentRoute.startsWith('/reports/balance') ||
                    currentRoute.startsWith('/reports/movements')
                  }
                >
                  <NavigationItem
                    requiredRoles={[ROLE_SUPER_ADMIN]}
                    isNested={true}
                    selectedNested={currentRoute.startsWith('/reports/users')}
                    selected={
                      currentRoute.startsWith('/reports/users') ||
                      currentRoute.startsWith('/reports/balance') ||
                      currentRoute.startsWith('/reports/movements')
                    }
                    component={NavigationLink}
                    to="/reports/users"
                    className="sidebar__list-item sidebar__list-item__without_border"
                  >
                    <ListItemIcon
                      className="icon"
                      classes={{ root: classes.icon }}
                    >
                      <ReportsUsersIcon />
                    </ListItemIcon>
                    <SidebarItem
                      primary={t('sidebar_user_totals')}
                      isNested={true}
                    />
                  </NavigationItem>
                  <NavigationItem
                    requiredRoles={[ROLE_SUPER_ADMIN]}
                    isNested={true}
                    selectedNested={currentRoute.startsWith('/reports/balance')}
                    selected={
                      currentRoute.startsWith('/reports/users') ||
                      currentRoute.startsWith('/reports/balance') ||
                      currentRoute.startsWith('/reports/movements')
                    }
                    component={NavigationLink}
                    to="/reports/balance"
                    className="sidebar__list-item sidebar__list-item__without_border"
                  >
                    <ListItemIcon
                      className="icon"
                      classes={{ root: classes.icon }}
                    >
                      <SystemAccountsIcon />
                    </ListItemIcon>
                    <SidebarItem
                      primary={t('sidebar_accounts_balance')}
                      isNested={true}
                    />
                  </NavigationItem>
                  {/*<NavigationItem*/}
                  {/*isNested={true}*/}
                  {/*selectedNested={currentRoute.startsWith(*/}
                  {/*'/reports/movements',*/}
                  {/*)}*/}
                  {/*selected={*/}
                  {/*currentRoute.startsWith('/reports/users') ||*/}
                  {/*currentRoute.startsWith('/reports/balance') ||*/}
                  {/*currentRoute.startsWith('/reports/movements')*/}
                  {/*}*/}
                  {/*component={NavigationLink}*/}
                  {/*to="/reports/movements"*/}
                  {/*className="sidebar__list-item sidebar__list-item__without_border"*/}
                  {/*>*/}
                  {/*<ListItemIcon*/}
                  {/*className="icon"*/}
                  {/*classes={{ root: classes.icon }}*/}
                  {/*>*/}
                  {/*<SystemAccountsIcon />*/}
                  {/*</ListItemIcon>*/}
                  {/*<SidebarItem*/}
                  {/*primary={t('sidebar_accounts_movements')}*/}
                  {/*isNested={true}*/}
                  {/*/>*/}
                  {/*</NavigationItem>*/}
                </CollapsableItem>
                <NavigationItem
                  requiredRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_POOL_MANAGER,
                    ROLE_TRANSACTION_MANAGER,
                  ]}
                  selected={currentRoute.startsWith('/messages')}
                  component={NavigationLink}
                  to="/messages"
                  className="sidebar__list-item"
                >
                  <ListItemIcon
                    className="icon"
                    classes={{ root: classes.icon }}
                  >
                    <SendMessageIcon />
                  </ListItemIcon>
                  <SidebarItem primary={t('sidebar_send_message')} />
                </NavigationItem>
                <NavigationItem
                  requiredRoles={[ROLE_SUPER_ADMIN]}
                  selected={
                    (currentRoute.startsWith('/referral/list') ||
                      currentRoute.startsWith('/referral/campaign')) &&
                    currentRoute !== '/referral/campaign/new'
                  }
                  component={NavigationLink}
                  to="/referral/list"
                  className="sidebar__list-item"
                >
                  <ListItemIcon
                    className="icon"
                    classes={{ root: classes.icon }}
                  >
                    <ReferralListIcon />
                  </ListItemIcon>
                  <SidebarItem primary={t('sidebar_referral_list')} />
                </NavigationItem>
                <NavigationItem
                  requiredRoles={[ROLE_SUPER_ADMIN]}
                  selected={currentRoute === '/referral/campaign/new'}
                  component={NavigationLink}
                  to="/referral/campaign/new"
                  className="sidebar__list-item"
                >
                  <ListItemIcon
                    className="icon"
                    classes={{ root: classes.icon }}
                  >
                    <AddCampaignIcon />
                  </ListItemIcon>
                  <SidebarItem
                    primary={
                      sliceString(
                        t('sidebar_add_referral_campaign'),
                        19,
                      ) as string
                    }
                  />
                </NavigationItem>
                <CollapsableItem
                  title={t('sidebar_preferences')}
                  icon={PreferencesIcon}
                  selected={currentRoute.startsWith('/preferences')}
                  requiredRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_AUDIT,
                    ROLE_POOL_MANAGER,
                  ]}
                >
                  <NavigationItem
                    requiredRoles={[ROLE_SUPER_ADMIN]}
                    isNested={true}
                    selectedNested={currentRoute.startsWith(
                      '/preferences/administrators/list',
                    )}
                    selected={currentRoute.startsWith('/preferences')}
                    component={NavigationLink}
                    to="/preferences/administrators/list"
                    className="sidebar__list-item sidebar__list-item__without_border"
                  >
                    <ListItemIcon
                      className="icon"
                      classes={{ root: classes.icon }}
                    >
                      <AdministratorsIcon />
                    </ListItemIcon>
                    <SidebarItem
                      primary={t('sidebar_preferences_administrators')}
                      isNested={true}
                    />
                  </NavigationItem>
                  <NavigationItem
                    requiredRoles={[
                      ROLE_SUPER_ADMIN,
                      ROLE_AUDIT,
                      ROLE_POOL_MANAGER,
                    ]}
                    isNested={true}
                    selectedNested={currentRoute.startsWith(
                      '/preferences/system-accounts/list',
                    )}
                    selected={currentRoute.startsWith('/preferences')}
                    component={NavigationLink}
                    to="/preferences/system-accounts/list"
                    className="sidebar__list-item sidebar__list-item__without_border"
                  >
                    <ListItemIcon
                      className="icon"
                      classes={{ root: classes.icon }}
                    >
                      <SystemAccountsIcon />
                    </ListItemIcon>
                    <SidebarItem
                      primary={t('sidebar_preferences_system_accounts')}
                      isNested={true}
                    />
                  </NavigationItem>
                  <NavigationItem
                    requiredRoles={[
                      ROLE_SUPER_ADMIN,
                      ROLE_AUDIT,
                      ROLE_POOL_MANAGER,
                    ]}
                    isNested={true}
                    selectedNested={currentRoute.startsWith(
                      '/preferences/bank/list',
                    )}
                    selected={currentRoute.startsWith('/preferences')}
                    component={NavigationLink}
                    to="/preferences/bank/list"
                    className="sidebar__list-item sidebar__list-item__without_border"
                  >
                    <ListItemIcon
                      className="icon"
                      classes={{ root: classes.icon }}
                    >
                      <BankIcon />
                    </ListItemIcon>
                    <SidebarItem
                      primary={t('sidebar_preferences_Bank')}
                      isNested={true}
                    />
                  </NavigationItem>
                  <NavigationItem
                    requiredRoles={[
                      ROLE_SUPER_ADMIN,
                      ROLE_AUDIT,
                      ROLE_POOL_MANAGER,
                    ]}
                    isNested={true}
                    selectedNested={currentRoute.startsWith(
                      '/preferences/countries',
                    )}
                    selected={currentRoute.startsWith('/preferences')}
                    component={NavigationLink}
                    to="/preferences/countries"
                    className="sidebar__list-item sidebar__list-item__without_border"
                  >
                    <ListItemIcon
                      className="icon"
                      classes={{ root: classes.icon }}
                    >
                      <CountriesIcon />
                    </ListItemIcon>
                    <SidebarItem
                      primary={t('sidebar_preferences_countries')}
                      isNested={true}
                    />
                  </NavigationItem>
                </CollapsableItem>
                <NavigationItem
                  style={{ bottom: '40px' }}
                  component="div"
                  requiredRoles={[ROLE_SUPER_ADMIN, ROLE_AUDIT]}
                  selected={false}
                  disableGutters={true}
                  className="version-divider sidebar__list-item sidebar__list-item__bottom"
                >
                  <ListItemIcon
                    className="icon"
                    classes={{ root: classes.icon }}
                  >
                    <AdminIcon />
                  </ListItemIcon>
                  <NavigationItem
                    component={NavigationLink}
                    to="/preferences"
                    disableGutters={true}
                    selected={false}
                  >
                    <div className="account__name">
                      <SidebarItem
                        primary={
                          sliceString(
                            getFullName({ firstName, lastName }),
                            8,
                          ) as string
                        }
                        isNested={true}
                      />
                    </div>
                  </NavigationItem>
                  <LanguageSwitcher />
                </NavigationItem>
                <NavigationItem
                  disableGutters={true}
                  component="div"
                  selected={false}
                  className="sidebar__list-item sidebar__list-item__bottom version_sidebar"
                >
                  {open && (
                    <ListItemText
                      primary={`v${packageJson.version}`}
                      className="text_version"
                    />
                  )}
                  <ListItemIcon className="exit__icon" onClick={this.logOut}>
                    <LogoutIcon />
                  </ListItemIcon>
                </NavigationItem>
              </List>
            )}
          </Translation>
        </div>
      </Drawer>
    ) : null;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      minWidth: '26px',
    },
    drawer: {
      backgroundColor: '#006caf',
      border: 'none',
      flexShrink: 0,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '84px',
    },
    drawerClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
      width: '84px',
      [theme.breakpoints.up('sm')]: {
        width: 8 * 9 + 1,
      },
    },
    drawerOpen: {
      overflow: 'hidden',
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
      }),
      width: '260px',
    },
    drawerPaper: {
      backgroundColor: '#006caf',
      border: 'none',
      whiteSpace: 'nowrap',
    },
  });

export const Sidebar = withRouter(
  withStyles(styles, { withTheme: true })(SidebarTemp),
);
