import {createAction, createApiAction} from '../../store/helpers';
import * as types from './ActionTypes';

export const getMessages = createApiAction(types.GET_MESSAGES);
export const getGroups = createApiAction(types.GET_MESSAGE_GROUPS);
export const sendMessage = createApiAction(types.SEND_MESSAGE);
export const saveMessage = createApiAction(types.SAVE_MESSAGE);
export const getMessageDetails = createApiAction(types.GET_MESSAGE_DETAILS);

export const resetMessageDetails = createAction(types.RESET_MESSAGE_DETAILS);
