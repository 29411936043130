import * as React from 'react';
import {Provider} from 'react-redux';
import {Loader} from './components';
import configureStore from './store/configureStore';

export const store = configureStore();
const AppRouter = React.lazy(() => import('./router'));

class App extends React.Component {
  public render() {
    return (
      <React.Suspense fallback={<Loader loaded={false} />}>
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </React.Suspense>
    );
  }
}

export default App;
