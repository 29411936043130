import {createAction, createApiAction} from '../../store/helpers';
import * as types from './ActionTypes';

export const getAdministratorsList = createApiAction(
  types.GET_ADMINISTRATORS_LIST,
);
export const resetAdministratorsList = createAction(
  types.RESET_ADMINISTRATORS_LIST,
);

export const getSystemAccountsList = createApiAction(
  types.GET_SYSTEM_ACCOUNTS_LIST,
);
export const rebalanceSystemAccounts = createApiAction(
  types.REBALANCE_SYSTEM_ACCOUNTS,
);
export const cashInSystemAccounts = createApiAction(
  types.CASH_IN_SYSTEM_ACCOUNTS,
);
export const cashOutSystemAccounts = createApiAction(
  types.CASH_OUT_SYSTEM_ACCOUNTS,
);
export const cashInAgent = createApiAction(types.CASH_IN_AGENT);
export const cashInCompany = createApiAction(types.CASH_IN_COMPANY);
export const cashOutAgent = createApiAction(types.CASH_OUT_AGENT);
export const cashOutCompany = createApiAction(types.CASH_OUT_COMPANY);
export const resetSystemAccountsList = createAction(
  types.RESET_SYSTEM_ACCOUNTS_LIST,
);

export const closeTransactionDetailsModal = createAction(
  types.CLOSE_TRANSACTION_DETAILS_MODAL,
);
export const closeModalSuccess = createAction(types.CLOSE_MODAL_SUCCESS);

export const getAdmin = createApiAction(types.GET_ADMIN);
export const updateAdmin = createApiAction(types.UPDATE_ADMIN);
export const resetAdmin = createAction(types.RESET_ADMIN);

export const getRoles = createApiAction(types.GET_ROLES);
export const getTypes = createApiAction(types.GET_TYPES);

export const blockAdmin = createApiAction(types.BLOCK_ADMIN);
export const resetCredential = createApiAction(types.RESET_CREDENTIAL);
