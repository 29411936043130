import {fork} from 'redux-saga/effects';
import {mainSaga} from '../components/main/main.saga';
import {notificationSaga} from '../components/notifications/notifications.saga';
import {agentSaga} from '../pages/agent/agent.saga';
import {authSaga} from '../pages/login/auth.saga';
import {messagesSaga} from '../pages/messages/messages.saga';
import {preferencesSaga} from '../pages/preferences/preferences.saga';
import {referralSaga} from '../pages/referral/referral.saga';
import {reportsSaga} from '../pages/reports/reports.saga';
import {transactionsSaga} from '../pages/transactions/transactions.saga';

export default function* root(): any {
  console.log('> Root saga started'); // tslint:disable-line
  yield fork(authSaga);
  yield fork(mainSaga);
  yield fork(referralSaga);
  yield fork(notificationSaga);
  yield fork(agentSaga);
  yield fork(transactionsSaga);
  yield fork(messagesSaga);
  yield fork(preferencesSaga);
  yield fork(reportsSaga);
}
