import * as React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {IInitialState} from '../../store/initialStateTypes/IInitialState';
import {INotification} from './INotificationsReducer';
import Notification from './Notification';
import './notification-styles.scss';

interface INotificationsBaseProps {
  notifications: INotification[];
}

interface INotificationsBaseState {
  open: boolean;
}

class NotificationsBase extends React.Component<
  INotificationsBaseProps,
  INotificationsBaseState
> {
  public domNode: HTMLElement | null = document.getElementById('notifications');

  public render() {
    const { notifications } = this.props;
    if (notifications.length && this.domNode) {
      return ReactDOM.createPortal(
        <div className="notifications">
          {notifications.map((notification: INotification) => (
            <Notification key={notification.id} {...notification} />
          ))}
        </div>,
        this.domNode,
      );
    }
    return null;
  }
}

export const Notifications = connect((state: IInitialState) => ({
  notifications: state.notifications,
}))(NotificationsBase);
