import injectReducer from '../../store/injectReducer';
import * as types from './ActionTypes';
import {IPreferencesReducer} from './IPreferencesReducer';

export const InitialPreferencesReducer: IPreferencesReducer = {
  administrators: { content: [], totalElements: 0, first: true, last: false },
  systemAccountsList: {
    content: [],
    totalElements: 0,
    first: true,
    last: false,
  },
  admin: {},
  isLoading: false,
  roles: [],
  types: [],
  closeTransactionModal: false,
  successReset: false,
  error: '',
};

const requestFunctionHandler = (state: IPreferencesReducer) => ({
  ...state,
  isLoading: true,
});

const failureFunctionHandler = (
  state: IPreferencesReducer,
  { payload }: any,
) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default injectReducer(InitialPreferencesReducer, {
  [types.GET_ADMINISTRATORS_LIST.REQUEST]: requestFunctionHandler,
  [types.GET_ADMINISTRATORS_LIST.FAILURE]: failureFunctionHandler,

  [types.BLOCK_ADMIN.REQUEST]: requestFunctionHandler,
  [types.BLOCK_ADMIN.SUCCESS]: (state: IPreferencesReducer) => ({
    ...state,
    isLoading: false,
    error: '',
  }),
  [types.BLOCK_ADMIN.FAILURE]: failureFunctionHandler,

  [types.GET_ADMINISTRATORS_LIST.SUCCESS]: (
    state: IPreferencesReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    administrators: {
      first: payload.first,
      last: payload.last,
      content: payload.content,
      totalElements: payload.totalElements,
    },
  }),
  [types.RESET_ADMINISTRATORS_LIST]: (
    state: IPreferencesReducer,
    action: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    administrators: InitialPreferencesReducer.administrators,
  }),
  [types.GET_ADMIN.REQUEST]: requestFunctionHandler,

  [types.GET_ADMIN.FAILURE]: failureFunctionHandler,

  [types.GET_ADMIN.SUCCESS]: (
    state: IPreferencesReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    admin: payload,
  }),
  [types.RESET_ADMIN]: (state: IPreferencesReducer, action: any) => ({
    ...state,
    isLoading: false,
    admin: InitialPreferencesReducer.admin,
  }),

  [types.GET_ROLES.SUCCESS]: (
    state: IPreferencesReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    roles: payload,
  }),

  [types.GET_TYPES.SUCCESS]: (
    state: IPreferencesReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    types: payload,
  }),

  [types.GET_SYSTEM_ACCOUNTS_LIST.REQUEST]: requestFunctionHandler,
  [types.GET_SYSTEM_ACCOUNTS_LIST.FAILURE]: failureFunctionHandler,

  [types.GET_SYSTEM_ACCOUNTS_LIST.SUCCESS]: (
    state: IPreferencesReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    systemAccountsList: {
      content: payload.content,
      totalElements: payload.totalElements,
      first: payload.first,
      last: payload.last,
    },
  }),
  [types.RESET_SYSTEM_ACCOUNTS_LIST]: (
    state: IPreferencesReducer,
    action: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    systemAccountsList: InitialPreferencesReducer.systemAccountsList,
  }),
  [types.CLOSE_TRANSACTION_DETAILS_MODAL]: (
    state: IPreferencesReducer,
    { payload }: { payload: boolean },
  ) => ({
    ...state,
    closeTransactionModal: payload,
  }),
  [types.RESET_CREDENTIAL.REQUEST]: requestFunctionHandler,
  [types.RESET_CREDENTIAL.FAILURE]: failureFunctionHandler,
  [types.RESET_CREDENTIAL.SUCCESS]: (state: IPreferencesReducer) => ({
    ...state,
    error: '',
    isLoading: false,
    successReset: true,
  }),
  [types.CLOSE_MODAL_SUCCESS]: (state: IPreferencesReducer) => ({
    ...state,
    successReset: false,
  }),
});
