import * as React from 'react';

export const SmallLogo: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <g fill="#FFF" fillRule="evenodd">
      <path d="M10.628 27.079l3.01-5.6c2.874.27 5.762.292 8.43-1.422 3.373-2.168 4.474-6.26 3.082-10.05-1.19-3.23-3.19-5.42-6.111-6.74-1.322 2.2-2.584 4.028-3.267 5.15.654.344 1.61.886 2.959 2.005 1.628 1.35 3.165 3.43 3.66 7.02-.31.226-1.087 1.076-1.407 1.257-.043-3.384-1.895-5.58-3.379-6.985-.315-.296-.514-.492-.771-.657-1.103-.846-2.26-1.355-3.563-1.735l5.26-8.436c.343.128.685.27 1.024.427 7.768 3.621 10.29 13.401 5.393 20.36-3.216 4.568-8.918 6.712-14.32 5.406zM21.08 10.276a1.595 1.595 0 1 1 0-3.19 1.595 1.595 0 0 1 0 3.19z" />
      <path d="M16.828.378l-3.009 5.6c-2.875-.271-5.762-.292-8.43 1.422C2.016 9.567.915 13.66 2.308 17.45c1.19 3.23 3.19 5.42 6.111 6.74 1.322-2.201 2.584-4.028 3.267-5.15-.654-.344-1.61-.886-2.959-2.005-1.628-1.35-3.166-3.431-3.66-7.02.31-.226 1.087-1.076 1.407-1.258.043 3.385 1.895 5.58 3.379 6.986.315.296.514.491.77.657 1.104.846 2.262 1.355 3.564 1.735l-5.26 8.436c-.344-.128-.686-.27-1.025-.428-7.767-3.62-10.29-13.4-5.392-20.36C5.724 1.217 11.427-.927 16.829.379zM6.376 17.181a1.595 1.595 0 1 1 0 3.19 1.595 1.595 0 0 1 0-3.19z" />
    </g>
  </svg>
);
