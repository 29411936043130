import * as React from 'react';

export const SendMessageIcon: React.SFC = () => (
  <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1">
    <g
      id="Icons-/-new-message"
      stroke="none"
      stroke-width="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M28,5 C30.209139,5 32,6.790861 32,9 L32,23 C32,25.209139 30.209139,27 28,27 L16.014,27 L8,32.9648403 L8,27 C5.790861,27 4,25.209139 4,23 L4,9 C4,6.790861 5.790861,5 8,5 L28,5 Z M28,6.5 L8,6.5 C6.61928813,6.5 5.5,7.61928813 5.5,9 L5.5,23 C5.5,24.3807119 6.61928813,25.5 8,25.5 L9.5,25.5 L9.5,29.9785018 L15.5170451,25.5 L28,25.5 C29.3807119,25.5 30.5,24.3807119 30.5,23 L30.5,9 C30.5,7.61928813 29.3807119,6.5 28,6.5 Z"
        id="Shape"
        fill="#FFFFFF"
      />
      <g
        id="user-plus"
        transform="translate(15.000000, 12.000000)"
        stroke="#FFFFFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      >
        <line x1="3.5" y1="0" x2="3.5" y2="7" id="Path" />
        <line x1="7" y1="3.5" x2="0" y2="3.5" id="Path" />
      </g>
    </g>
  </svg>
);
