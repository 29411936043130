import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';

interface IColumn {
  name: string;
  cellRenderer?: (data: any) => any;
  label: string;
  hasSort?: boolean;
  width?: number;
  maxWidth?: number;
}

interface ITableHeader {
  columns: IColumn[];
}

export const TableHeader: React.SFC<ITableHeader> = ({ columns }) => (
  <TableHead>
    <TableRow>
      {columns.map(({ name, label, hasSort, width, maxWidth }) => {
        return <TableCell key={name}>{label || name} </TableCell>;
      })}
    </TableRow>
  </TableHead>
);
