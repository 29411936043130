import intersection from 'lodash/intersection';
import * as React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, RouteComponentProps, RouteProps, withRouter,} from 'react-router-dom';
import {IInitialState} from '../../store/initialStateTypes';
import {ROLE_AUDIT, ROLE_COMPLIANCE, ROLE_POOL_MANAGER, ROLE_TRANSACTION_MANAGER,} from '../../utils';

interface IPrivateRoute extends RouteComponentProps<any> {
  component: any;
  path: string;
  authenticated: boolean;
  requiredRoles?: string[];
  roles: string[];
  exact?: boolean;
  children?: React.ReactNode;
}

const ROLES = [ROLE_COMPLIANCE, ROLE_AUDIT];
const REDIRECT_ACCORDING_ROLES: {
  [index: string]: string;
} = {
  [ROLE_AUDIT]: '/preferences/system-accounts/list',
  [ROLE_COMPLIANCE]: '/transactions/history',
  [ROLE_POOL_MANAGER]: '/preferences/system-accounts/list',
  [ROLE_TRANSACTION_MANAGER]: '/transactions/history',
};

const PrivateRouteTemp: React.SFC<IPrivateRoute> = ({
  component: Component,
  requiredRoles,
  roles,
  authenticated,
  ...rest
}) => {
  const hasRequiredRole: boolean = Array.isArray(requiredRoles)
    ? roles.some((role: string) => requiredRoles.indexOf(role) !== -1)
    : true;

  let render;
  if (authenticated) {
    if (hasRequiredRole) {
      render = (props: RouteProps) => <Component {...props} />;
    } else {
      const rolesKeys = Object.keys(roles);
      if (rolesKeys.length === 1) {
        render = (props: RouteProps) => (
          <Redirect to={REDIRECT_ACCORDING_ROLES[roles[0]] || '/referral'} />
        );
      } else if (rolesKeys.length > 1) {
        const matchedRoles = intersection(ROLES, roles);
        render = (props: RouteProps) => (
          <Redirect
            to={REDIRECT_ACCORDING_ROLES[matchedRoles[0]] || '/referral'}
          />
        );
      } else {
        render = (props: RouteProps) => <Redirect to="/referral" />;
      }
    }
  } else {
    render = (props: RouteProps) => <Redirect to="/login" />;
  }
  return <Route {...rest} render={render} />;
};

const mapStateToProps = ({ auth }: IInitialState) => ({
  authenticated: auth.isAuthenticated,
  roles: auth.roles,
});

export const PrivateRoute = withRouter(
  connect(mapStateToProps)(PrivateRouteTemp),
);
