import {createApiActionTypes} from '../../store/helpers';

export const GET_AGENT_SEARCH_RESULT = createApiActionTypes(
  'GET_AGENT_SEARCH_RESULT',
);
export const RESET_AGENT_SEARCH_RESULT = 'RESET_AGENT_SEARCH_RESULT';

export const GET_AGENT = createApiActionTypes('GET_AGENT');
export const DISABLE_AGENT = createApiActionTypes('DISABLE_AGENT');
export const UPDATE_AGENT = createApiActionTypes('UPDATE_AGENT');
export const GET_KYC_TABLE = createApiActionTypes('GET_KYC_TABLE');
export const GET_AGENT_ACTION_LOGS = createApiActionTypes(
  'GET_AGENT_ACTION_LOGS',
);
export const GET_LOCALITIES = createApiActionTypes('GET_LOCALITIES');
export const GET_GROUPS = createApiActionTypes('GET_GROUPS');
export const GET_ACCOUNTS = createApiActionTypes('GET_ACCOUNTS');
export const GET_ACCOUNTS_DETAILS = createApiActionTypes(
  'GET_ACCOUNTS_DETAILS',
);
export const RESET_ACCOUNTS_DETAILS = 'RESET_ACCOUNTS_DETAILS';
export const RESET_ACCOUNTS = 'RESET_ACCOUNTS';
export const RESET_ACTION_LOGS = 'RESET_ACTION_LOGS';
export const RESET_KYC_TABLE = 'RESET_KYC_TABLE';
export const RESET_AGENT = 'RESET_AGENT';
export const RESET_POOL_ACCOUNTS = 'RESET_POOL_ACCOUNTS';
export const ROLLBACK_TRANSACTION = createApiActionTypes(
  'ROLLBACK_TRANSACTION',
);
export const SET_PASSWORD = createApiActionTypes('SET_PASSWORD');
export const SET_PIN = createApiActionTypes('SET_PIN');
export const CASH_IN = createApiActionTypes('CASH_IN');
export const CASH_OUT = createApiActionTypes('CASH_OUT');
export const GET_POOL_ACCOUNTS = createApiActionTypes('GET_POOL_ACCOUNTS');
