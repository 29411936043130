import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import * as React from 'react';
import {ITransactionsDetails} from '../../pages/transactions/models/interfaces';

interface IModalProps {
  open: boolean;
  disableBackdropClick?: boolean;
  disablePadding?: boolean;
  availibleToScroll?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: React.ReactNode;
  className?: string;
  transactionsDetails?: ITransactionsDetails;
}

class ModalTemp extends React.Component<
  WithStyles<typeof modalStyles> & IModalProps
> {
  public render() {
      return (
        <Dialog
          maxWidth="lg"
          disableBackdropClick={this.props.disableBackdropClick}
          onClose={this.props.onClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          classes={{
            paper: classNames(this.props.className),
          }}
        >
          <DialogTitle onClose={this.props.onClose}>
            {this.props.title}
          </DialogTitle>
          <DialogContent
            classes={
              this.props.disablePadding
                ? { root: this.props.classes.disablePadding }
                : this.props.availibleToScroll
                  ? { root: this.props.classes.availibleToScroll }
                  : undefined
            }
          >
            {this.props.children}
          </DialogContent>
        </Dialog>
      );
  }
}

const modalStyles = createStyles({
  disablePadding: {
    padding: 0,
    overflow: 'hidden',
  },
  availibleToScroll: {
    padding: 0,
  },
  root: {
    flex: '0 1 990px',
  },
});

export const Modal = withStyles(modalStyles)(ModalTemp);

const dialogTitleStyles = createStyles({
  closeButton: {
    color: '#006caf',
  },
  root: {
    alignItems: 'center',
    borderBottom: `2px solid #e2e2e2`,
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: '4px 16px',
    color: '#006caf',
    fontWeight: 700,
  },
});

const DialogTitleTemp: React.FunctionComponent<
  WithStyles<typeof dialogTitleStyles> & { onClose: () => void }
> = props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography={true} className={classes.root}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogTitle = withStyles(dialogTitleStyles)(DialogTitleTemp);
