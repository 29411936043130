import injectReducer from '../../store/injectReducer';
import * as types from './ActionTypes';
import {INotification, INotificationsReducer} from './INotificationsReducer';

export const InitialNotificationReducer: INotification[] = [];

export default injectReducer(InitialNotificationReducer, {
  [types.ADD_NOTIFICATION]: (
    state: INotificationsReducer,
    { payload }: { payload: any },
  ) => [payload, ...state],
  [types.REMOVE_NOTIFICATION]: (
    state: INotificationsReducer,
    { payload }: { payload: any },
  ) => state.filter(notification => notification.id !== payload),
});
