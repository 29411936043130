import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankRounded from '@material-ui/icons/CheckBoxOutlineBlankRounded';
import CheckBoxRounded from '@material-ui/icons/CheckBoxRounded';
import * as React from 'react';

import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

interface IInputProps {
  label: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  value?: boolean | undefined;
  defaultValue?: string | undefined;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxTemp: React.FunctionComponent<
  IInputProps & WithStyles<typeof styles>
> = ({
  label,
  name,
  disabled,
  classes,
  className,
  value = false,
  defaultValue,
  required,
  onChange,
}) => {
  return (
    <FormControlLabel
      classes={{
        label: classes.label,
      }}
      control={
        <MuiCheckbox
          disabled={disabled}
          name={name}
          className={classNames(classes.root, className, {
            [classes.checked]: value,
          })}
          checked={value}
          defaultValue={defaultValue}
          icon={<CheckBoxOutlineBlankRounded />}
          checkedIcon={<CheckBoxRounded />}
          onChange={onChange}
          required={required}
          color="default"
        />
      }
      label={label}
    />
  );
};

const styles = () =>
  createStyles({
    root: {
      color: '#e2e2e2',
      '&$checked': {
        color: '#006caf',
      },
    },
    checked: {},
    label: {
      display: 'flex',
      alignItems: 'flex-end',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.57',
      letterSpacing: 'normal',
      color: '#777777',
    },
  });

export const Checkbox = withStyles(styles)(CheckboxTemp);
