import * as React from 'react';
import {Backdrop, CircularProgress, makeStyles} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';

interface ILoader {
  loaded: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
  },
}));

export const Loader: React.FunctionComponent<ILoader> = ({ loaded }) => {
  const styles = useStyles();
  if (!loaded) {
    return (<Backdrop open={!loaded} className={styles.backdrop}>
        <CircularProgress color='primary' classes={{ colorPrimary: '#006caf' }} />
      </Backdrop>);
  } else return null;
};
