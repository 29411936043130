import {createAction} from '../../store/helpers';
import {ADD_NOTIFICATION, REMOVE_NOTIFICATION} from './ActionTypes';

export const createActionWithId = (type: string) => (
  payload: any,
  ...rest: any
) => ({
  payload: {
    ...payload,
    id: Date.now(),
  },
  type,
  ...rest,
});

export const addNotification = createActionWithId(ADD_NOTIFICATION);
export const removeNotification = createAction(REMOVE_NOTIFICATION);
