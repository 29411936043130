import {delay, put, takeEvery} from 'redux-saga/effects';

import {ADD_NOTIFICATION} from './ActionTypes';
import {removeNotification} from './notifications.actions';

function* addNotificationSaga({ payload }: { payload: any }) {
  yield delay(5000);
  yield put(removeNotification(payload.id));
}

export function* notificationSaga() {
  // @ts-ignore
  yield takeEvery(ADD_NOTIFICATION, addNotificationSaga);
}
