import classNames from 'classnames';
import * as React from 'react';
import './styles.scss';

export const InfoItem: React.FunctionComponent<{
  label: string;
  value: string | React.ReactElement | null;
  className?: string;
  classLabel?: string;
  classValue?: string;
  selectElem?: string;
}> = ({ label, value, className, classLabel, classValue, selectElem }) => {
  return (
    <div className={classNames('info-item-block', className, selectElem)}>
      <div className="flex-column">
        <div
          className={classNames(
            'info-item-block__label',
            classLabel,
            selectElem,
          )}
        >
          {label}
        </div>
        <div
          className={classNames(
            'info-item-block__value',
            classValue,
            selectElem,
          )}
        >
          {value}
        </div>
      </div>
    </div>
  );
};
