import injectReducer from '../../store/injectReducer';
import * as types from './ActionTypes';
import {ITransactionsReducer} from './ITransactionsReducer';

export const InitialTransactionReducer: ITransactionsReducer = {
  transactions: { content: [], totalElements: 0, first: true, last: false },
  tokens: { content: [], totalElements: 0, first: true, last: false },
  transactionsDetails: undefined,
  calculateDetails: undefined,
  isLoading: false,
  isRoll: false,
  isConfirmed: false,
  error: '',
};

const requestFunctionHandler = (state: ITransactionsReducer) => ({
  ...state,
  isLoading: true,
});

const failureFunctionHandler = (
  state: ITransactionsReducer,
  { payload }: any,
) => ({
  ...state,
  error: payload,
  isLoading: false,
  isConfirmed: false,
});

export default injectReducer(InitialTransactionReducer, {
  [types.GET_TOKENS.REQUEST]: requestFunctionHandler,
  [types.GET_TRANSACTIONS.REQUEST]: requestFunctionHandler,
  [types.GET_TRANSACTIONS_DETAILS.REQUEST]: requestFunctionHandler,
  [types.ROLL_BACK.REQUEST]: requestFunctionHandler,
  [types.CONFIRM_ROLL_BACK.REQUEST]: requestFunctionHandler,
  [types.CALCULATE_TRANSACTION.REQUEST]: requestFunctionHandler,

  [types.GET_TOKENS.FAILURE]: failureFunctionHandler,
  [types.GET_TRANSACTIONS.FAILURE]: failureFunctionHandler,
  [types.GET_TRANSACTIONS_DETAILS.FAILURE]: failureFunctionHandler,
  [types.ROLL_BACK.FAILURE]: failureFunctionHandler,
  [types.CONFIRM_ROLL_BACK.FAILURE]: failureFunctionHandler,
  [types.CALCULATE_TRANSACTION.FAILURE]: failureFunctionHandler,

  [types.GET_TOKENS.SUCCESS]: (
    state: ITransactionsReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    tokens: {
      content: payload.content,
      totalElements: payload.totalElements,
      first: payload.first,
      last: payload.last,
    },
  }),
  [types.GET_TRANSACTIONS.SUCCESS]: (
    state: ITransactionsReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    transactions: {
      content: payload.content,
      totalElements: payload.totalElements,
      first: payload.first,
      last: payload.last,
    },
  }),
  [types.GET_TRANSACTIONS_DETAILS.SUCCESS]: (
    state: ITransactionsReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    transactionsDetails: payload,
  }),
  [types.CALCULATE_TRANSACTION.SUCCESS]: (
    state: ITransactionsReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    calculateDetails: payload,
  }),
  [types.ROLL_BACK.SUCCESS]: (
    state: ITransactionsReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isRoll: true,
    isLoading: false,
    transactionsDetails: payload,
  }),

  [types.CONFIRM_ROLL_BACK.SUCCESS]: (
    state: ITransactionsReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    transactionsDetails: payload,
    isRoll: false,
    isConfirmed: true,
    isLoading: false,
  }),

  [types.CANCEL_ROLL_BACK]: (state: ITransactionsReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    isRoll: false,
  }),

  [types.RESET_TOKENS]: (state: ITransactionsReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    tokens: InitialTransactionReducer.tokens,
  }),
  [types.RESET_TRANSACTIONS]: (state: ITransactionsReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    transactions: InitialTransactionReducer.transactions,
  }),
  [types.RESET_TRANSACTIONS_DETAILS]: (
    state: ITransactionsReducer,
    action: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    isRoll: false,
    isConfirmed: false,
    transactionsDetails: InitialTransactionReducer.transactionsDetails,
  }),
});
