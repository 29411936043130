import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import * as React from 'react';
import {ADMIN_APPROVED_STATUS, ADMIN_REJECTED_STATUS,} from '../../utils/constants';
import {Toggle} from '../toggle';
import './info-block-styles.scss';

const styles = createStyles({
  chips: {
    backgroundColor: '#f4f5f8',
    borderRadius: '18px',
  },
  expanded: {
    margin: 0,
  },
  root: {
    '&:before': {
      display: 'none',
    },
    backgroundColor: '#f4f5f8',
    boxShadow: 'none',
  },
});

export interface IInfoBlockProps {
  label: string;
  labelValue?: string;
  className?: string;
  type?: string;
  disable?: boolean;
  status?: string;
  comment?: string | undefined;
  withToggle?: boolean;
  onStatusChange?: (type: string, status: string) => void;
  button?: React.ReactNode;
  onCommentChange?: (type: string, comment: string) => void;
}

type Props = IInfoBlockProps & WithStyles<typeof styles>;

interface IState {
  active: boolean;
}

const statuses: { [index: string]: string } = {
  false: ADMIN_REJECTED_STATUS,
  true: ADMIN_APPROVED_STATUS,
};

export class InfoBlockTemp extends React.Component<Props, IState> {
  public static getDerivedStateFromProps(props: Props, state: IState) {
    if (!state.active && props.status !== ADMIN_REJECTED_STATUS) {
      return {
        active: true,
      };
    } else if (state.active && props.status === ADMIN_REJECTED_STATUS) {
      return {
        active: false,
      };
    }
    return null;
  }

  public state = {
    active: !(this.props.status === ADMIN_REJECTED_STATUS),
    propStatus: ADMIN_APPROVED_STATUS,
  };

  public handleStatus = () => {
    if (this.props.onStatusChange && this.props.type) {
      const status = statuses[(!this.state.active).toString()];
      this.props.onStatusChange(this.props.type, status);
    }
  };

  public handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onCommentChange && this.props.type) {
      this.props.onCommentChange(this.props.type, event.target.value);
    }
  };

  public render() {
    const { active } = this.state;
    const {
      classes,
      label,
      labelValue,
      withToggle,
      children,
      comment,
      button,
      disable,
      className,
    } = this.props;

    return (
      <div className={classNames('flex-column info-block', className)}>
        <div className="flex-row info-block__header">
          <div className="info-block__header__label">{label}</div>
          <div className="info-block__header__value">{labelValue}</div>
          {withToggle && (
            <Toggle
              active={active}
              onClick={this.handleStatus}
              disable={disable}
            />
          )}
          {button}
        </div>
        <div className="info-block__container">{children}</div>
      </div>
    );
  }
}

export const InfoBlock = withStyles(styles)(InfoBlockTemp);
