import {createApiActionTypes} from '../../store/helpers';

export const GET_TRANSACTIONS = createApiActionTypes('GET_TRANSACTIONS');
export const GET_TRANSACTIONS_DETAILS = createApiActionTypes(
  'GET_TRANSACTIONS_DETAILS',
);
export const CALCULATE_TRANSACTION = createApiActionTypes(
  'CALCULATE_TRANSACTION',
);

export const GET_TOKENS = createApiActionTypes('GET_TOKENS');
export const ROLL_BACK = createApiActionTypes('ROLL_BACK');
export const CONFIRM_ROLL_BACK = createApiActionTypes('CONFIRM_ROLL_BACK');

export const RESET_TRANSACTIONS = 'RESET_TRANSACTIONS';
export const RESET_TOKENS = 'RESET_TOKENS';

export const RESET_TRANSACTIONS_DETAILS = 'RESET_TRANSACTIONS_DETAILS';
export const CANCEL_ROLL_BACK = 'CANCEL_ROLL_BACK';
