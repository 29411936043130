import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import * as React from 'react';
import {CustomizedTooltip} from '../components';

export const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return (
      (navigator as any).userLanguage ||
      navigator.language ||
      (navigator as any).browserLanguage ||
      'en'
    );
  }
};

const CURRENCY_FORMATTERS: { [index: string]: Intl.NumberFormat } = {};

export const getFormatter = (currency: string, digits: number = 0) => {
  
  // if (currency && CURRENCY_FORMATTERS[currency]) {
  //   console.log("currency ",currency)
  //   console.log("CURRENCY_FORMATTERS ",CURRENCY_FORMATTERS[currency])
  //   return CURRENCY_FORMATTERS[currency];
  // }
  let formatter: any;
  try {
    formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency,
      currencyDisplay: 'narrowSymbol'
    });
  } catch (e) {
    formatter = {};
    formatter.format = (value: any) => {
      return value.toFixed(digits) + ' ' + currency;
    };
  }
  CURRENCY_FORMATTERS[currency] = formatter;
  return formatter;
};

export const withOutCodeFormatter = (currency: number) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'code',
  });
  return formatter
    .format(currency)
    .replace(/[a-z]{3}/i, '')
    .trim();
};

export const getFullName = (data: {
  firstName?: string;
  lastName?: string;
}) => {
  if (!data) {
    return '';
  }
  let fullName = '';
  if (data.firstName) {
    fullName = `${data.firstName}`;
  }
  return `${fullName}${data.lastName ? ` ${data.lastName}` : ''}`;
};

export const sliceString = (str: string, length: number) => {
  if (str.length > length) {
    return (
      <CustomizedTooltip tooltip={str} placement="bottom">
        <div>{`${str.slice(0, length + 1)}...`}</div>
      </CustomizedTooltip>
    );
  }
  return str;
};

export const startDayTransformer = (date: any) => date.startOf('day');
export const endDayTransformer = (date: any) => date.endOf('day');

export const formatDate = (val: string) => format(new Date(val), 'yyyy-MM-dd');

export const some = (conditions: any[]) => conditions.some(Boolean);

export const every = (conditions: any[]) => conditions.every(Boolean);

export const timeZone = (time: string) => {
  const timeOffsetInHours = -new Date().getTimezoneOffset() / 60;
  return addHours(new Date(time), timeOffsetInHours);
};

export const scrollToFirstError = (selector = '.Mui-error') => {
  const error = document.querySelector(selector);
  if (!error) {
    return;
  }
  error.scrollIntoView({ behavior: 'smooth' });
};
