//@ts-nocheck

import InputAdornment from '@material-ui/core/InputAdornment';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SearchIcon } from './search-icon';
import './search-styles.scss';

interface ISearchProps {
  classes?: any;
  type?: string;
  name?: string;
  helperText?: string;
  className?: string;
  placeholderClassName?: string;
  placeholder?: string;
  value?: string | number;
  endAdornment?: React.ReactNode;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onChange?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
}

interface ISearchState {
  search?: string | number;
  searchProp?: string | number;
}

type Props = ISearchProps & WithStyles<typeof styles> & WithTranslation;

const searchAdornment = (
  <InputAdornment position="end">
    <SearchIcon />
  </InputAdornment>
);

class SearchTemp extends React.Component<Props, ISearchState> {
  public static getDerivedStateFromProps(props: Props, state: ISearchState) {
    if (state.searchProp !== props.value) {
      return {
        search: props.value,
        searchProp: props.value,
      };
    }
    return null;
  }

  public state = {
    search: this.props.value || '',
    searchProp: this.props.value || '',
  };

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    this.setState({ search: event.target.value }, () => {
      if (this.props.onChange) {
        this.props.onChange(event);
      }
    });
  };

  public resetSearch = () =>
    this.setState({ search: '' }, () => {
      if (this.props.onChange) {
        this.props.onChange({
          target: {
            name: this.props.name || 'search',
            value: '',
          },
        } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>);
      }
    });

  public render() {
    const {
      classes,
      placeholder,
      type,
      endAdornment,
      name,
      className,
      onKeyDown,
      helperText,
      placeholderClassName,
      t,
    } = this.props;
    const { search } = this.state;

    return (
      <div className={className || 'search-text'}>
        <TextField
          id="standard-search"
          name={name || 'search'}
          type={type || 'text'}
          value={search}
          helperText={helperText}
          InputProps={{
            classes: {
              root: classNames(classes.placeholder, placeholderClassName),
            },
            endAdornment: search ? (
              <InputAdornment position="end" className="delete-icon">
                <CloseIcon onClick={this.resetSearch} className="search-icon" />
              </InputAdornment>
            ) : (
              endAdornment || searchAdornment
            ),
            placeholder: placeholder || t('search.placeholder'),
          }}
          onChange={this.handleChange}
          onKeyDown={onKeyDown}
          className={classes.textField}
        />
      </div>
    );
  }
}

const styles = () =>
  createStyles({
    placeholder: {
      color: '#777777',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      lineHeight: 1.57,
    },
    textField: {
      width: '100%',
    },
  });

export const Search = withStyles(styles)(withTranslation('common')(SearchTemp));
