import * as React from 'react';

export const ApproveIcon: React.SFC<{
  iconClass?: string;
}> = ({ iconClass }) => (
  <svg
    width="14px"
    height="10px"
    viewBox="0 0 14 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={iconClass || ''}
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="check_icn"
        transform="translate(-5.000000, -7.000000)"
        fill="#08b407"
      >
        <path
          d="M6.44128293,16.7280575 C6.12650789,17.0577129 5.59914501,17.0744153 5.26338496,16.7653634 C4.92762492,16.4563115 4.91061321,15.938537 5.22538825,15.6088816 L13.9144964,7.25859351 C14.2437242,6.91380216 14.8011632,6.91380216 15.1303911,7.25859351 L18.645141,10.8626424 C18.9599161,11.1922978 18.9429044,11.7100723 18.6071443,12.0191242 C18.2713843,12.3281761 17.7440214,12.3114737 17.4292463,11.9818183 L14.5224437,9.01445614 L6.44128293,16.7280575 Z"
          id="Path-Copy"
          transform="translate(11.935265, 11.993327) rotate(-180.000000) translate(-11.935265, -11.993327) "
        />
      </g>
    </g>
  </svg>
);
