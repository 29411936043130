import endOfDay from 'date-fns/endOfDay';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import subWeeks from 'date-fns/subWeeks';
import * as React from 'react';
import {Translation} from 'react-i18next';
import {DatePicker} from '../date-picker';
import {Select} from '../form-fields';

export interface IDateChangeObject {
  [index: string]: string;
}

interface IPresetRangeProps {
  onDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPresetRangeChange: (data: IDateChangeObject) => void;
  dateFrom?: string;
  dateTo?: string;
  fromPlaceholder: string;
  toPlaceholder: string;
  label: string;
}

interface IPresetRangeState {
  presetRange?: string;
  propsDateTo?: string;
  propsDateFrom?: string;
}

const currentDate = new Date();
const presetRangeMapper: {
  [index: string]: { dateFrom: string; dateTo: string };
} = {
  today: {
    dateFrom: format(startOfDay(currentDate), 'yyyy-MM-dd HH:mm'),
    dateTo: format(endOfDay(currentDate), 'yyyy-MM-dd HH:mm'),
  },
  yesterday: {
    dateFrom: format(startOfDay(subDays(currentDate, 1)), 'yyyy-MM-dd HH:mm'),
    dateTo: format(endOfDay(subDays(currentDate, 1)), 'yyyy-MM-dd HH:mm'),
  },
  this_week: {
    dateFrom: format(startOfWeek(currentDate), 'yyyy-MM-dd HH:mm'),
    dateTo: format(endOfWeek(currentDate), 'yyyy-MM-dd HH:mm'),
  },
  previous_week: {
    dateFrom: format(startOfWeek(subWeeks(currentDate, 1)), 'yyyy-MM-dd HH:mm'),
    dateTo: format(endOfWeek(subWeeks(currentDate, 1)), 'yyyy-MM-dd HH:mm'),
  },
  this_month: {
    dateFrom: format(startOfMonth(currentDate), 'yyyy-MM-dd HH:mm'),
    dateTo: format(endOfMonth(currentDate), 'yyyy-MM-dd HH:mm'),
  },
  previous_month: {
    dateFrom: format(
      startOfMonth(subMonths(currentDate, 1)),
      'yyyy-MM-dd HH:mm',
    ),
    dateTo: format(endOfMonth(subMonths(currentDate, 1)), 'yyyy-MM-dd HH:mm'),
  },
};

const findPresetRange = (dateFrom?: string, dateTo?: string) => {
  return Object.keys(presetRangeMapper).find((key: string) => {
    const { dateFrom: dateFromRange, dateTo: dateToRange } = presetRangeMapper[
      key
    ];
    return dateFromRange === dateFrom && dateToRange === dateTo;
  });
};

class PresetRangeTemp extends React.Component<
  IPresetRangeProps,
  IPresetRangeState
> {
  public static getDerivedStateFromProps(
    props: IPresetRangeProps,
    state: IPresetRangeState,
  ) {
    const { dateFrom, dateTo } = props;
    const { propsDateFrom, propsDateTo } = state;
    const presetRange = findPresetRange(dateFrom, dateTo);
    if (dateFrom !== propsDateFrom || propsDateTo !== dateTo) {
      return {
        propsDateFrom: dateFrom,
        propsDateTo: dateTo,
        presetRange,
      };
    }
    return null;
  }
  public state = {
    presetRange: '',
    propsDateTo: this.props.dateTo,
    propsDateFrom: this.props.dateFrom,
  };

  public onChangePresetRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ presetRange: e.target.value });
    this.props.onPresetRangeChange(presetRangeMapper[e.target.value]);
  };

  public onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      e.target.name === 'dateTo'
        ? endOfDay(new Date(e.target.value))
        : startOfDay(new Date(e.target.value));
    this.props.onDateChange({
      target: {
        name: e.target.name,
        value: format(value, 'yyyy-MM-dd HH:mm'),
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  public render() {
    const {
      dateFrom,
      dateTo,
      fromPlaceholder,
      toPlaceholder,
      label,
    } = this.props;
    const { presetRange } = this.state;
    return (
      <React.Fragment>
        <DatePicker
          name="dateFrom"
          onChange={this.onChange}
          value={dateFrom}
          maxDate={dateTo}
          placeholder={fromPlaceholder}
          className="referral-date-picker"
        />
        <DatePicker
          name="dateTo"
          onChange={this.onChange}
          value={dateTo}
          minDate={dateFrom}
          placeholder={toPlaceholder}
          className="referral-date-picker"
        />
        <Select
          onChange={this.onChangePresetRange}
          value={presetRange}
          className="referral-select-filter"
          label={label}
          options={[
            {
              label: (
                <Translation ns="common">
                  {t => t('range_selector.today')}
                </Translation>
              ),
              value: 'today',
            },
            {
              label: (
                <Translation ns="common">
                  {t => t('range_selector.yesterday')}
                </Translation>
              ),
              value: 'yesterday',
            },
            {
              value: 'this_week',
              label: (
                <Translation ns="common">
                  {t => t('range_selector.this_week')}
                </Translation>
              ),
            },
            {
              value: 'previous_week',
              label: (
                <Translation ns="common">
                  {t => t('range_selector.previous_week')}
                </Translation>
              ),
            },
            {
              value: 'this_month',
              label: (
                <Translation ns="common">
                  {t => t('range_selector.this_month')}
                </Translation>
              ),
            },
            {
              value: 'previous_month',
              label: (
                <Translation ns="common">
                  {t => t('range_selector.previous_month')}
                </Translation>
              ),
            },
          ]}
          name="presetRange"
        />
      </React.Fragment>
    );
  }
}

export const PresetRange = PresetRangeTemp;
