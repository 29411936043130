import MuiChip from '@material-ui/core/Chip';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import * as React from 'react';

const styles = createStyles({
  chips: {
    backgroundColor: '#f4f5f8',
    borderRadius: '18px',
    marginBottom: '5px',
  }
});

export interface IChip {
  label: string | React.ReactNode;
  icon?: React.ElementType;
}

type Props = IChip & WithStyles<typeof styles>;

export class ChipTemp extends React.PureComponent<Props> {
  public render() {
    const { classes, label, icon } = this.props;

    let renderLabel;
    if (icon) {
      const IconChip: React.ElementType = icon;
      renderLabel = (
        <div className="flex-row text">
          <span className="chip-icon">
            <IconChip />
          </span>
          {label}
        </div>
      );
    } else {
      renderLabel = label;
    }
    return <MuiChip classes={{ root: classes.chips }} label={renderLabel} />;
  }
}

export const Chip = withStyles(styles)(ChipTemp);

