import {createApiAction} from '../../store/helpers';
import * as types from './ActionTypes';

export const getCurrencies = createApiAction(types.GET_CURRENCIES);
export const getDeliveryMethod = createApiAction(types.GET_DELIVERY_METHOD);
export const gettransactionType = createApiAction(types.GET_TRANSACTION_TYPE);
export const getProviderType = createApiAction(types.GET_PROVIDER_TYPE);
export const getCountriesDetail = createApiAction(types.GET_COUNTRIES_DETAIL);
export const getDomains = createApiAction(types.GET_DOMAINS);
export const addPhonePrefix = createApiAction(types.ADD_PHONE_PREFIX);
export const uploadFlag = createApiAction(types.UPLOAD_FLAG);
export const saveCountry = createApiAction(types.SAVE_COUNTRY);
export const getDocuments = createApiAction(types.GET_DOCUMENTS);
