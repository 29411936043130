import classNames from 'classnames';
import * as React from 'react';
import {Translation} from 'react-i18next';
import {RefreshButton} from '../refresh-button';
import {ResetButton} from '../reset-button';
import './list-page-header-styles.scss';

interface IListPageHeader {
  onRefreshButtonClick?: () => void;
  onResetFilterButtonClick?: () => void;
  showFilter?: boolean;
  label: React.ReactNode;
  header?: string;
  className?: string;
}

export const checkFilter = (filter: any): boolean => {
  const filterKeys = Object.keys(filter);
  return filterKeys.length ? filterKeys.some(key => filter[key] as any) : false;
};

export const ListPageHeader: React.FunctionComponent<IListPageHeader> = ({
  onRefreshButtonClick,
  onResetFilterButtonClick,
  showFilter,
  label,
  className,
  header,
}) => {
  return (
    <div className={classNames('flex-row header-block', className)}>
      {header ? <div className="pre-header">{header}&nbsp;-&nbsp;</div> : null}
      <div className="page-header">{label}</div>
      {onRefreshButtonClick && (
        <RefreshButton
          className="col-3"
          onClick={onRefreshButtonClick}
          label={
            <Translation ns="agent">
              {t => t('result.buttons.refresh')}
            </Translation>
          }
        />
      )}
      {onResetFilterButtonClick && showFilter && (
        <ResetButton
          className="col-3"
          resetFilter={onResetFilterButtonClick}
          label={
            <Translation ns="agent">
              {t => t('result.buttons.reset')}
            </Translation>
          }
        />
      )}
    </div>
  );
};
