import {ApproveIcon, PendingApprovalIcon, RejectedIcon,} from '../components/icons';

export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_AUDIT = 'ROLE_AUDIT';
export const ROLE_COMPLIANCE = 'ROLE_COMPLIANCE';
export const ROLE_KYC_AUDITOR = 'ROLE_KYC_AUDITOR';
export const ROLE_KYC_BASIC = 'ROLE_KYC_BASIC';
export const ROLE_AGENT_MANAGER = 'ROLE_AGENT_MANAGER';
export const ROLE_SUPPORT_ADMIN = 'ROLE_SUPPORT';
export const ROLE_TRANSACTION_MANAGER = 'ROLE_TRANSACTION_MANAGER';
export const ROLE_POOL_MANAGER = 'ROLE_POOL_MANAGER';

export const STEPS: any = {
  approvedStepCount: {
    icon: ApproveIcon,
    label: 'Approved: ',
  },
  needApprovalStepCount: {
    icon: PendingApprovalIcon,
    label: 'Pending approval: ',
  },
  rejectedStepCount: {
    icon: RejectedIcon,
    label: 'Rejected:',
  },
};

export const KYC_PROCESS_TYPE: any = {
  DEFAULT: 'Default',
  DEFAULT_CONGO: 'Default DRC',
  MILITARY_CD: 'Military DRC',
  DEFAULT_CI: 'Default Ivory Coast',
  DEFAULT_KM: 'Default Comoros',
  IQ_STAGE1: 'Iraq Stage 1',
  IQ_STAGE2: 'Iraq Stage 2',
  IQ_STAGE3: 'Iraq Stage 3',
  ID_CARD_FRONT: 'Id card front',
  ID_CARD_BACK: 'Id card back',
  RESIDENCE_CARD_FRONT: 'Residency card front',
  RESIDENCE_CARD_BACK: 'Residency card back',
  IQ_ADDITIONAL_DOCUMENT_FRONT: 'Additional document front',
  IQ_ADDITIONAL_DOCUMENT_BACK: 'Additional document back',
  BRAZIL: 'Brazil',
};

export const KYC_STEP_TYPE: any = {
  GSM_NUMBER: 'Gsm number confirmation',
  ID_RECTO: 'Id Card Recto',
  ID_VERSO: 'Id Card Verso',
  KYC_PHOTO: 'KYC Photo',
  ID_CARD: 'Id Card Data',
  PIN_CODE_SETUP: 'Pin code setup',
  PASS_PHRASE_SETUP: 'Pass phrase setup',
  ID_CARD_DRC: 'Id card data',
  CD_SECRET_QUESTIONS: 'Secret questions',
  ID_CARD_IQ: 'Id card data',
  FIN_SETUP_DRC: 'Matricule number',
  USER_DATA_2_IQ: 'Stage 2 user details',
  IQ_AGENT_APPROVAL: 'Stage 3 Agent approval',
  ID_CARD_FRONT: 'Id card front',
  ID_CARD_BACK: 'Id card back',
  RESIDENCE_CARD_FRONT: 'Residence card front',
  RESIDENCE_CARD_BACK: 'Residence card back',
  IQ_ADDITIONAL_DOCUMENT_FRONT: 'Additional document front',
  IQ_ADDITIONAL_DOCUMENT_BACK: 'Additional document back',
  USER_DATA_BRAZIL: 'User data Brazil',
  USER_DATA_CONGO: 'User data Congo',
  ID_CARD_CI: 'CI user data',
};

export const KYC_STEP_STATUS: any = {
  SELF_APPROVED: 'User self approved',
  AGENT_PROCESSED: 'Agent processed',
  ADMIN_APPROVED: 'Admin approved',
  ADMIN_REJECTED: 'Admin rejected',
  SELF_PROCESSED: 'User processed',
};

export const ADMIN_APPROVED_STATUS = 'ADMIN_APPROVED';
export const ADMIN_REJECTED_STATUS = 'ADMIN_REJECTED';
