import {createAction, createApiAction} from '../../store/helpers';
import * as types from './ActionTypes';

export const getAgentSearchResult = createApiAction(
  types.GET_AGENT_SEARCH_RESULT,
);
export const rollbackTransaction = createApiAction(types.ROLLBACK_TRANSACTION);
export const setPassword = createApiAction(types.SET_PASSWORD);
export const setPin = createApiAction(types.SET_PIN);
export const cashIn = createApiAction(types.CASH_IN);
export const cashOut = createApiAction(types.CASH_OUT);
export const getPoolAccounts = createApiAction(types.GET_POOL_ACCOUNTS);
export const getLocalities = createApiAction(types.GET_LOCALITIES);
export const getGroups = createApiAction(types.GET_GROUPS);
export const getAgent = createApiAction(types.GET_AGENT);
export const disableAgent = createApiAction(types.DISABLE_AGENT);
export const updateAgent = createApiAction(types.UPDATE_AGENT);
export const getKycTable = createApiAction(types.GET_KYC_TABLE);
export const getActionLogs = createApiAction(types.GET_AGENT_ACTION_LOGS);
export const getAccounts = createApiAction(types.GET_ACCOUNTS);
export const getAccountsDetails = createApiAction(types.GET_ACCOUNTS_DETAILS);
export const resetAccounts = createAction(types.RESET_ACCOUNTS);
export const resetKycTable = createAction(types.RESET_KYC_TABLE);
export const resetActionLogs = createAction(types.RESET_ACTION_LOGS);

export const resetAgentSearchResult = createAction(
  types.RESET_AGENT_SEARCH_RESULT,
);
export const resetAgent = createAction(types.RESET_AGENT);
export const resetAccountsDetails = createAction(types.RESET_ACCOUNTS_DETAILS);
