import {IGroupList} from '../../models';
import injectReducer from '../../store/injectReducer';
import * as types from './ActionTypes';
import {IMessagesReducer} from './IMessagesReducer';
import {IMessages, IMessagesDetails} from './models';

export const InitialMessagesReducer: IMessagesReducer = {
  isLoading: false,
  error: '',
  isSent: false,
  isDrafted: false,
  groups: [],
  messages: {
    content: [],
    totalElements: 0,
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {},
    totalPages: 0,
  },
  messageDetails: {
    created: '',
    creator: '',
    draft: undefined,
    id: null,
    message: '',
    groups: [],
    recipientUsers: [],
    sender: '',
    sent: '',
    subject: '',
  },
};

const requestFunctionHandler = (state: IMessagesReducer) => ({
  ...state,
  isLoading: true,
});

const failureFunctionHandler = (state: IMessagesReducer, { payload }: any) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default injectReducer(InitialMessagesReducer, {
  [types.GET_MESSAGES.REQUEST]: requestFunctionHandler,
  [types.SEND_MESSAGE.REQUEST]: requestFunctionHandler,
  [types.SAVE_MESSAGE.REQUEST]: requestFunctionHandler,
  [types.GET_MESSAGE_DETAILS.REQUEST]: requestFunctionHandler,

  [types.GET_MESSAGES.FAILURE]: failureFunctionHandler,
  [types.SEND_MESSAGE.FAILURE]: failureFunctionHandler,
  [types.SAVE_MESSAGE.FAILURE]: failureFunctionHandler,
  [types.GET_MESSAGE_DETAILS.FAILURE]: failureFunctionHandler,

  [types.GET_MESSAGES.SUCCESS]: (
    state: IMessagesReducer,
    { payload }: { payload: IMessages },
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    messages: payload,
    isSent: false,
    isDrafted: false,
  }),
  [types.GET_MESSAGE_GROUPS.SUCCESS]: (
    state: IMessagesReducer,
    { payload }: { payload: IGroupList },
  ) => ({
    ...state,
    groups: payload,
  }),
  [types.GET_MESSAGE_DETAILS.SUCCESS]: (
    state: IMessagesReducer,
    { payload }: { payload: IMessagesDetails },
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    messageDetails: payload,
  }),
  [types.RESET_MESSAGE_DETAILS]: (state: IMessagesReducer) => ({
    ...state,
    error: '',
    isLoading: false,
    messageDetails: InitialMessagesReducer.messageDetails,
  }),
  [types.SAVE_MESSAGE.SUCCESS]: (state: IMessagesReducer) => ({
    ...state,
    error: '',
    isLoading: false,
    isDrafted: true,
  }),
  [types.SEND_MESSAGE.SUCCESS]: (state: IMessagesReducer) => ({
    ...state,
    error: '',
    isLoading: false,
    isSent: true,
  }),
});
