import Block from '@material-ui/icons/Block';
import Check from '@material-ui/icons/Check';
import classNames from 'classnames';
import * as React from 'react';

import './toggle-styles.scss';

interface IToggleProps {
  active: boolean;
  disable?: boolean;
  withoutLabel?: boolean;
  className?: string;
  name?: string;
  position?: string;
  label?: string | React.ReactElement;
  onClick?: (name?: string) => void;
}

class ToggleTemp extends React.Component<IToggleProps> {
  public parent: React.ReactNode;
  public on: React.ReactNode;
  public off: React.ReactNode;

  public onClick = () => {
    if (!this.props.disable && this.props.onClick) {
      this.props.onClick(this.props.name);
    }
  };

  public render() {
    const {
      active,
      disable,
      withoutLabel,
      label,
      className,
      position,
    } = this.props;
    const defaultPosition = position || 'left';

    return (
      <div className={classNames('flex-row toggle-switch', className)}>
        {!withoutLabel && (
          <div className="label">{active ? 'Approve' : 'Reject'}</div>
        )}
        {label && defaultPosition === 'left' ? (
          <div className="label">{label}</div>
        ) : null}
        <div
          role="button"
          className={classNames('toggle-btn toggle', {
            [`off reject`]: !active,
            approve: active,
          })}
          ref={c => {
            this.parent = c;
          }}
        >
          <div className="toggle-group">
            <span
              style={{ display: this.props.active ? 'flex' : 'none' }}
              ref={onLabel => {
                this.on = onLabel;
              }}
              className="toggle-btn toggle-on approve"
            >
              <Check fontSize="small" />
            </span>
            <span
              style={{ display: !this.props.active ? 'flex' : 'none' }}
              ref={offLabel => {
                this.off = offLabel;
              }}
              className="toggle-btn toggle-off reject"
            >
              <Block fontSize="small" />
            </span>
            <span
              onClick={this.onClick}
              className={classNames('toggle-btn toggle-handle', {
                disabled: disable,
              })}
            />
          </div>
        </div>
        {label && defaultPosition === 'right' ? (
          <div className="label">{label}</div>
        ) : null}
      </div>
    );
  }
}

export const Toggle = ToggleTemp;
