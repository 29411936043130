import * as React from 'react';

export const SearchAgentIcon: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#006CAF"
      fillRule="evenodd"
      d="M11.015 5a6.01 6.01 0 0 1 6.016 6.004 5.97 5.97 0 0 1-1.244 3.657l3.002 3.067a.75.75 0 0 1-.019 1.061.753.753 0 0 1-1.063-.018l-2.978-3.043a5.997 5.997 0 0 1-3.714 1.28A6.01 6.01 0 0 1 5 11.004 6.01 6.01 0 0 1 11.015 5zm0 1.501a4.507 4.507 0 0 0-4.511 4.503 4.507 4.507 0 0 0 4.511 4.503 4.507 4.507 0 0 0 4.512-4.503 4.507 4.507 0 0 0-4.512-4.503z"
    />
  </svg>
);
