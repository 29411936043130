import injectReducer from '../../store/injectReducer';
import * as types from './ActionTypes';
import {IReportsReducer,} from './IReportsReducer';

export const InitialReportsReducer: IReportsReducer = {
  accounts: {},
  users: {},
  balance: {
    generated: '',
    currencyBreakdown: {
      floatAccountTotals: 0,
      poolAccountTotals: 0,
      feesAccounts: 0,
      userAccounts: 0,
      agentAccounts: 0,
      pendingPayments: 0,
      companyAccounts: 0,
      tokenAccounts: 0,
      delta: 0,
      paymentMean: {
        id: 0,
        isoCode: '',
        internalCode: '',
        spreadPip: 0,
        type: '',
        isPrimary: false,
        fractionalDigits: 0,
        enabled: false,
        displayName: '',
        calculatedIsoCode: '',
        rolledBacked: '',
        identificator: 0,
        name: '',
      },
      poolAccounts: [],
    },
  },
  error: '',
  isLoading: false,
};

const requestFunctionHandler = (state: IReportsReducer) => ({
  ...state,
  isLoading: true,
});

const failureFunctionHandler = (state: IReportsReducer, { payload }: any) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default injectReducer(InitialReportsReducer, {
  [types.GET_REPORT_ACCOUNTS.REQUEST]: requestFunctionHandler,
  [types.GET_USERS.REQUEST]: requestFunctionHandler,
  [types.GET_REPORT_BALANCE.REQUEST]: requestFunctionHandler,

  [types.GET_REPORT_ACCOUNTS.FAILURE]: failureFunctionHandler,
  [types.GET_USERS.FAILURE]: failureFunctionHandler,
  [types.GET_REPORT_BALANCE.FAILURE]: failureFunctionHandler,

  [types.GET_REPORT_ACCOUNTS.SUCCESS]: (
    state: IReportsReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    accounts: payload,
  }),
  [types.GET_USERS.SUCCESS]: (state: IReportsReducer, { payload }: any) => ({
    ...state,
    error: '',
    isLoading: false,
    users: payload,
  }),
  [types.GET_REPORT_BALANCE.SUCCESS]: (
    state: IReportsReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: '',
    isLoading: false,
    balance: payload,
  }),
  [types.RESET_USERS]: (state: IReportsReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    users: InitialReportsReducer.users,
  }),
  [types.RESET_REPORT_ACCOUNTS]: (state: IReportsReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    accountsDetails: InitialReportsReducer.accounts,
  }),
  [types.RESET_REPORT_BALANCE]: (state: IReportsReducer, action: any) => ({
    ...state,
    error: '',
    isLoading: false,
    balance: InitialReportsReducer.balance,
  }),
});
