import * as React from 'react';

export const RejectedIcon: React.SFC = () => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="reject_icn-"
        transform="translate(-5.000000, -5.000000)"
        fill="#f4453b"
      >
        <path
          d="M16.383064,8.67704109 L8.67032002,16.3779525 C9.59457793,17.081983 10.7484724,17.5 12,17.5 C15.0375661,17.5 17.5,15.0375661 17.5,12 C17.5,10.7515042 17.0840058,9.60016911 16.383064,8.67704109 Z M15.3223274,7.61645659 C14.3993058,6.91580448 13.2482111,6.5 12,6.5 C8.96243388,6.5 6.5,8.96243388 6.5,12 C6.5,13.2451794 6.9137871,14.3937136 7.61135635,15.3155978 L15.3223274,7.61645659 Z M12,19 C8.13400675,19 5,15.8659932 5,12 C5,8.13400675 8.13400675,5 12,5 C15.8659932,5 19,8.13400675 19,12 C19,15.8659932 15.8659932,19 12,19 Z"
          id="reject_icn"
        />
      </g>
    </g>
  </svg>
);
