//import * as Sentry from '@sentry/react';
import JSEncrypt from 'jsencrypt';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {addNotification} from '../../components/notifications/notifications.actions';
import {request} from '../../utils';
import * as types from './ActionTypes';
import {
  cashIn,
  cashOut,
  disableAgent,
  getAccounts,
  getAccountsDetails,
  getActionLogs,
  getAgent,
  getAgentSearchResult,
  getGroups,
  getKycTable,
  getLocalities,
  getPoolAccounts,
  rollbackTransaction,
  setPassword,
  setPin,
  updateAgent,
} from './agent.actions';

const KEY_IDENTIFIER = 'admin_asmCrypto256_credentials';
const KEY =
  'f56063ec379d4e646a3d4ea1bcf4cc4785a1a91e197ef5029398013920498791b30143ee2d8eb8adf9c6274ed1b4065d0630f9d4ec05018bd1a78df15276654d8c0eb33f9de97f6a354fca40a9cd23da6d4f202a31e207455c5fb46cb9d7646368999304313d4295548e57a87fd2ec5dc03cea8fa25e0f84237c90f4e26e4850640aee22b38098abb48f85fde358cb71064286085f74548445779c55c6ffbafe1a45dc360af8c42b3df0eb92445d5be8000ac47dfcbf504f92a6dfb3c17fd28a0f4446f20d587ca8deadc9c12563d7e85b493dbd31377be8c9acfd8e333ecf7da220d9bc8509a0000aaed18b24add75d1c912d1e989af0a797ba279f28cf313d';

function* getAgentSearchSaga({ payload }: { payload: any }): any {
  try {
    yield put(getAgentSearchResult.loading({} as any));

    const searchResultParams = {
      method: 'GET',
      url: 'agent/search',
      params: {
        q: payload.search,
      },
    };
    const data: any = (yield call(request, searchResultParams)).data;
    yield put(getAgentSearchResult.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load search result', error); // tslint:disable-line
    yield put(getAgentSearchResult.fail(error.toString())); // tslint:disable-line
  }
}

function* disableAgentSaga({ payload }: { payload: any }): any {
  try {
    yield put(disableAgent.loading({} as any));

    const disableAgentParams = {
      method: 'PUT',
      url: `agent/${payload.id}/${payload.isBlocked ? 'unblock' : 'block'}`,
    };
    const data: any = (yield call(request, disableAgentParams)).data;
    yield put(disableAgent.success(data));
    yield call(getAgentSaga, { payload: { id: payload.id } } as any);
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to disable agent', error); // tslint:disable-line
    yield put(disableAgent.fail(error.toString())); // tslint:disable-line
  }
}

function* getPoolAccountsSaga({ payload }: { payload: any }): any {
  try {
    yield put(getPoolAccounts.loading({} as any));

    const poolAccountsParams = {
      method: 'GET',
      url: `agent/pool-accounts`,
    };
    const data: any = (yield call(request, poolAccountsParams)).data;
    yield put(getPoolAccounts.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load search result', error); // tslint:disable-line
    yield put(getPoolAccounts.fail(error.toString())); // tslint:disable-line
  }
}

function* getLocalitiesSaga({ payload }: { payload: any }): any {
  try {
    const { countryIsoCode } = payload;
    yield put(getAgent.loading({} as any));

    const localitiesParams = {
      method: 'GET',
      url: `agent/localities?countryIsoCode=${countryIsoCode}&lang=en`,
    };

    const data: any = (yield call(request, localitiesParams)).data;
    yield put(getLocalities.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load user', error); // tslint:disable-line
    yield put(getLocalities.fail(error.toString()));
  }
}

function* getGroupsSaga({ payload }: { payload: any }): any {
  try {
    yield put(getGroups.loading({} as any));

    const localitiesParams = {
      method: 'GET',
      url: 'agent/group',
    };

    const data: any = (yield call(request, localitiesParams)).data;
    yield put(getGroups.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load group', error); // tslint:disable-line
    yield put(getGroups.fail(error.toString()));
  }
}

// @ts-ignore
function* getAgentSaga({ payload }: { payload: any }): any {
  try {
    yield put(getAgent.loading({} as any));

    const agentParams = {
      method: 'GET',
      url: `agent/${payload.id}/details`,
    };

    const agentPhotosParams = {
      method: 'GET',
      url: `agent/${payload.id}/photos`,
    };

    const agentAvatarParams = {
      method: 'GET',
      url: `agent/${payload.id}/avatar`,
    };

    const [agent, photos, avatar] = yield all([
      call(request, agentParams),
      call(request, agentPhotosParams),
      call(request, agentAvatarParams),
    ]);

    yield put(
      getAgent.success({
        agent: agent.data,
        photos: photos.data,
        avatar: avatar.data,
      }),
    );
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load user', error); // tslint:disable-line
    yield put(getAgent.fail(error.toString()));
  }
}

function* updateAgentSaga({ payload }: { payload: any }): any {
  try {
    yield put(updateAgent.loading({} as any));

    const agentEditParams = {
      method: 'PUT',
      url: 'agent/edit',
      data: payload,
    };

    const data: any = (yield call(request, agentEditParams)).data;
    yield put(updateAgent.success({}));
    yield getAgentSaga({ payload: { id: payload.id } });
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load user', error); // tslint:disable-line
    yield put(updateAgent.fail(error.toString()));
  }
}

function* getKycTableSaga({ payload }: { payload: any }): any {
  try {
    yield put(getKycTable.loading({} as any));

    const kycTableParams = {
      method: 'GET',
      params: {
        page: payload.page,
        size: payload.rowsPerPage,
        ...payload.filter,
      },
      url: `agent/${payload.id}/kyc-process`,
    };
    const data: any = (yield call(request, kycTableParams)).data;
    yield put(getKycTable.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load kyc', error); // tslint:disable-line
    yield put(getKycTable.fail(error.toString()));
  }
}

function* getAccountsSaga({ payload }: { payload: any }): any {
  try {
    yield put(getAccounts.loading({} as any));
    const transactionHistoryParams = {
      method: 'GET',
      params: {
        page: payload.page,
        size: payload.rowsPerPage,
        ...payload.filter,
      },
      url: `agent/${payload.id}/transactions`,
    };
    const data: any = (yield call(request, transactionHistoryParams)).data;
    yield put(getAccounts.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load transaction history', error); // tslint:disable-line
    yield put(getAccounts.fail(error.toString())); // tslint:disable-line
  }
}

function* getAccountsDetailsSaga({ payload }: { payload: any }): any {
  try {
    yield put(getAccountsDetails.loading({} as any));

    const transactionDetailsParams = {
      method: 'GET',
      params: {
        transactionId: payload.transactionId,
      },
      url: 'agent/transaction',
    };
    const data: any = (yield call(request, transactionDetailsParams)).data;
    yield put(getAccountsDetails.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load transaction history', error); // tslint:disable-line
    yield put(getAccountsDetails.fail(error.toString())); // tslint:disable-line
  }
}

function* getActionLogsSaga({ payload }: { payload: any }): any {
  try {
    yield put(getActionLogs.loading({} as any));

    const actionLogsParams = {
      method: 'GET',
      params: {
        page: payload.page,
        size: payload.rowsPerPage,
        ...payload.filter,
      },
      url: `agent/${payload.id}/actionLogs`,
    };
    const data: any = (yield call(request, actionLogsParams)).data;
    yield put(getActionLogs.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load action logs', error); // tslint:disable-line
    yield put(getActionLogs.fail(error.toString()));
  }
}

function* rollbackTransactionSaga({ payload }: { payload: any }): any {
  try {
    yield put(rollbackTransaction.loading({} as any));

    const encrypt = new JSEncrypt();
    const publicKeyParams = {
      method: 'GET',
      url: `profile/challenge`,
    };
    const publicKey: any = (yield call(request, publicKeyParams)).data;
    encrypt.setPublicKey(publicKey);
    const hash = encrypt.encrypt(payload.password);

    const rollbackParams = {
      method: 'PUT',
      data: {
        transactionId: payload.transactionId,
        communication: payload.communication || null,
        signedChallenge: {
          code: payload.code,
          signature: hash,
        },
      },
      url: `agent/rollback-transaction`,
    };
    const data: any = (yield call(request, rollbackParams)).data;
    yield put(rollbackTransaction.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load action logs', error); // tslint:disable-line
    yield put(rollbackTransaction.fail(error.toString()));
  }
}

function* setPinSaga({ payload }: { payload: any }): any {
  try {
    yield put(setPin.loading({} as any));

    const asmCryptoKey = [
      window.asmCrypto.hex_to_bytes(KEY),
      window.asmCrypto.hex_to_bytes('10001'),
    ];

    const rsaOaepEnc = new window.asmCrypto.RSA_OAEP(
      asmCryptoKey,
      new window.asmCrypto.Sha1(),
    );
    const enc = rsaOaepEnc.encrypt(
      window.asmCrypto.string_to_bytes(payload.pinCode),
    );
    const encryptedContent = window.asmCrypto.bytes_to_hex(enc);

    const setPinParams = {
      method: 'POST',
      data: {
        id: payload.id,
        sealedObjectDto: {
          encryptedContent,
          keyIdentifier: KEY_IDENTIFIER,
        },
      },
      url: 'agent/set-pin',
    };
    const data: any = (yield call(request, setPinParams)).data;
    yield put(setPin.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load action logs', error); // tslint:disable-line
    yield put(setPin.fail(error.toString()));
  }
}

function* cashInSaga({ payload }: { payload: any }): any {
  try {
    yield put(cashIn.loading({} as any));

    const encrypt = new JSEncrypt();
    const publicKeyParams = {
      method: 'GET',
      url: `profile/challenge`,
    };
    const publicKey: any = (yield call(request, publicKeyParams)).data;
    encrypt.setPublicKey(publicKey);
    const hash = encrypt.encrypt(payload.password);

    const cashInParams = {
      method: 'PUT',
      data: {
        signedChallenge: {
          code: payload.mfaCode,
          signature: hash,
        },
        cashDto: {
          agentId: payload.id,
          amount: payload.amount,
          poolAccountId: payload.poolAccountId,
        },
      },
      url: 'agent/execute-cashin',
    };
    const data: any = (yield call(request, cashInParams)).data;
    yield put(cashIn.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load action logs', error); // tslint:disable-line
    yield put(cashIn.fail(error.toString()));
  }
}

function* cashOutSaga({ payload }: { payload: any }): any {
  try {
    yield put(cashOut.loading({} as any));

    const encrypt = new JSEncrypt();
    const publicKeyParams = {
      method: 'GET',
      url: `profile/challenge`,
    };
    const publicKey: any = (yield call(request, publicKeyParams)).data;
    encrypt.setPublicKey(publicKey);
    const hash = encrypt.encrypt(payload.password);

    const cashOutParams = {
      method: 'PUT',
      data: {
        signedChallenge: {
          code: payload.mfaCode,
          signature: hash,
        },
        cashDto: {
          agentId: payload.id,
          amount: payload.amount,
          poolAccountId: payload.poolAccountId,
          originAccountId: payload.originAccountId,
        },
      },
      url: 'agent/execute-cashout',
    };
    const data: any = (yield call(request, cashOutParams)).data;
    yield put(cashOut.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load action logs', error); // tslint:disable-line
    yield put(cashOut.fail(error.toString()));
  }
}

function* setPasswordSaga({ payload }: { payload: any }): any {
  try {
    yield put(setPassword.loading({} as any));

    const asmCryptoKey = [
      window.asmCrypto.hex_to_bytes(KEY),
      window.asmCrypto.hex_to_bytes('10001'),
    ];

    const rsaOaepEnc = new window.asmCrypto.RSA_OAEP(
      asmCryptoKey,
      new window.asmCrypto.Sha1(),
      window.asmCrypto.string_to_bytes(''),
    );
    const enc = rsaOaepEnc.encrypt(
      window.asmCrypto.string_to_bytes(payload.password),
    );
    const encryptedContent = window.asmCrypto.bytes_to_hex(enc);
    const setPasswordParams = {
      method: 'POST',
      data: {
        id: payload.id,
        sealedObjectDto: {
          encryptedContent,
          keyIdentifier: KEY_IDENTIFIER,
        },
      },
      url: 'agent/set-password',
    };
    const data: any = (yield call(request, setPasswordParams)).data;
    yield put(setPassword.success(data));
  } catch (error: any) {
    //Sentry.captureException(error);
    console.log('> Failed to load action logs', error); // tslint:disable-line
    yield put(
      addNotification({
        message: error.message || 'Something was wrong',
        type: 'error',
      }),
    );
    yield put(setPassword.fail(error.toString()));
  }
}

// Saga watchers / listeners
export function* agentSaga() {
  yield all([
    // @ts-ignore
    takeEvery(types.GET_AGENT_SEARCH_RESULT.DEFAULT, getAgentSearchSaga),
    // @ts-ignore
    takeEvery(types.GET_AGENT.DEFAULT, getAgentSaga),
    // @ts-ignore
    takeEvery(types.GET_LOCALITIES.DEFAULT, getLocalitiesSaga),
    // @ts-ignore
    takeEvery(types.GET_GROUPS.DEFAULT, getGroupsSaga),
    // @ts-ignore
    takeEvery(types.DISABLE_AGENT.DEFAULT, disableAgentSaga),
    // @ts-ignore
    takeEvery(types.GET_POOL_ACCOUNTS.DEFAULT, getPoolAccountsSaga),
    // @ts-ignore
    takeEvery(types.UPDATE_AGENT.DEFAULT, updateAgentSaga),
    // @ts-ignore
    takeEvery(types.GET_KYC_TABLE.DEFAULT, getKycTableSaga),
    // @ts-ignore
    takeEvery(types.GET_AGENT_ACTION_LOGS.DEFAULT, getActionLogsSaga),
    // @ts-ignore
    takeEvery(types.GET_ACCOUNTS.DEFAULT, getAccountsSaga),
    // @ts-ignore
    takeEvery(types.GET_ACCOUNTS_DETAILS.DEFAULT, getAccountsDetailsSaga),
    // @ts-ignore
    takeEvery(types.ROLLBACK_TRANSACTION.DEFAULT, rollbackTransactionSaga),
    // @ts-ignore
    takeEvery(types.SET_PIN.DEFAULT, setPinSaga),
    // @ts-ignore
    takeEvery(types.SET_PASSWORD.DEFAULT, setPasswordSaga),
    // @ts-ignore
    takeEvery(types.CASH_IN.DEFAULT, cashInSaga),
    // @ts-ignore
    takeEvery(types.CASH_OUT.DEFAULT, cashOutSaga),
  ]);
}
