import * as React from 'react';

export const BurgerIconOpen: React.SFC = () => (
  <svg
    width="25px"
    height="21px"
    viewBox="0 0 25 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="minimize_menu_icn"
        transform="translate(-6.000000, -8.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <path
          d="M6.75,10.5 C6.33578644,10.5 6,10.1642136 6,9.75 C6,9.33578644 6.33578644,9 6.75,9 L20.5833333,9 C20.9975469,9 21.3333333,9.33578644 21.3333333,9.75 C21.3333333,10.1642136 20.9975469,10.5 20.5833333,10.5 L6.75,10.5 Z M6.75,16.25 C6.33578644,16.25 6,15.9142136 6,15.5 C6,15.0857864 6.33578644,14.75 6.75,14.75 L20.5833333,14.75 C20.9975469,14.75 21.3333333,15.0857864 21.3333333,15.5 C21.3333333,15.9142136 20.9975469,16.25 20.5833333,16.25 L6.75,16.25 Z M6.75,22.25 C6.33578644,22.25 6,21.9142136 6,21.5 C6,21.0857864 6.33578644,20.75 6.75,20.75 L29.5409032,20.75 C29.9551168,20.75 30.2909032,21.0857864 30.2909032,21.5 C30.2909032,21.9142136 29.9551168,22.25 29.5409032,22.25 L6.75,22.25 Z M6.75,28.25 C6.33578644,28.25 6,27.9142136 6,27.5 C6,27.0857864 6.33578644,26.75 6.75,26.75 L29.5409032,26.75 C29.9551168,26.75 30.2909032,27.0857864 30.2909032,27.5 C30.2909032,27.9142136 29.9551168,28.25 29.5409032,28.25 L6.75,28.25 Z M29.8247372,9.44128293 L26.0977895,13 L29.8247372,16.5587171 C30.1543925,16.8734921 30.1710949,17.400855 29.862043,17.736615 C29.5529911,18.0723751 29.0352166,18.0893868 28.7055613,17.7746117 L24.3419268,13.6079473 C23.9971355,13.2787195 23.9971355,12.7212805 24.3419268,12.3920527 L28.7055613,8.22538825 C29.0352166,7.91061321 29.5529911,7.92762492 29.862043,8.26338496 C30.1710949,8.59914501 30.1543925,9.12650789 29.8247372,9.44128293 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);
