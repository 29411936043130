import {createApiAction} from '../../store/helpers';
import * as types from './ActionTypes';

export interface ILoginCredentials {
  headers: {
    authorization: string;
    [index: string]: string;
  };
  data: any;
}

export interface ILoginPayload {
  username: string;
  password: string;
  mfa: string;
  [index: string]: string;
}

export const signIn = createApiAction(types.SIGN_IN);
export const signOut = createApiAction(types.SIGN_OUT);
