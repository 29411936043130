import * as React from 'react';

export const CountriesIcon: React.SFC = () => (
  <svg width="36" height="36" viewBox="0 0 36 36">
    <path
      fill="#87C7F0"
      fillRule="evenodd"
      d="M5.524 4a.75.75 0 0 1 .75.75v.616a12.041 12.041 0 0 1 3.816-.608c.858 0 1.679.127 3.054.423l.32.07c1.596.346 2.255.457 3.003.457 1.124 0 1.993-.207 2.62-.604a.75.75 0 0 1 1.15.634v7.948a.75.75 0 0 1-.294.596c-.908.695-2.068 1.036-3.454 1.036-.906 0-1.633-.136-3.352-.55l-.28-.068c-1.307-.313-2.046-.441-2.767-.441a9.475 9.475 0 0 0-3.816.788v4.203a.75.75 0 1 1-1.5 0V4.75a.75.75 0 0 1 .75-.75zm4.566 2.258c-1.335 0-2.606.234-3.816.701v6.473a10.981 10.981 0 0 1 3.816-.673c.874 0 1.699.143 3.117.482l.282.068c1.596.386 2.257.509 3 .509.915 0 1.66-.18 2.249-.529V6.898c-.665.208-1.422.31-2.27.31-.896 0-1.615-.12-3.323-.492l-.317-.069c-1.276-.274-2.017-.389-2.738-.389z"
    />
  </svg>
);
