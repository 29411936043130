import React from 'react';

const Segment = ({
  stroke,
  strokeDasharray,
  strokeDashoffset,
  strokeWidth,
  title,
}: any) => (
  <circle
    cx={center.x}
    cy={center.y}
    r={radius}
    fill="none"
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeDasharray={strokeDasharray}
    strokeDashoffset={strokeDashoffset}
  >
    {title && <title>{title}</title>}
  </circle>
);

export const radius = 100 / (2 * Math.PI);
export const width = 52;
export const center = {
  x: width / 2,
  y: width / 2,
};

export const ReactDonutChart = ({ data, spacing, ...rest }: any) => {
  const total = data.reduce(
    (prev: number, current: any) => current.value + prev,
    0,
  );

  let percentAcc = 0;

  return (
    <svg viewBox={`0 0 ${width} ${width}`} {...rest}>
      {data.map((d: any, i: number) => {
        const percentElem = Math.round((d.value / Math.ceil(total)) * 100);
        const percent = percentElem === 0 && d.value > 0 ? 1 : percentElem;

        const DashArrayPercent =
          spacing < 0 || percent - spacing < 0 ? percent : percent - spacing;
        const DashArraylength =
          spacing < 0 || percent + spacing > 100
            ? 100 - percent
            : 100 - percent + spacing;
        const strokeDasharray = `${DashArrayPercent} ${DashArraylength}`;
        const strokeDashoffset = i === 0 ? 0 : 100 - percentAcc;
        percentAcc += percent;

        return (
          percent > 0 && (
            <Segment
              key={i}
              strokeDasharray={strokeDasharray}
              strokeDashoffset={strokeDashoffset}
              {...d}
            />
          )
        );
      })}
    </svg>
  );
};
