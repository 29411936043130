import * as React from 'react';

export const GroupIcon: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <title>Group</title>
    <path
      fill="#006CAF"
      fillRule="evenodd"
      d="M12 3a3 3 0 0 1 1.61 5.532c.9.322 1.684.912 2.247 1.694a3 3 0 0 1 2.754 5.305A4.748 4.748 0 0 1 21.75 20a.75.75 0 1 1-1.5 0 3.25 3.25 0 0 0-6.5 0 .75.75 0 1 1-1.5 0 4.752 4.752 0 0 1 3.14-4.47A2.994 2.994 0 0 1 14 13c0-.708.245-1.36.656-1.872A3.244 3.244 0 0 0 12 9.75a3.245 3.245 0 0 0-2.695 1.432l.04-.053a2.998 2.998 0 0 1-.735 4.403A4.748 4.748 0 0 1 11.75 20a.75.75 0 1 1-1.5 0 3.25 3.25 0 0 0-6.5 0 .75.75 0 1 1-1.5 0 4.752 4.752 0 0 1 3.14-4.47 3 3 0 1 1 2.756-5.304 4.742 4.742 0 0 1 2.243-1.695A3 3 0 0 1 12 3zm-5 8.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm10 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-5-7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
    />
  </svg>
);
