import * as React from 'react';

export const ProcessingIcon: React.SFC = () => (
  <svg
    width="12px"
    height="14px"
    viewBox="0 0 12 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="user_icn"
        transform="translate(-6.000000, -5.000000)"
        fill="#333333"
      >
        <path
          d="M10.513729,12.4439329 C9.1819721,11.8684206 8.25,10.5430472 8.25,9 C8.25,6.92893219 9.92893219,5.25 12,5.25 C14.0710678,5.25 15.75,6.92893219 15.75,9 C15.75,10.5430472 14.8180279,11.8684206 13.486271,12.4439329 C15.9415012,13.0990461 17.75,15.3383243 17.75,18 C17.75,18.4142136 17.4142136,18.75 17,18.75 C16.5857864,18.75 16.25,18.4142136 16.25,18 C16.25,15.6527898 14.3472102,13.75 12,13.75 C9.65278981,13.75 7.75,15.6527898 7.75,18 C7.75,18.4142136 7.41421356,18.75 7,18.75 C6.58578644,18.75 6.25,18.4142136 6.25,18 C6.25,15.3383243 8.05849882,13.0990461 10.513729,12.4439329 Z M12,11.25 C13.2426407,11.25 14.25,10.2426407 14.25,9 C14.25,7.75735931 13.2426407,6.75 12,6.75 C10.7573593,6.75 9.75,7.75735931 9.75,9 C9.75,10.2426407 10.7573593,11.25 12,11.25 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);
