import * as React from 'react';

type ErrorsState = string[];

export interface IUsedField {
  name: string;
  formPrefix?: string;
  value: any;
  errors: string[];
  setErrors: (errors: string[]) => void;
  pristine: boolean;
  setDefaultValue: () => void;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
  ) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  validate: (onSubmitValidation?: boolean) => Promise<boolean> | boolean;
  onRevert: () => void;
  deletedElem: (val: string) => void;
}

const requiredRule = (formData: any, name: string): string[] => {
  if (
    typeof formData === 'undefined' ||
    typeof formData[name] === 'undefined' ||
    !formData[name].length
  ) {
    return [`Field ${name} is required`];
  }
  return [];
};

export const useUnmounted = () => {
  const unmounted = React.useRef(false);
  React.useEffect(
    () => () => {
      unmounted.current = true;
    },
    [],
  );
  return unmounted;
};

export const useField = (
  name: string,
  form: IUsedForm,
  {
    defaultValue,
    onlyNumbers = false,
    onlyCharacters = false,
    maxLength,
    validations = [],
    isRequired,
    fieldsToValidateOnChange = [name],
    validateOnMount,
    onChangeCallback,
    formPrefix,
  }: any = {},
): IUsedField => {
  const [value, setValue] = React.useState(defaultValue);
  const [errors, setErrors] = React.useState<ErrorsState>([]);
  const [pristine, setPristine] = React.useState(true);
  const validateCounter = React.useRef(0);
  const unmounted = useUnmounted();

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (maxLength && value.length >= maxLength) {
      return;
    }
    if (pristine) {
      setPristine(false);
    }
    if (onChangeCallback) {
      onChangeCallback(e.target.value);
    }
    if (onlyNumbers) {
      setValue(e.target.value.replace(/[^0-9]/g, ''));
    } else if (onlyCharacters) {
      setValue(e.target.value.replace(/[^a-zA-Z_]/g, ''));
    } else {
      setValue(e.target.value);
    }
  };

  const validate = async (onSubmitValidation?: boolean) => {
    const validateIteration = ++validateCounter.current;
    const formData = form.getFormData();

    let errorMessages = await Promise.all<string>(
      validations.map((validation: (formData: any, name: string) => string) =>
        validation(formData, name),
      ),
    );
    errorMessages = errorMessages.filter(errorMsg => !!errorMsg);
    if (validateIteration === validateCounter.current) {
      let requiredResult: string[] = [];
      if (isRequired) {
        requiredResult = requiredRule(formData, name);
      }
      errorMessages = [...errorMessages, ...requiredResult];
      // this is the most recent invocation
      if (!unmounted.current) {
        setErrors(errorMessages);
      }
    }
    if (errorMessages.length && !unmounted.current && onSubmitValidation) {
      setPristine(false);
    }
    return errorMessages.length === 0;
  };
  const field = {
    name,
    value,
    errors,
    formPrefix,
    setErrors,
    pristine,
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
      if (pristine) {
        setPristine(false);
      }
    },
    setDefaultValue: () => {
      setValue(defaultValue || '');
    },
    onRevert: () => {
      setPristine(true);
      setValue(defaultValue || '');
      setErrors([]);
    },
    deletedElem: (val: string) => {
      setValue(value.filter((elem: string) => elem !== val));
    },
    onChange,
    validate,
  };

  React.useEffect(() => {
    if (pristine && !validateOnMount) {
      return;
    } // Avoid validate on mount
    form.validateFields(fieldsToValidateOnChange, false); // Validate fields when value changes
  }, [value]);

  // Register field with the form
  form.addField(field);
  return field;
};

export interface IUseFormProps {
  onSubmit: (formData: any, formValid: boolean) => void;
}

export interface IUsedForm {
  onSubmit: (e: React.FormEvent) => void;
  isValid: () => boolean;
  addField: (field: IUsedField) => void;
  getField: (name: string) => IUsedField | undefined;
  getFormData: () => { [index: string]: string };
  getErrors: () => string[][];
  validateFields: (
    fieldNames: IUsedField[],
    onSubmitValidation: boolean,
  ) => void;
  reset: () => void;
  onRevert: (cb?: () => void) => void;
}

export const useForm = ({ onSubmit }: IUseFormProps): IUsedForm => {
  let fields: IUsedField[] = [];

  const validateFields = async (
    fieldsForValidation: IUsedField[] = [],
    onSubmitValidation: boolean,
  ) => {
    const fieldsValid = await Promise.all(
      (fieldsForValidation.length ? fieldsForValidation : fields).map(field => {
        if (typeof field === 'string') {
          const fieldObject = fields.find(
            (fieldDefinition: IUsedField) => fieldDefinition.name === field,
          );
          return fieldObject ? fieldObject.validate(onSubmitValidation) : false;
        } else {
          return field.validate(onSubmitValidation);
        }
      }),
    );
    return fieldsValid.every(isValid => isValid);
  };

  const getFormData = () => {
    // Get an object containing raw form data
    const values = fields.reduce((formData: any, field) => {
      if (field.formPrefix) {
        if (!formData[field.formPrefix]) {
          formData[field.formPrefix] = {};
        }
        formData[field.formPrefix][field.name] = field.value;
      } else {
        formData[field.name] = field.value;
      }
      return formData;
    }, {});

    return values;
  };

  const reset = () => {
    fields.forEach((field) => {
      field.setDefaultValue();
    });
  };

  return {
    onSubmit: async (e: React.FormEvent) => {
      e.preventDefault(); // Prevent default form submission
      const formValid = await validateFields(fields, true);
      await onSubmit(getFormData(), formValid);
      // reset();
    },
    onRevert: (cb?: () => void) => {
      fields.forEach(field => {
        field.onRevert();
      });
      if (cb) {
        cb();
      }
    },
    getErrors: () => {
      return fields
        .map((f: IUsedField) => f.errors)
        .filter((errors: string[]) => errors.length);
    },
    getField: (name: string) =>
      fields.find((field: any) => field.name === name),
    isValid: () =>
      fields.every((f: IUsedField) => {
        return f.errors.length === 0;
      }),
    addField: (field: IUsedField) => {
      const fieldIndex = fields.findIndex(
        (existingField: IUsedField) => existingField.name === field.name,
      );
      if (fieldIndex >= 0) {
        fields = [
          ...fields.slice(0, fieldIndex),
          field,
          ...fields.slice(fieldIndex + 1),
        ];
      } else {
        fields.push(field);
      }
    },
    getFormData,
    validateFields,
    reset,
  };
};

export const useDebounce = (value: string, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Return a cleanup function that will be called every time ...
    // ... useEffect is re-called. useEffect will only be re-called ...
    // ... if value changes (see the inputs array below).
    // This is how we prevent debouncedValue from changing if value is ...
    // ... changed within the delay period. Timeout gets cleared and restarted.
    // To put it in context, if the user is typing within our app's ...
    // ... search box, we don't want the debouncedValue to update until ...
    // ... they've stopped typing for more than 500ms.
    return () => {
      clearTimeout(handler);
    };
  }, [value]); // ... need to be able to change that dynamically. // You could also add the "delay" var to inputs array if you ... // Only re-call effect if value changes

  return debouncedValue;
};
