import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import MuiTableRow from '@material-ui/core/TableRow';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import classNames from 'classnames';
import * as React from 'react';
import './table-styles.scss';
import {TableHeader} from './TableHeader';
import {TablePaginationWrapped} from './TablePagination';
import {TableRow} from './TableRow';

export interface ITableProps {
  minWidth?: string;
  className?: string;
  maxWidth?: string;
  columns: any;
  first: boolean;
  last: boolean;
  style?: any;
  data: any;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  page: number;
  count?: number;
  showFooter?: boolean;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  onPageChange?: (page: number) => void;
}

class TableTemp extends React.Component<
  ITableProps & WithStyles<typeof tableStyles>,
  {}
> {
  public handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    if (this.props.onPageChange) {
      this.props.onPageChange(page);
    }
  };

  public handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (this.props.onRowsPerPageChange) {
      this.props.onRowsPerPageChange(parseInt(event.target.value, 10));
    }
  };

  public renderRow = (row: any, idx: number) => (
    <TableRow row={row} columns={this.props.columns} key={idx} />
  );

  public actionsComponent = (tableProps: any) => {
    const { first, last } = this.props;
    return <TablePaginationWrapped {...tableProps} first={first} last={last} />;
  };

  public render() {
    const {
      columns,
      data,
      minWidth,
      maxWidth,
      rowsPerPage,
      classes,
      page,
      showFooter,
      count,
      className,
      style,
      rowsPerPageOptions = [5, 10, 25],
    } = this.props;

    return (
      <>
        <div className={classNames('ui-table', className)} style={style}>
          <MuiTable style={{ minWidth, maxWidth }}>
            <TableHeader columns={columns} />

            <TableBody style={{ overflow: 'auto', height: '150px' }}>
              {data.map(this.renderRow)}
            </TableBody>
          </MuiTable>
        </div>
        {showFooter && (
          <div className="footer-pagination">
            <TableFooter>
              <MuiTableRow>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  count={count || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    IconComponent: KeyboardArrowDown,
                    classes: {
                      selectMenu: classes.input,
                    },
                  }}
                  onPageChange={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={this.actionsComponent}
                />
              </MuiTableRow>
            </TableFooter>
          </div>
        )}
      </>
    );
  }
}

const tableStyles = createStyles({
  iconButton: {
    color: '#006caf',
  },
  input: {
    display: 'flex',
    width: '20px',
    padding: '6px 20px',
  },
});

export const Table = withStyles(tableStyles, {
  withTheme: true,
})(TableTemp);
