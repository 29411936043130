import * as React from 'react';
import {connect} from 'react-redux';
import {IInitialState} from '../../store/initialStateTypes';

interface IPrivateComponent {
  authenticated: boolean;
  requiredRoles?: string[];
  roles: string[];
  children: any;
}

const PrivateComponentTemp: React.FunctionComponent<IPrivateComponent> = ({
  requiredRoles,
  roles,
  authenticated,
  children,
}) => {
  const hasRequiredRole: boolean = Array.isArray(requiredRoles)
    ? roles.some((role: string) => requiredRoles.indexOf(role) !== -1)
    : true;

  if (authenticated && hasRequiredRole) {
    return <>{children}</>;
  }
  return null;
};

const mapStateToProps = ({ auth }: IInitialState) => ({
  authenticated: auth.isAuthenticated,
  roles: auth.roles,
});

export const PrivateComponent = connect(mapStateToProps)(PrivateComponentTemp);
