import * as React from 'react';

export const InfoAdminIcon: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#006CAF"
      fillRule="evenodd"
      d="M12 18.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zm0 1.5a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm.053-9a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5a.75.75 0 0 1 .75-.75zM12 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
    />
  </svg>
);
