import * as React from 'react';

export const TokensIcon: React.SFC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="7.25" stroke="#87C7F0" strokeWidth="1.5" />
      <path
        fill="#87C7F0"
        d="M13.107 12.09L14 11.027l-1.316-.307L12 9.5l-.684 1.221-1.316.307.893 1.061-.13 1.411L12 12.935l1.236.565-.13-1.41zM12 14.637l-1.769.802a.681.681 0 0 1-.91-.374.748.748 0 0 1-.054-.356l.185-2.001-1.278-1.506a.742.742 0 0 1 .06-1.017.682.682 0 0 1 .308-.164l1.883-.435.979-1.732a.676.676 0 0 1 1.192 0l.979 1.732 1.883.435c.373.086.608.471.525.86a.732.732 0 0 1-.157.321l-1.278 1.506.185 2c.037.397-.242.75-.622.787a.667.667 0 0 1-.342-.056l-1.77-.802z"
      />
    </g>
  </svg>
);
