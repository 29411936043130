import * as React from 'react';

export const DoneIcon: React.SFC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="36" cy="36" r="36" fill="#1CC179" />
      <path
        fill="#FFF"
        d="M52.288 21.776a2.533 2.533 0 0 1 3.533-.112 2.423 2.423 0 0 1 .114 3.47l-26.067 25.05a2.532 2.532 0 0 1-3.648 0L15.676 39.372a2.423 2.423 0 0 1 .114-3.47 2.533 2.533 0 0 1 3.534.113l8.72 8.902 24.244-23.141z"
      />
    </g>
  </svg>
);
