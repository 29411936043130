import * as React from 'react';

export const WarningIcon: React.SFC = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Icons-/-warning" fill="#EF5E5F">
        <path
          d="M5.39607895,17.046533 L18.603921,17.046533 L12,6.10853071 L5.39607895,17.046533 Z M12.6253498,4.33594973 L20.6253498,17.4098618 C20.9554572,17.9084308 20.5979481,18.5739121 20,18.5739121 L4,18.5739121 C3.40205189,18.5739121 3.04454276,17.9084308 3.3746502,17.4098618 L11.3746502,4.33594973 C11.671231,3.88801676 12.328769,3.88801676 12.6253498,4.33594973 Z M11.25,10.0542978 C11.25,9.64008422 11.5857864,9.30429778 12,9.30429778 C12.4142136,9.30429778 12.75,9.64008422 12.75,10.0542978 L12.75,12.859113 C12.75,13.2733265 12.4142136,13.609113 12,13.609113 C11.5857864,13.609113 11.25,13.2733265 11.25,12.859113 L11.25,10.0542978 Z M11.25,15.0542978 C11.25,14.6400842 11.5857864,14.3042978 12,14.3042978 C12.4142136,14.3042978 12.75,14.6400842 12.75,15.0542978 L12.75,15.2201382 C12.75,15.6343517 12.4142136,15.9701382 12,15.9701382 C11.5857864,15.9701382 11.25,15.6343517 11.25,15.2201382 L11.25,15.0542978 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);
