import * as React from 'react';

export const ReportsUsersIcon: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="#87C7F0" fillRule="nonzero">
      <path d="M12 18.5V20H7a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v4h-1.5V6a.5.5 0 0 0-.5-.5H7a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h5z" />
      <path d="M18 15.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
      <path d="M14.5 22H13a5 5 0 0 1 10 0h-1.5a3.5 3.5 0 0 0-7 0zM8.75 13.25a.75.75 0 1 1 0-1.5h3.513a.75.75 0 1 1 0 1.5H8.75zM8.75 10.25a.75.75 0 0 1 0-1.5h5.49a.75.75 0 1 1 0 1.5H8.75zM8.75 16.25a.75.75 0 1 1 0-1.5h3.534a.75.75 0 1 1 0 1.5H8.75z" />
    </g>
  </svg>
);
