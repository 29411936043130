import {createApiActionTypes} from '../../store/helpers';

export const GET_REFERRAL_LIST = createApiActionTypes('GET_REFERRAL_LIST');
export const GET_REFERRAL_CAMPAIGN = createApiActionTypes(
  'GET_REFERRAL_CAMPAIGN',
);
export const GET_REFERRAL_USER = createApiActionTypes('GET_REFERRAL_USER');

export const RESET_REFERRAL_LIST = 'RESET_REFERRAL_LIST';
export const RESET_REFERRAL_CAMPAIGN = 'RESET_REFERRAL_CAMPAIGN';
