import { all, call, put, takeEvery } from 'redux-saga/effects';
import { request } from '../../utils';
import * as types from './ActionTypes';
import {
  addPhonePrefix,
  getCountriesDetail,
  getCurrencies,
  getDeliveryMethod,
  getDocuments,
  getDomains,
  getProviderType,
  gettransactionType,
  saveCountry,
  uploadFlag,
} from './main.actions';
import { ICountry } from './IMainReducer';
import { addNotification } from '../notifications/notifications.actions';

const getErrorMessage = (code: string) => {
  let erroCode = "";
  if(code){
    console.log("code ",code)
    erroCode = code.toString().replace("Error:","");
    console.log("erroCode ",erroCode)
    erroCode = erroCode.toString().trim();
    console.log("erroCode ",erroCode)
  }
  let message = "";
  switch (erroCode) {
    case "TECHNICAL_ERROR":
      message = "Technical problem. Try again later";
      break;
    case "DOCUMENT_SIDE_NOT_MATCHED":
      message = "Document not matched with any records";
      break;
    case "DUPLICATE_DELIVERY_METHOD_DETECTED":
      message = "Duplicate delivery method found";
      break;
    case "OTP_NOT_FOUND":
      message = "OTP not found";
      break;
    case "OTP_EXPIRED":
      message = "OTP expired";
      break;
    case "GSM_NUMBER_INCORRECT":
      message = "GSM number incorrect";
      break;
    case "GSM_NUMBER_PREFIX_NOT_ALLOWED":
      message = "GSM number prefix not allowed";
      break;
    default:
      message = "Something went wrong";
  }
  return message;
}

function* getCurrenciesSaga({ payload }: { payload: any }): any {
  try {
    yield put(getCurrencies.loading(payload));

    const currenciesParams = {
      method: 'GET',
      url: 'currency',
    };

    const data: any = (yield call(request, currenciesParams)).data;
    yield put(getCurrencies.success(data));
  } catch (error: any) {
    console.log('> Failed to get currencies', error); // tslint:disable-line
    yield put(getCurrencies.fail(error.toString()));
  }
}

function* getCountriesDetailSaga(): any {
  try {
    yield put(getCountriesDetail.loading({}));

    const countriesParams = {
      method: 'GET',
      url: `countries/detailed`,
    };

    const data: any = (yield call(request, countriesParams)).data;
    yield put(getCountriesDetail.success(data));
  } catch (error: any) {
    console.log('> Failed to get countries detailed', error); // tslint:disable-line
    yield put(getCountriesDetail.fail(error.toString()));
  }
}

function* getDomainsSaga(): any {
  try {
    yield put(getDomains.loading({}));

    const countriesParams = {
      method: 'GET',
      url: 'domains',
    };

    const data: any = (yield call(request, countriesParams)).data;
    yield put(getDomains.success(data));
  } catch (error: any) {
    console.log('> Failed to get domains', error); // tslint:disable-line
    yield put(getDomains.fail(error.toString()));
  }
}

function* addPhonePrefixSaga({ payload }: { payload: { countryId: string, formData: {} } }): any {
  try {
    yield put(addPhonePrefix.loading(payload));
    const { countryId, formData } = payload;

    const countriesParams = {
      method: 'POST',
      data: {
        disabled: false,
        ...formData,
      },
      url: `country/${countryId}/phone-prefix`,
    };

    const data: any = (yield call(request, countriesParams)).data;
    const dataToStore = { data, countryId }
    yield put(addPhonePrefix.success(dataToStore));
  } catch (error: any) {
    console.log('> Failed to save new phone prefix', error); // tslint:disable-line
    yield put(addPhonePrefix.fail(error.toString()));
  }
}

function* uploadFlagSaga({ payload }: { payload: { countryId: string, file: File } }): any {
  try {
    yield put(uploadFlag.loading(payload));
    const { countryId, file } = payload;

    const formData = new FormData();
    formData.append('countryId', countryId);
    formData.append('fileType', 'COUNTRY_FLAG');
    formData.append('file', file);

    const countriesParams = {
      method: 'POST',
      data: formData,
      url: 'uploadFlag',
    };

    const data: any = (yield call(request, countriesParams)).data;
    const dataToStore = { data, countryId }
    yield put(uploadFlag.success(dataToStore));
  } catch (error: any) {
    console.log('> Failed to upload flag', error); // tslint:disable-line
    yield put(uploadFlag.fail(error.toString()));
  }
}

function* saveCountrySaga({ payload }: { payload: ICountry }): any {
  try {
    yield put(saveCountry.loading(payload));

    const countriesParams = {
      method: 'POST',
      data: prepareData(payload),
      url: `country`,
    };

    const data: any = (yield call(request, countriesParams)).data;
    yield put(saveCountry.success(data));
  } catch (error: any) {
    console.log('> Failed to save country', error); // tslint:disable-line
    yield put(
      addNotification({ type: 'error', message: getErrorMessage(error) }),
    );
    yield put(saveCountry.fail(error.toString()));
  }
}

function* getDocumentsSaga(): any {
  try {
    yield put(getDocuments.loading({}));

    const countriesParams = {
      method: 'GET',
      url: `documentTypes`,
    };

    const data: any = (yield call(request, countriesParams)).data;
    yield put(getDocuments.success(data));
  } catch (error: any) {
    console.log('> Failed to get documents', error); // tslint:disable-line
    yield put(getDocuments.fail(error.toString()));
  }
}

function* getDeliveryMethodSaga(): any {
  try {
    yield put(getDeliveryMethod.loading({}));

    const deliveryMethodParams = {
      method: 'GET',
      url: `delivery-method-type`,
    };

    const data: any = (yield call(request, deliveryMethodParams)).data;
    yield put(getDeliveryMethod.success(data));
  } catch (error: any) {
    console.log('> Failed to get documents', error); // tslint:disable-line
    yield put(getDeliveryMethod.fail(error.toString()));
  }
}

function* getProviderTypeSaga(): any {
  try {
    yield put(getProviderType.loading({}));

    const getProviderTypeParams = {
      method: 'GET',
      url: `provider-type`,
    };

    const data: any = (yield call(request, getProviderTypeParams)).data;
    yield put(getProviderType.success(data));
  } catch (error: any) {
    console.log('> Failed to get documents', error); // tslint:disable-line
    yield put(getProviderType.fail(error.toString()));
  }
}

function* getTransactionTypesaga(): any {
  try {
    yield put(gettransactionType.loading({}));

    const getTransactionTypeParams = {
      method: 'GET',
      url: `symex-transaction-type`,
    };

    const data: any = (yield call(request, getTransactionTypeParams)).data;
    yield put(gettransactionType.success(data));
  } catch (error: any) {
    console.log('> Failed to get documents', error); // tslint:disable-line
    yield put(gettransactionType.fail(error.toString()));
  }
}


const prepareData = (data: { [x: string]: any }) => {
  for (const key in data) {
    if (data[key] === null) {
      data[key] = '';
    }
    return data;
  }
};

// Saga watchers / listeners
export function* mainSaga() {
  yield all([
    // @ts-ignore
    takeEvery(types.GET_CURRENCIES.DEFAULT, getCurrenciesSaga),
    // @ts-ignore
    takeEvery(types.GET_COUNTRIES_DETAIL.DEFAULT, getCountriesDetailSaga),
    // @ts-ignore
    takeEvery(types.GET_DOMAINS.DEFAULT, getDomainsSaga),
    // @ts-ignore
    takeEvery(types.ADD_PHONE_PREFIX.DEFAULT, addPhonePrefixSaga),
    // @ts-ignore
    takeEvery(types.SAVE_COUNTRY.DEFAULT, saveCountrySaga),
    // @ts-ignore
    takeEvery(types.GET_DOCUMENTS.DEFAULT, getDocumentsSaga),
    // @ts-ignore
    takeEvery(types.GET_DELIVERY_METHOD.DEFAULT, getDeliveryMethodSaga),
    // @ts-ignore
    takeEvery(types.GET_PROVIDER_TYPE.DEFAULT, getProviderTypeSaga),
    // @ts-ignore
    takeEvery(types.GET_TRANSACTION_TYPE.DEFAULT, getTransactionTypesaga),

    // @ts-ignore
    takeEvery(types.UPLOAD_FLAG.DEFAULT, uploadFlagSaga),
  ]);
}
