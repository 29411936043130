import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';

interface ICustomizedTooltipState {
  arrowRef: React.ReactNode | null;
}

interface ICustomizedTooltipProps {
  tooltip: React.ReactNode;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
  children: JSX.Element;
}

class CustomizedTooltipTemp extends React.Component<
  ICustomizedTooltipProps & WithStyles<typeof styles>,
  ICustomizedTooltipState
> {
  public state = {
    arrowRef: null,
  };

  public handleArrowRef = (node: React.ReactNode) => {
    this.setState({
      arrowRef: node,
    });
  };

  public render() {
    const { classes, tooltip, placement = 'top' } = this.props;

    return (
      <Tooltip
        placement={placement}
        title={
          <React.Fragment>
            {tooltip}
            {/* <span className={classes.arrow} ref={this.handleArrowRef} /> */}
          </React.Fragment>
        }
        classes={{
          popper: classes.bootstrapPopper,
          tooltip: classes.bootstrapTooltip,
          tooltipPlacementBottom: classes.bootstrapPlacementBottom,
          tooltipPlacementTop: classes.bootstrapPlacementTop,
        }}
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                element: this.state.arrowRef,
                enabled: Boolean(this.state.arrowRef),
              },
            },
          },
        }}
      >
        {this.props.children}
      </Tooltip>
    );
  }
}

const styles = () =>
  createStyles({
    arrow: {
      '&::before': {
        borderStyle: 'solid',
        content: '""',
        display: 'block',
        height: 0,
        margin: 'auto',
        width: 0,
      },
      fontSize: 14,
      position: 'absolute',
    },
    bootstrapPlacementBottom: {
      margin: '8px 0',
    },
    bootstrapPlacementTop: {
      margin: '8px 0',
    },
    bootstrapPopper: {
      '&[x-placement*="bottom"] $arrow': {
        '&::before': {
          borderColor: 'transparent transparent #006caf transparent',
          borderWidth: '0 1em 1em 1em',
        },
        height: '1em',
        left: 0,
        top: '-3px',
        width: '3em',
      },
      '&[x-placement*="top"] $arrow': {
        '&::before': {
          borderColor: '#006caf transparent transparent transparent',
          borderWidth: '1em 1em 0 1em',
        },
        height: '1em',
        left: 0,
        marginBottom: '-0.95em',
        width: '3em',
      },
    },
    bootstrapTooltip: {
      borderRadius: '12px',
      backgroundColor: '#006caf',
      fontSize: '14px',
    },
    button: {
      margin: 8,
    },
  });

export const CustomizedTooltip = withStyles(styles)(CustomizedTooltipTemp);
