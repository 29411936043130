import DateFnsUtils from '@date-io/date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import {
  DatePicker as MUIDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CalendarIcon } from './calendar-icon';
import './date-picker-styles.scss';

interface IDatePickerTempState {
  value: any | string | null;
  propsDate: any | string | null;
}

interface IDatePickerTempProps {
  label?: string;
  dateFormatter?: (date: any) => void;
  placeholder?: string;
  format?: string;
  value?: string;
  maxDate?: typeof Date;
  minDate?: typeof Date;
  name?: string;
  // classes?: any;
  className?: string;

  disabled?: boolean;
  showPlaceholder?: boolean;
  [index: string]: any;
}
type DatePickerProps = IDatePickerTempProps &
  WithStyles<typeof styles> &
  WithTranslation;
class DatePickerTemp extends React.Component<
  DatePickerProps,
  IDatePickerTempState
> {
  public static getDerivedStateFromProps(
    props: DatePickerProps,
    state: IDatePickerTempState,
  ) {
    const { value } = props;
    const { propsDate } = state;
    if (value !== propsDate) {
      return {
        value: value || null,
        propsDate: value || null,
      };
    }
    return null;
  }

  public state = {
    value: this.props.value || null,
    propsDate: this.props.value || null,
  };

  private datePicker: any;

  public handleDateChange = (date: any) => {
    const { name, dateFormatter } = this.props;
    this.setState({ value: date }, () => {
      this.props.onChange({
        target: { name, value: dateFormatter ? dateFormatter(date) : date },
      });
    });
  };

  public openPicker = () => {
    const { disabled } = this.props;
    if (disabled) {
      return null;
    }
    this.datePicker.open();
  };

  public resetDate = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState({ value: null });
  };

  public render() {
    const { value } = this.state;
    const {
      label,
      className,
      disabled,
      // classes,
      placeholder,
      showPlaceholder = true,
      format,
      maxDate,
      minDate,
      t,
    } = this.props;

    return (
      <div className={className}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MUIDatePicker
            label={label}
            disabled={disabled}
            ref={(node: any) => (this.datePicker = node)}
            InputProps={{
              disabled,
              // classes: {
              //   root: classes.label,
              // },
              endAdornment: (
                <InputAdornment position="end" className="date-icons">
                  <div onClick={this.openPicker} style={{ cursor: 'pointer' }}>
                    <CalendarIcon />
                  </div>
                </InputAdornment>
              ),
              placeholder: showPlaceholder
                ? placeholder || t('date_picker.placeholder')
                : undefined,
              style: { width: '100%' },
            }}
            minDate={minDate}
            maxDate={maxDate}
            format={format || 'dd/MM/yyyy'}
            value={value}
            onChange={this.handleDateChange}
            animateYearScrolling={false}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

const styles = createStyles({
  label: {
    color: '#777777',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.57',
    letterSpacing: 'normal',
  },
  iconButton: {
    padding: '4px',
  },
});

export const DatePicker = withStyles(styles)(
  // withTranslation('common')
  DatePickerTemp,
);
