import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import MuiStepper from '@material-ui/core/Stepper';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import * as React from 'react';
import {Button} from '../button';
import './stepper-styles.scss';
import {Theme} from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    stepper: {
      padding: '24px 0 8px 0',
    },
    button: {
      marginRight: 8,
    },
    instructions: {
      marginTop: 8,
      marginBottom: 8,
    },
    label: {
      textTransform: 'uppercase',
      fontSize: '12px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.5,
      letterSpacing: '0.6px',
    },
    labelColor: {
      color: '#c8c8c8 !important',
    },
    alternativeLabel: {
      maxWidth: '157px',
      width: '100%',
    },
    connectorActive: {
      '& $connectorLine': {
        borderColor: '#e2e2e2',
      },
    },
    connectorCompleted: {
      '& $connectorLine': {
        borderColor: '#e2e2e2',
      },
    },
    connectorDisabled: {
      '& $connectorLine': {
        borderColor: '#e2e2e2',
      },
    },
    connectorLine: {
      transition: theme.transitions.create('border-color'),
    },
    rootIcon: {
      color: 'rgba(0, 0, 0, 0.1)',
    },
    activeIcon: {
      color: '#4841ef !important',
    },
    completedIcon: {
      color: '#1cc179 !important',
    },
  });

interface IStepperProps {
  className?: string;
  steps: string[];
  stepsContent: React.ReactNode[];
  wellDone: React.ReactNode;
}

interface IStepperState {
  activeStep: number;
}

class StepperTemp extends React.Component<
  IStepperProps & WithStyles<typeof styles>,
  IStepperState
> {
  public state = {
    activeStep: 0,
  };

  public handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  public handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  public render() {
    const { classes, steps, stepsContent, className, wellDone } = this.props;
    const { activeStep } = this.state;
    const connector = (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          completed: classes.connectorCompleted,
          disabled: classes.connectorDisabled,
          line: classes.connectorLine,
        }}
      />
    );

    return (
      <div className={classNames(classes.root, className)}>
        {activeStep !== steps.length ? (
          <MuiStepper
            alternativeLabel={true}
            activeStep={activeStep}
            connector={connector}
            classes={{ root: classes.stepper }}
          >
            {steps.map(label => (
              <Step key={label} classes={{ root: classes.alternativeLabel }}>
                <StepLabel
                  classes={{
                    completed: classes.labelColor,
                    label: classes.label,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.rootIcon,
                      active: classes.activeIcon,
                      completed: classes.completedIcon,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </MuiStepper>
        ) : null}
        {activeStep === steps.length ? (
          <React.Fragment>{wellDone}</React.Fragment>
        ) : (
          <React.Fragment>
            <div className="flex-column flex-align-center stepper-content">
              {stepsContent[activeStep]}
            </div>
            <div className="stepper-buttons flex-row">
              {activeStep === 0 ? (
                <Button onClick={this.handleNext} hollow={true}>
                  Cancel
                </Button>
              ) : (
                <Button onClick={this.handleBack}>Back</Button>
              )}
              <Button onClick={this.handleNext}>
                {activeStep === steps.length - 1 ? 'Register' : 'Next'}
              </Button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export const Stepper = withStyles(styles)(StepperTemp);
