//import * as Sentry from '@sentry/react';
import axios from 'axios';
import {store} from '../App';
import {addNotification} from '../components/notifications/notifications.actions';
import {signOut} from '../pages/login/auth.actions';

// // Add a request interceptor
// axios.interceptors.request.use(
//   function(config) {
//     console.log('out', config);

//     // Do something before request is sent
//     return config;
//   },
//   function(error) {
//     // Do something with request error
//     return Promise.reject(error);
//   },
// );

// // Add a response interceptor
// axios.interceptors.response.use(
//   function(response) {
//     // Do something with response data
//     console.log('in', response);

//     return response;
//   },
//   function(error) {
//     // Do something with response error
//     return Promise.reject(error);
//   },
// );

function formQueryString(params: any = {}) {
  const paramsToParse = {
    ...params,
  };
  const filteredQueryDict = {} as any;
  Object.keys(paramsToParse).forEach((key) => {
    const value = params[key];
    // discard all falsy values, but allow 0 in query
    if (typeof value !== 'undefined' && value !== null) {
      filteredQueryDict[key] = value;
    }
  });

  const chainedQueryString = Object.keys(filteredQueryDict)
    .map((param) => `${[param]}=${filteredQueryDict[param]}`)
    .join('&');

  return chainedQueryString ? `?${chainedQueryString}` : '';
}

export const request = (conditions: any) => {
  const {
    url,
    method,
    headers,
    payload,
    data,
    params,
    withNotification,
    apiVersion,
  } = conditions;
  const queryString: string = formQueryString(params);

  const endpoint = /^(https?:\/\/)/.test(url)
    ? url
    : `${process.env.REACT_APP_API_URL}${url}${queryString}`;
  console.log('endpoint', endpoint); // tslint:disable-line
  const version = apiVersion ? 'v2' : 'v1';

  const options = {
    withCredentials: true,
    data:
      method && method !== 'GET'
        ? data || {
            ...payload,
          }
        : payload,
    headers: headers || {
      'content-type': 'application/json',
    },
    method: method || 'GET',
    timeout: 25000,
    url: `/api/${version}/${url}${queryString}`,
  };
  return axios(options)
    .then((response) => {
      if (response.data.errorMessage) {
        throw new Error(response.data.errorMessage);
      }

      if (response.status !== 200) {
   
        throw new Error(response.status + ' - ' + response.statusText);
      }

      if (withNotification) {
        store.dispatch(
          addNotification({
            message: 'Done',
            type: 'success',
          }),
        );
      }
      return response;
    })
    .catch((error) => {
 
      if (error.message && error.message === 'Network Error') {
        throw new Error(error.message);
      }
      if (!error.response) {
        throw new Error('TECHNICAL_ERROR');
      }
      if (error.response.status === 500) {
        throw new Error('TECHNICAL_ERROR');
      }
      if (error.response.status === 400) {
        throw new Error('DUPLICATE_DELIVERY_METHOD_DETECTED');
      }
      if (error.response.status === 401) {
        if (store.getState().auth.isAuthenticated) {
          store.dispatch(
            addNotification({
              message: 'Token is expired',
              type: 'warning',
            }),
          );
          store.dispatch(signOut.default({}));
          location.href = '/';
        }
        store.dispatch(signOut.default({}));
      }

      if (withNotification) {
        store.dispatch(
          addNotification({
            message:
              error.response.data.message.indexOf('GSM_NUMBER') !== -1 &&
              error.response.data.message.indexOf('Duplicate') !== -1
                ? 'errors.phone_unique'
                : error.response.data.message.indexOf('EMAIL') !== -1 &&
                  error.response.data.message.indexOf('Duplicate') !== -1
                ? 'errors.email_unique'
                : error.response.data.message || 'Something was wrong',
            type: 'error',
          }),
        );
      }
      throw new Error(error.response.data.message || error.response.data);
    });
};
