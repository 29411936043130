import {createAction, createApiAction} from '../../store/helpers';
import * as types from './ActionTypes';

export const getUsers = createApiAction(types.GET_USERS);
export const getReportAccounts = createApiAction(types.GET_REPORT_ACCOUNTS);
export const resetReportAccounts = createAction(types.RESET_REPORT_ACCOUNTS);
export const resetUsers = createAction(types.RESET_USERS);

export const getBalance = createApiAction(types.GET_REPORT_BALANCE);
export const resetReportBalance = createAction(types.RESET_REPORT_BALANCE);
