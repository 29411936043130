import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import * as React from 'react';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const buttonStyles = () =>
  createStyles({
    button: {
      color: '#006caf',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0.07em',
      lineHeight: 'normal',
      textAlign: 'center',
      textTransform: 'none',
      width: '110px',
    },
    rightIcon: {
      marginLeft: 8,
    },
  });

interface IResetButton extends WithStyles<typeof buttonStyles> {
  resetFilter: () => void;
  className?: string;
  label: React.ReactNode;
}

const ResetButtonTemp: React.SFC<IResetButton> = (props: IResetButton) => {
  const { classes, className, label } = props;

  return (
    <Button
      size="small"
      className={classNames(classes.button, className)}
      onClick={props.resetFilter}
    >
      <CloseIcon className={classes.rightIcon} />
      {label}
    </Button>
  );
};
export const ResetButton = withStyles(buttonStyles)(ResetButtonTemp);
