import * as React from 'react';

export const TransactionHistoryIcon: React.SFC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="#87C7F0" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M10 18.5V20H7a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v4h-1.5V6a.5.5 0 0 0-.5-.5H7a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h3z"
      />
      <path d="M18.389 18.5l-.693-.59a.75.75 0 1 1 1.06-1.061l2.014 1.793a.749.749 0 0 1 .311.602.842.842 0 0 1-.256.666l-2.044 1.82a.75.75 0 0 1-1.06-1.06l.785-.67H13.75a.75.75 0 1 1 0-1.5h4.639zM14.53 13l.844-.72a.75.75 0 0 0-1.06-1.06l-2.044 1.82a.752.752 0 0 0-.112.141c-.266.298-.174.822.112 1.107l2.043 1.82a.75.75 0 0 0 1.06-1.06l-.616-.548h4.606a.75.75 0 1 0 0-1.5H14.53z" />
    </g>
  </svg>
);
