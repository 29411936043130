import * as React from 'react';

export const SystemAccountsIcon: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#87C7F0"
      fillRule="evenodd"
      d="M18.245 5.845a2.75 2.75 0 0 1 2.75 2.75v9.71a2.75 2.75 0 0 1-2.75 2.75H5.758a2.75 2.75 0 0 1-2.75-2.75V5.793a2.75 2.75 0 0 1 2.75-2.75h8.328a2.75 2.75 0 0 1 2.75 2.75v.053h1.41zM4.537 7.477V18.3c0 .69.56 1.25 1.25 1.25h12.469c.69 0 1.25-.56 1.25-1.25V8.727c0-.69-.56-1.25-1.25-1.25H4.537zm10.931-1.855c0-.69-.56-1.25-1.25-1.25H5.762c-.69 0-1.25.56-1.25 1.25v.223h10.956v-.223zM16.5 15a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
    />
  </svg>
);
