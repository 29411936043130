import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import get from 'lodash/get';
import * as React from 'react';
import i18n from '../../i18next';
import './notification-styles.scss';

const variantIcon: { [index: string]: React.ReactType } = {
  error: Cancel,
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: ErrorIcon,
};

const styles = () =>
  createStyles({
    close: {
      fill: '#fff',
    },
    contentWrapper: {
      display: 'flex',
      flexFlow: 'row',
      marginBottom: 5,
      padding: 0,
      position: 'relative',
      zIndex: 7,
    },
    error: {
      fill: '#EB6A6A',
    },
    icon: {
      fontSize: 20,
      height: 28,
      width: 28,
    },
    iconVariant: {
      marginRight: '15px',
      opacity: 0.9,
    },
    info: {
      fill: '#35A7CB',
    },
    message: {
      alignItems: 'center',
      display: 'flex',
      fontSize: 12,
    },
    success: {
      fill: '#229659',
    },
    transitionWrapper: {
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      padding: '0px 12px',
      position: 'relative',
      zIndex: 7,
    },
    warning: {
      fill: '#EEC65D',
    },
  });

interface INotificationTempProps {
  id: number;
  message: string;
  type?: string;
}

interface INotificationTempState {
  open: boolean;
}

class NotificationTemp extends React.Component<
  INotificationTempProps & WithStyles<typeof styles>,
  INotificationTempState
> {
  public state = {
    open: true,
  };

  public timeout: number | undefined;

  public componentDidMount() {
    // Close it by state to get smooth animation, will be removed from redux by saga later
    this.timeout = window.setTimeout(this.handleCloseClick, 5000);
  }

  public componentWillUnmount() {
    // Just to make sure changes of timeout value in saga don't crash it
    clearTimeout(this.timeout);
  }

  public handleCloseClick = () => this.setState({ open: false });

  public getTransitionComponent = (props: any) => (
    <Slide
      {...props}
      direction="left"
      className={this.props.classes.transitionWrapper}
    />
  );

  public render() {
    const { message, classes, type } = this.props;
    const Icon = type ? variantIcon[type] : variantIcon.success;
    return (
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={this.state.open}
        TransitionComponent={this.getTransitionComponent}
        className={classes.contentWrapper}
        message={
          <div className={classes.message}>
            <Icon
              className={classNames(
                classes.icon,
                classes.iconVariant,
                type ? get(classes, type) : classes.success,
              )}
            />
            {i18n.t(message, { ns: 'common' }) || message}
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleCloseClick}
          >
            <CloseIcon className={classes.close} />
          </IconButton>,
        ]}
      />
    );
  }
}

export default withStyles(styles)(NotificationTemp);
