import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import * as React from 'react';

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};

interface IVerificationCodeProps {
  fields: number;
  onComplete: any;
  className: string;
  onChange?: any;
  autoFocus?: any;
  showRealValues?: any;
  type?: string;
}

class VerificationCodeTemp extends React.Component<
  IVerificationCodeProps & WithStyles<typeof styles>,
  any
> {
  public iRefs: any[];
  public id: any;

  constructor(props: any) {
    super(props);
    const { fields } = props;
    this.state = {
      values: Array(fields).fill(''),
      realValues: Array(fields).fill(''),
    };

    this.iRefs = [];
    for (let i = 0; i < fields; i++) {
      this.iRefs.push(React.createRef());
    }
    this.id = +new Date();

    // this.handleKeys = Array(fields).fill(false);
  }

  public triggerChange = (values = this.state.realValues) => {
    const { onChange, onComplete, fields } = this.props;
    const val = values.join('');
    if (onChange) {
      onChange(val);
    }
    if (onComplete && val.length >= fields) {
      onComplete(val);
    }
  };

  public onChange = (e: any) => {
    const index = parseInt(e.target.dataset.id, 10);
    // this.handleKeys[index] = false;
    if (this.props.type === 'number' && !e.target.validity.valid) {
      return;
    }
    const { fields } = this.props;
    let next;
    const value = e.target.value;
    let { values, realValues } = this.state;
    values = Object.assign([], values);
    realValues = Object.assign([], realValues);
    if (value.length > 1) {
      let nextIndex = value.length + index - 1;
      if (nextIndex >= fields) {
        nextIndex = fields - 1;
      }
      next = this.iRefs[nextIndex];
      values[nextIndex] = '*';
      realValues[nextIndex] = value[value.length - 1];
      this.setState({ values, realValues });
    } else {
      next = this.iRefs[index + 1];
      values[index] = '*';
      realValues[index] = value;
      this.setState({ values, realValues });
    }

    if (next) {
      next.current.focus();
      next.current.select();
    }

    this.triggerChange(realValues);
  };

  public onKeyDown = (e: any) => {
    const index = parseInt(e.target.dataset.id, 10);
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prev = this.iRefs[prevIndex];
    const next = this.iRefs[nextIndex];
    switch (e.keyCode) {
      case KEY_CODE.backspace:
        e.preventDefault();
        const vals = [...this.state.values];
        const realVals = [...this.state.realValues];
        if (this.state.values[index]) {
          vals[index] = '';
          realVals[index] = '';
          this.setState({ values: vals, realValues: realVals });
          this.triggerChange(realVals);
        } else if (prev) {
          vals[prevIndex] = '';
          realVals[prevIndex] = '';
          prev.current.focus();
          this.setState({ values: vals, realValues: realVals });
          this.triggerChange(realVals);
        }
        break;
      case KEY_CODE.left:
        e.preventDefault();
        if (prev) {
          prev.current.focus();
        }
        break;
      case KEY_CODE.right:
        e.preventDefault();
        if (next) {
          next.current.focus();
        }
        break;
      case KEY_CODE.up:
      case KEY_CODE.down:
        e.preventDefault();
        break;
      default:
        // this.handleKeys[index] = true;
        break;
    }
  };

  public onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  public render() {
    const { values, realValues } = this.state;
    const { classes, autoFocus, className, showRealValues } = this.props;
    const valuesToShow = showRealValues ? realValues : values;
    return (
      <div className={classNames('flex-row', className)}>
        {valuesToShow.map((value: any, index: number) => (
          <TextField
            autoFocus={autoFocus && index === 0}
            className={classes.textField}
            key={`${this.id}-${index}`}
            InputProps={{
              classes: {
                root: classes.input,
                input: classes.input,
              },
            }}
            inputProps={{
              'data-id': index,
              ref: this.iRefs[index],
              classes: {
                root: classes.input,
                input: classes.input,
              },
            }}
            value={value}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            margin="dense"
            classes={{
              root: classes.root,
            }}
          />
        ))}
      </div>
    );
  }
}
const styles = () =>
  createStyles({
    root: {
      marginRight: '12px',
      marginTop: 0,
    },
    input: {
      paddingLeft: '4px',
      height: '100%',
      fontSize: '48px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      // width: '100%',
    },
    textField: {
      width: '76px',
    },
    rootHelperText: {
      display: 'flex',
    },
  });

export const VerificationCode = withStyles(styles)(VerificationCodeTemp);
