import injectReducer from '../../store/injectReducer';
import * as types from './ActionTypes';
import {ICountry, IMainReducer} from './IMainReducer';

export const InitialMainReducer = {
  countryIsLoading: false,
  currenciesIsLoading: false,
  domainsLoading: false,
  documentsIsLoading: false,
  deliveryMethodIsLoading:false,
  providerTypeIsLoading:false,
  transactionTypeLoading:false,
  transactionType:[],
  providerType:[],
  deliveryMethod:[],
  error: '',
  currencies: [],
  countries: [],
  currentCountry: null as ICountry | null,
  domains: [],
  documents: [],
};

export default injectReducer(InitialMainReducer, {
  [types.GET_CURRENCIES.REQUEST]: (state: IMainReducer) => ({
    ...state,
    currenciesIsLoading: true,
  }),
  [types.GET_CURRENCIES.FAILURE]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    error: payload,
    currenciesIsLoading: false,
  }),
  [types.GET_CURRENCIES.SUCCESS]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    currencies: payload,
    currenciesIsLoading: false,
    error: '',
  }),
  [types.GET_COUNTRIES_DETAIL.REQUEST]: (state: IMainReducer) => ({
    ...state,
    countryIsLoading: true,
  }),
  [types.GET_COUNTRIES_DETAIL.FAILURE]: (
    state: IMainReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: payload,
    countryIsLoading: false,
  }),
  [types.GET_COUNTRIES_DETAIL.SUCCESS]: (
    state: IMainReducer,
    { payload }: any,
  ) => ({
    ...state,
    countries: payload,
    countryIsLoading: false,
    error: '',
  }),
  [types.GET_DOMAINS.REQUEST]: (state: IMainReducer) => ({
    ...state,
    domainsIsLoading: true,
  }),
  [types.GET_DOMAINS.FAILURE]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    error: payload,
    domainsIsLoading: false,
  }),
  [types.GET_DOMAINS.SUCCESS]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    domains: payload,
    domainsLoading: false,
    error: '',
  }),
  [types.ADD_PHONE_PREFIX.REQUEST]: (state: IMainReducer) => ({
    ...state,
    countryIsLoading: true,
  }),
  [types.ADD_PHONE_PREFIX.SUCCESS]: (
    state: IMainReducer,
    { payload }: any,
  ) => (
    {
    ...state,
    countries: state.countries.map(country => (country.id === payload.countryId) ? {...country, operatorPrefixes:  [...country.operatorPrefixes, payload.data] } : country),
    countryIsLoading: false,
    error: '',
  }),
  [types.ADD_PHONE_PREFIX.FAILURE]: (
    state: IMainReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: payload,
    countryIsLoading: false,
  }),

  [types.UPLOAD_FLAG.REQUEST]: (state: IMainReducer) => ({
    ...state,
    countryIsLoading: true,
  }),
  [types.UPLOAD_FLAG.SUCCESS]: (
    state: IMainReducer,
    { payload }: any,
  ) => ({
    ...state,
    countries: state.countries.map(country => (country.id === payload.countryId) ? {...country, countryFlag: payload.data} : country),
    countryIsLoading: false,
    error: '',
  }),
  [types.UPLOAD_FLAG.FAILURE]: (
    state: IMainReducer,
    { payload }: any,
  ) => ({
    ...state,
    error: payload,
    countryIsLoading: false,
  }),

  [types.SAVE_COUNTRY.REQUEST]: (state: IMainReducer) => ({
    ...state,
    countryIsLoading: true,
  }),
  [types.SAVE_COUNTRY.SUCCESS]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    countries: state.countries.map(country => country.id === payload.id ? payload : country),
    countryIsLoading: false,
    error: '',
  }),
  [types.SAVE_COUNTRY.FAILURE]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    error: payload,
    countryIsLoading: false,
  }),
  [types.GET_DOCUMENTS.REQUEST]: (state: IMainReducer) => ({
    ...state,
    documentsIsLoading: true,
  }),
  [types.GET_DOCUMENTS.SUCCESS]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    documents: payload,
    documentsIsLoading: false,
    error: '',
  }),
  [types.GET_DOCUMENTS.FAILURE]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    error: payload,
    documentsIsLoading: false,
  }),
  [types.GET_DELIVERY_METHOD.REQUEST]: (state: IMainReducer) => ({
    ...state,
    deliveryMethodIsLoading: true,
  }),
  [types.GET_DELIVERY_METHOD.SUCCESS]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    deliveryMethod: payload,
    deliveryMethodIsLoading: false,
    error: '',
  }),
  [types.GET_DELIVERY_METHOD.FAILURE]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    error: payload,
    deliveryMethodIsLoading: false,
  }),
  [types.GET_PROVIDER_TYPE.REQUEST]: (state: IMainReducer) => ({
    ...state,
    providerTypeIsLoading: true,
  }),
  [types.GET_PROVIDER_TYPE.SUCCESS]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    providerType: payload,
    providerTypeIsLoading: false,
    error: '',
  }),
  [types.GET_PROVIDER_TYPE.FAILURE]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    error: payload,
    providerTypeIsLoading: false,
  }),
  [types.GET_TRANSACTION_TYPE.REQUEST]: (state: IMainReducer) => ({
    ...state,
    transactionTypeLoading: true,
  }),
  [types.GET_TRANSACTION_TYPE.SUCCESS]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    transactionType: payload,
    transactionTypeLoading: false,
    error: '',
  }),
  [types.GET_TRANSACTION_TYPE.FAILURE]: (state: IMainReducer, { payload }: any) => ({
    ...state,
    error: payload,
    transactionTypeLoading: false,
  }),
});
